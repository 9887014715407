import React from "react";

import "../header.css";

import HeaderHomePage from "../Components/HeaderHomePage";
import Logotitle from "../Components/Logotitle";
import LoginRegister from "../Components/LoginRegister";

const Header = () => {
  return (
    <section className="headersection">
      <div className="container">
        <Logotitle />
        <LoginRegister />
        <HeaderHomePage />
      </div>
    </section>
  );
};

export default Header;
