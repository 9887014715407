import React from "react";
import { useNavigate } from "react-router-dom";
import TL from "./TL";

const BackToCases = () => {
  const navigate = useNavigate();
  return (
    <div className="">
      <button
        className="readmore align-start bg-black m-1"
        onClick={() => navigate("/cases/")}
      >
        <i className="bi bi-arrow-left ms-3"></i> <TL text="terug" />
      </button>
    </div>
  );
};

export default BackToCases;
