import React from "react";
import { Helmet } from "react-helmet";
import ContactBlock from "../Components/ContactBlock";

import TL from "../Components/TL";
import { keywordscontact } from "../Data/keywordscontact";

import phone from "../Media/phone.webp";
import "../contact.css";

const HomeContactPage = () => {
  const alsoForMe = {
    backgroundImage: `url(${phone})`,
    width: "100%",
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    padding: "20px",
  };
  return (
    <div className="contactpage">
      <Helmet>
        <title>Ubicopia® - Contact</title>
        <meta
          name="description"
          content="Contacteer iemand bij Ubicopia® via het contactformulier, telefoon of email. Wij stellen een persoonlijk contact steeds op prijs."
        />
        <meta name="twitter:title" content="Ubicopia® - Contact" />
        <meta
          name="twitter:description"
          content="Contacteer iemand bij Ubicopia® via het contactformulier, telefoon of email. Wij stellen een persoonlijk contact steeds op prijs."
        />
        <meta name="keywords" content={keywordscontact} />
        <link rel="canonical" href="https://ubicopia.be/contact/" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <section className="darksection">
        <div className="container text-white mt-5" style={alsoForMe}>
          <div className="row px-4 py-5 my-5 text-center coverbox boxtransp boxhigh">
            <h1 className="text-white display-4 fw-bold">
              <TL text="Hoe kan u ons contacteren ?" />
            </h1>
          </div>
          <div className="row d-flex justify-content-evenly">
            <div className="col-md-5 coverbox boxolive mb-4 bg-opacity-75">
              <h2 className="h2 text-white mb-4">
                <TL text="Vragen over Ubicopia®" />
              </h2>
              <p>
                <TL text="Hebt u een specifieke vraag, dan kan u ons die gerust stellen via bijgaand formulier, email of telefoon." />
              </p>
              <p>
                <a
                  href={`mailto:info@ubicopia.com`}
                  className="ms-3 link-info"
                  aria-label="send email"
                >
                  info@ubicopia.com
                </a>
              </p>
              <p>
                <a
                  href={`tel:+3216903806`}
                  className="ms-3 link-info"
                  aria-label="call phone nr +32 16 903806"
                >
                  +32 (0)16.903806
                </a>
              </p>
              <p>
                <TL text="Maakt u gebruik van het formulier, omschrijf dan goed uw zaak, uw product en/of uw specifieke vraag." />
              </p>
              <p>
                <TL text="We bekijken uw vraag en nemen zo snel als mogelijk contact op." />
              </p>
              <p>
                <TL text="Alles is vrijblijvend." />
              </p>
              <h2 className="text-white mt-5 mb-4">
                <TL text="Voor vragen met een eerder bedrijfstechnisch karakter." />
              </h2>
              <div>
                <TL text="Ubicopia® is een produkt van DRO Projects BV" />
              </div>
              <div className="ms-3">
                <div>Sint-Hubertusstraat 120</div>
                <div>B-3300 Oplinter (Tienen)</div>
                <div>BE0631698840</div>
              </div>
              <h2 className="text-white mt-5 mb-4">
                <TL text="Consultancies" />
              </h2>
              <div>
                <TL text="Ook voor consultancies en vragen over digitalisatie, Saas, Headless, ... staan we open." />
              </div>
            </div>

            <div className="col-md-5 bg-black p-5 mb-4 bg-opacity-75">
              <ContactBlock />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeContactPage;
