import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import BackToCases from "../Components/BackToCases";
import TL from "../Components/TL";
import HomeInteraction from "../Components/Home/HomeInteraction";

import vegetables from "../Media/vegetables.webp";
import newgeneration from "../Media/new-generation.webp";
import market from "../Media/market.webp";

const CaseKleinHandel = () => {
  useEffect(() => {
    document.getElementById("start").scrollIntoView({ behavior: "smooth" });
  }, []);
  const kleinhandelstyle = {
    backgroundImage: `url(${vegetables})`,
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    filterBrightness: "70%",
  };
  return (
    <div className="casepage" id="start">
      <Helmet>
        <title>Webwinkel voor kleinhandel</title>
        <meta
          name="description"
          content="Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta name="twitter:title" content="Webwinkel voor foodtrucks" />
        <meta
          name="twitter:description"
          content="Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta
          name="keywords"
          content="webwinkel voor kleinhandel, webshop voor kleinhandel, webwinkel software voor kleinhandel, webshop software voor kleinhandel, webwinkel systeem voor kleinhandel, webshop systeem voor kleinhandel, kleinhandel online, online kleinhandel"
        />
        <link rel="canonical" href="https://ubicopia.be/cases/kleinhandel/" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <section className="py-5" style={kleinhandelstyle}>
        <div className="container mb-3">
          <BackToCases />
        </div>
        <div className="container" id="title">
          <div className="row boxcornsilk p-5">
            <div className="col-lg-6 text-white boxolive p-3 my-3 d-flex justify-content-center align-items-center">
              <div className="">
                <h1 className="mb-4">
                  <TL text="Webwinkel voor de kleinhandel" />
                </h1>
                <p>
                  <TL text="Ubicopia® verhoogt je klantenbinding door actief met je klanten te communiceren." />
                </p>
                <p>
                  <TL text="Door je online aanwezigheid connecteer je met de moderne consument." />
                </p>
                <p>
                  <TL text="Door een overzichtelijke orderverwerking verhoogt je efficiëntie." />
                </p>
                <p>
                  <TL text="De klant plaatst op voorhand zijn bestelling, ook al sta je op verschillende plaatsen." />
                </p>
              </div>
            </div>
            <div className="col-lg-5 my-3">
              <img
                src={market}
                className="w-100 h-auto"
                alt="webwinkel voor kleinhandel en marktkramer"
                width="411"
                height="273"
              />
            </div>
          </div>
        </div>

        <div className="container boxtransp70 text-white p-5 my-5">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center">
              <img
                src={newgeneration}
                className="w-100 h-auto"
                alt="connecteer met de moderne consument"
                width="332"
                height="375"
              />
            </div>
            <div className="col-lg-6 d-flex align-items-center my-5">
              <div>
                <h2 className="text-start">
                  <TL text="Met een webwinkel verhoog je je klantenbinding en efficiëntie." />
                </h2>
                <h3 className="fs-5">
                  <TL text="Online shoppen" />
                </h3>
                <p className="ms-4">
                  <TL text="Het is geweten dat de moderne consument meer en meer online gaat shoppen. Met een eigen webwinkel trek je de moderne consument aan, en verhoog je je omzet." />
                </p>
                <h3 className="fs-5">
                  <TL text="Het systeem werkt voor jou, niet omgekeerd." />
                </h3>
                <p className="ms-4">
                  <TL text="Initieel zal je je winkel wel moeten opzetten, foto's uploaden en prijzen ingeven. Reeds bij de eerste bestellingen zal je merken dat je deze geïnvesteerde tijd al snel terugwint doordat het systeem zichzelf organiseert." />
                </p>
                <h3 className="fs-5">
                  <TL text="Flexibiliteit" />
                </h3>
                <p className="ms-4">
                  <TL text="Ook al sta je bvb als marktkramer op verschillende locaties, de klant kan steeds de locatie en het tijdslot kiezen dat hem uitkomt." />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <HomeInteraction />
        </div>

        <div className="container mt-5 boxtransp70 p-5 text-white">
          <div className="container">
            <div className="row">
              <div className="m-1 p-3">
                <h2>
                  <TL text="Dankzij de configureerbare interface biedt Ubicopia® een kant-en-klare oplossing voor de kleinhandel." />
                </h2>
                <p>
                  <strong>
                    <TL text="Bovendien wordt via het pakket Ubicopia® microSite het verkoopkanaal en een eenvoudige landingpage samen aangeboden." />
                  </strong>
                </p>
                <p>
                  <a
                    className="link-primary text-white"
                    href="https://demosite.ubicopia.be"
                    aria-label="go to demosite of Ubicopia"
                  >
                    <TL text="Ga naar voorbeeld" />
                    <i className="bi bi-arrow-right ms-3"></i>
                  </a>
                </p>
                <ul className="list-group bg-dark">
                  <li className="list-group-item bg-transparent text-white">
                    <p>
                      <TL text="Door de partnering met Worldline kan de klant op voorhand betalen, zo verliezen we geen tijd meer bij het afhaalmoment." />
                    </p>
                  </li>
                  <li className="list-group-item bg-transparent text-white">
                    <p>
                      <TL text="De afhaalmomenten kunnen vrij worden bepaald, en kan je verhogen naar het weekend toe, wanneer het drukker wordt. Zo kan je je personeel ontlasten en piekmomenten vermijden. Het werk kan gelijkmatiger worden verdeeld." />
                    </p>
                  </li>
                  <li className="list-group-item bg-transparent text-white">
                    <p>
                      <TL text="Bovendien kan je het maximum aantal bestellingen per timeslot ingeven, en een levertijd, in uren of dagen, hanteren, zodat je ruim de tijd hebt om voor voldoende ingrediënten te zorgen." />
                    </p>
                  </li>
                  <li className="list-group-item bg-transparent text-white">
                    <p>
                      <TL text="Via gepersonaliseerde emails wordt de klant op de hoogte gehouden van de voortgang van zijn bestelling." />
                    </p>
                  </li>
                  <li className="list-group-item bg-transparent text-white">
                    <p>
                      <TL text="Wat met het persoonlijke contact - bij het verwerken zie je bij elke bestelling de naam en contactgegevens, door hierop te klikken bel je automatisch (Google Android), of start je een email op. Zo blijf je steeds in nauw contact met de klant, en kan je hem/haar snel contacteren wanneer er iets niet duidelijk is." />
                    </p>
                  </li>
                  <li className="list-group-item bg-transparent text-white">
                    <p>
                      <TL text="Ubicopia® voorziet de mogelijkheid HTML mails te versturen naar je klanten. Zo informeer je je volledige klantenbestand in één klap !" />
                    </p>
                  </li>
                  <li className="list-group-item bg-transparent text-white">
                    <p>
                      <TL text="Door de makkelijke overzichten voor de boekhouding en de orderverwerking win je al snel veel tijd en kan je je focussen op je zaak en je leven, ipv administratie." />
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <BackToCases />
        </div>
      </section>
    </div>
  );
};

export default CaseKleinHandel;
