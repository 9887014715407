import React, { useState } from "react";
import { blankLead } from "../Data/data";
import TL from "./TL";

const InfoBox = () => {
  const [clickedInfo, setClickedInfo] = useState(false);
  const [showConsent, setShowConsent] = useState(false);

  const [lead, setLead] = useState(blankLead);

  const createLead = async (data) => {
    var url = `${process.env.REACT_APP_API_URL}capi/lead/create/`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (resp.ok) {
          console.log("lead create ok");
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setLead({ ...lead, [name]: value });
  }

  function requestInfo() {
    if (lead.email !== "") {
      lead.text =
        "From pressing - I wish to receive more information - button.";
      createLead(lead);
      setClickedInfo(false);
      setShowConsent(false);
    }
  }

  return (
    <div>
      <form className="hprequest mx-auto" onSubmit={requestInfo}>
        {!clickedInfo && (
          <div className="row d-flex align-items-center mx-auto">
            <div className="col-lg-5">
              <input
                type="email"
                className="form-control formwhite mt-3"
                placeholder="name@example.com"
                name="email"
                value={lead.email}
                onInput={handleChange}
                required
                aria-label="email"
              />
            </div>
            <div className="col-lg-6">
              <button
                className="buttongreen w-100 mt-3"
                type="submit"
                aria-label="submit button"
              >
                <TL text="Ik wens vrijblijvende informatie te ontvangen" />
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default InfoBox;
