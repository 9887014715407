import React, { Suspense, lazy, useState } from "react";
import TL from "../TL";
// import Configurator from "../Configurator";

const Configurator = lazy(() => import("../Configurator"));

const HomeInteraction = () => {
  const [action, setAction] = useState("");

  const [demo, setDemo] = useState({
    email: "",
    title: "",
    firstname: "",
    name: "",
    business: "",
    phone: "",
    day: "",
    time: "",
    type: "",
    text: "",
  });

  const createLead = async (data) => {
    var url = `${process.env.REACT_APP_API_URL}capi/lead/create/`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (resp.ok) {
          console.log("lead create ok");
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setDemo({ ...demo, [name]: value });
  }

  function sendRequest() {
    var lead = {
      text:
        "Aanvraag demo via Ubicopia.be voor demo op " +
        demo.day +
        " om " +
        demo.time +
        " voor zaak:" +
        demo.business +
        " - text:" +
        demo.text,
      email: demo.email,
      title: "",
      firstname: demo.firstname,
      name: demo.name,
      phone: demo.phone,
    };
    console.log("lead :", lead);
    createLead(lead);
    setAction("");
    // setShowConsent(false);
  }

  return (
    <>
      <hr></hr>
      {action !== "" && (
        <button
          className="btn btn-secondary btn-lg"
          aria-label="terug"
          onClick={() => {
            setAction("");
            document
              .getElementById("title")
              .scrollIntoView({ behavior: "smooth" });
          }}
        >
          <TL text="terug" />
        </button>
      )}
      <div className="row interactionrow text-black">
        {action === "" && (
          <>
            <div className="col-md interactionbox border-primary">
              <div>
                <>
                  <button
                    id="bookdemobutton"
                    aria-label="book demo"
                    className="shadow-lg btn btn-primary"
                    onClick={() => setAction("bookdemo")}
                  >
                    <h2 className="fs-4">
                      <TL text="Boek een Demo" />
                    </h2>
                  </button>
                </>
              </div>
            </div>
            <div className="col-md interactionbox border-primary">
              <div className="">
                <div>
                  <div className="d-flex justify-content-center">
                    <button
                      id="registerbutton"
                      aria-label="info en prijzen"
                      className="shadow-lg btn btn-primary"
                      onClick={() => setAction("register")}
                    >
                      <h2 className="fs-4">
                        <TL text="Info & prijzen" />
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md interactionbox border-primary">
              <div className="d-flex justify-content-center">
                <div>
                  <div className="d-flex justify-content-center">
                    <button
                      id="letstalkbutton"
                      aria-label="praten"
                      className="shadow-lg btn btn-primary"
                      onClick={() => setAction("talk")}
                    >
                      <h2 className="fs-4">
                        <TL text="Eerst even praten" />
                      </h2>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md interactionbox border-primary">
              <div className="d-flex justify-content-center">
                <div>
                  <div className="d-flex justify-content-center">
                    <a
                      id="visitdemobutton"
                      aria-label="naar de demoshop"
                      className="shadow-lg btn btn-primary"
                      href="https://demosite.ubicopia.be"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h2 className="fs-4">
                        <TL text="Bezoek de demoshop" />
                      </h2>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div>
          {action === "register" && (
            <div className="text-white">
              <Suspense fallback={<div>...loading</div>}>
                <Configurator />
              </Suspense>
            </div>
          )}
          {action === "talk" && (
            <div className="configsection p-3">
              <div className="bookdemoscreen boxdark p-3 mt-4">
                <h2>
                  <TL text="Persoonlijk contact" />
                </h2>
                <div>
                  <p>
                    <TL text="Ook wij vinden een persoonlijk contact zéér belangrijk." />
                  </p>
                  <p>
                    <TL text="Daarom mag u ons steeds vrijblijvend contacteren met uw vragen." />
                  </p>
                  <p>
                    {" "}
                    <TL text="Een telefonisch contact is steeds mogelijk tijdens de werkuren." />
                  </p>
                  <p>
                    <TL text="Wij spreken Nederlands, Engels, Frans en Duits." />
                  </p>
                  <p>
                    <TL text="Ons telefoonnummer is :" />

                    <a
                      href={`tel:+3216903806`}
                      className="ms-3 link-info"
                      aria-label="call phone nr +3216903806"
                    >
                      +32 (0)16.903806
                    </a>
                  </p>
                  <p>
                    <TL text="Indien u toevallig het antwoordapparaat treft, laat dan steeds een bericht, we nemen dan spoedig contact met u op." />
                  </p>
                  <hr></hr>
                  <p>
                    <TL text="Ook een video-conferentie is steeds mogelijk." />
                  </p>
                  <p>
                    <TL text="Hiervoor stuurt u dan best een mailtje via" />
                  </p>
                  <p>
                    <a
                      href={`mailto:info@ubicopia.com`}
                      className="ms-3 link-info"
                      aria-label="send email"
                    >
                      info@ubicopia.com
                    </a>
                  </p>
                  <hr></hr>
                  <p>
                    <TL text="Een persoonlijk informatief bezoek, kan uiteraard ook, dit kan via het boeken van een demo. Die steeds kosteloos en vrijblijvend is." />
                  </p>
                </div>
              </div>
            </div>
          )}
          {action === "bookdemo" && (
            <div className="configsection p-4">
              <div className="bookdemoscreen boxdark p-3 mt-4">
                <h2>
                  <TL text="Boek een demo" />
                </h2>
                <p>
                  <TL text="Geef hier je gegevens, en je voorkeur voor de demo op op zodat we je kunnen contacteren." />
                </p>
                <form className="form" onSubmit={sendRequest}>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="input-firstname">
                      <TL text="Voornaam" /> *
                    </label>
                    <input
                      type="text"
                      className="form-control formwhite"
                      id="input-firstname"
                      placeholder="first name"
                      name="firstname"
                      value={demo.firstname}
                      onInput={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="input-name">
                      <TL text="Naam" /> *
                    </label>
                    <input
                      type="text"
                      className="form-control formwhite"
                      id="input-name"
                      placeholder="name"
                      name="name"
                      value={demo.name}
                      onInput={handleChange}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="input-business">
                      <TL text="Naam van je zaak" />
                    </label>
                    <input
                      type="text"
                      className="form-control formwhite"
                      id="input-business"
                      placeholder="business"
                      name="business"
                      value={demo.business}
                      onInput={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="input-phone">
                      <TL text="Telefoon" />
                    </label>
                    <input
                      type="phone"
                      className="form-control formwhite"
                      id="input-phone"
                      placeholder="+32 11 xxxxxx"
                      name="phone"
                      value={demo.phone}
                      onInput={handleChange}
                      required
                    />
                  </div>
                  <div className="">
                    <label className="form-label" htmlFor="input-email">
                      Email *
                    </label>
                    <input
                      type="email"
                      className="form-control formwhite"
                      id="input-email"
                      placeholder="name@example.com"
                      name="email"
                      value={demo.email}
                      onInput={handleChange}
                      required
                    />
                  </div>
                  <div className="mt-3">
                    <label className="form-label" htmlFor="voorkeur">
                      <TL text="Voorkeur Dag /Tijd" />
                    </label>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="input-group mt-2">
                          <span
                            className="input-group-text"
                            htmlFor="input-dag"
                          >
                            <TL text="Dag" />
                          </span>
                          <select
                            className="form-select"
                            placeholder="dag"
                            type="text"
                            id="input-dag"
                            name="day"
                            value={demo.day}
                            onChange={handleChange}
                          >
                            <option name="day" value="geen voorkeur">
                              <TL text="geen voorkeur" />
                            </option>
                            <option name="day" value="maandag">
                              <TL text="Maandag" />
                            </option>
                            <option name="day" value="dinsdag">
                              <TL text="Dinsdag" />
                            </option>
                            <option name="day" value="woensdag">
                              <TL text="Woensdag" />
                            </option>
                            <option name="day" value="donderdag">
                              <TL text="Donderdag" />
                            </option>
                            <option name="day" value="vrijdag">
                              <TL text="Vrijdag" />
                            </option>
                            <option name="day" value="zaterdag">
                              <TL text="Zaterdag" />
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-group mt-2">
                          <span className="input-group-text">
                            <TL text="Tijdstip" />
                          </span>
                          <input
                            className="form-control formwhite"
                            type="time"
                            id="voorkeur"
                            name="time"
                            value={demo.time}
                            onInput={handleChange}
                            aria-label="Demo time"
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-group mt-2">
                          <span
                            className="input-group-text"
                            htmlFor="input-type"
                          >
                            <TL text="Type" />
                          </span>
                          <select
                            className="form-select"
                            placeholder="dag"
                            type="text"
                            id="input-type"
                            name="type"
                            value={demo.type}
                            onChange={handleChange}
                          >
                            <option name="day" value="geen voorkeur">
                              <TL text="geen voorkeur" />
                            </option>
                            <option name="fysiek" value="fysiek">
                              <TL text="Fysiek" />
                            </option>
                            <option name="teams" value="video-conference teams">
                              <TL text="Teams videoconference" />
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-4">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="demo-text">
                        <TL text="Opmerkingen" />
                        <span className="ms-4 fs-6 fst-italic">
                          ({demo.text.length}/241)
                        </span>
                      </label>
                      <textarea
                        className="form-control formwhite"
                        id="demo-text"
                        rows="3"
                        pattern="{1,241}$"
                        name="text"
                        value={demo.text}
                        onInput={handleChange}
                      />
                      <div className="d-flex justify-content-end">
                        <div className="ts-italic">
                          *<TL text="verplicht" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row my-4">
                    <div className="col d-flex justify-content-center">
                      <button
                        className="btn btn-primary"
                        type="submit"
                        aria-label="verzend"
                      >
                        <TL text="Verzend" />
                      </button>
                    </div>
                    <div className="col d-flex justify-content-center">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        aria-label="annuleer"
                        onClick={() => {
                          setAction("");
                          document
                            .getElementById("title")
                            .scrollIntoView({ behavior: "smooth" });
                        }}
                      >
                        <TL text="Annuleer" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
      <hr></hr>
    </>
  );
};

export default HomeInteraction;
