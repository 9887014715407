import React from "react";

const GTC = () => {
  return (
    <div>
      <p>ALGEMENE VOORWAARDEN VAN TOEPASSING OP ALLE KLANTEN – v3_20240104</p>
      <p>
        1. Behoudens uitdrukkelijk andersluidend schriftelijk beding, zijn
        uitsluitend de algemene en specifieke voorwaarden van toepassing zoals
        op deze website weergegeven.
      </p>
      <p>
        2. Geen enkele dienst of prestatie zal worden geleverd alvorens een
        schriftelijke bevestiging, gedateerd en ondertekend, wordt teruggestuurd
        via post of email. Tenzij anders overeengekomen worden er geen diensten
        of prestaties geleverd alvorens deze worden betaald.
      </p>
      <p>
        3. Alle facturen zijn betaalbaar, online via kaart via de website
        Ubicopia.com/.be, in onze burelen te Sint-Hubertusstraat 120, 3300
        Tienen of per overschrijving.
      </p>
      <p>
        4. Behoudens andersluidend schriftelijk beding, zijn onze facturen
        contant betaalbaar bij ontvangst ervan.
      </p>
      <p>
        5. Elke klacht moet ons binnen de 8 dagen na ontvangst van de factuur
        bereiken per aangetekend schrijven, en dit op straffe van nietigheid.
      </p>
      <p>
        6. Bij gebrek aan betaling 15 dagen na de vervaldag van de factuur, zal
        er van rechtswege en zonder voorafgaandelijke ingebrekestelling een
        intrest verschuldigd zijn ten belope van 10% op iedere aangevangen
        maand. Verder wordt na een periode van 31 dagen na factuurdatum de
        digitale dienst onmiddellijk stopgezet.
      </p>
      <p>
        7. In geval van ongerechtvaardigde niet-betaling van een factuur door de
        klant of in geval van ongerechtvaardigde niet-levering van het bestelde
        dienst van onzentwege 10 dagen na vervaldatum, zal een forfaitaire som
        van 15% van het factuurbedrag, met een minimum van 40,00 euro,
        verschuldigd zijn aan de andere partij bij wijze van schadevergoeding,
        en dit van rechtswege en zonder voorafgaandelijke ingebrekestelling.
      </p>
      <p>
        8. Bij gebrek aan betaling op de vervaldag worden alle verschuldigde
        sommen onmiddellijk opeisbaar, welke ook de betalingsfaciliteiten zijn
        die vroeger werden toegekend.
      </p>
      <p>
        9. De klant erkent, in het geval van fysieke goederen, in afwijking van
        het art.1583 van het Burgerlijk Wetboek, dat de geleverde goederen onze
        eigendom blijven tot volledige betaling van de prijs vermeerderd met de
        intresten en eventuele kosten. De overdracht van de risico’s gebeurt
        echter op het moment van levering. Bij gebrek aan betaling 8 dagen na
        het versturen van een ingebrekestelling per aangetekende brief
        betreffende de niet betaling, moeten de geleverde goederen ons
        onmiddellijk terugbezorgd worden op kosten en risico van de klant die
        zich hiertoe verbindt en dit op simpele vraag van onzentwege.
      </p>
      <p>
        10. De huidige algemene verkoopsvoorwaarden vallen onder het Belgisch
        recht en alle betwistingen van welke aard ook, vallen onder de
        uitsluitende bevoegdheid van de rechtbank te Vaartstraat 5, 3000 Leuven.
        De verkoper kan evenwel afzien van deze clausule en, indien hij het
        wenst, dagvaarden voor de bevoegde rechtbank volgens het gemeen recht.
      </p>
      <p>
        11. Behoudens verborgen gebreken, dient elke klacht met betrekking tot
        de geleverde goederen, ons binnen de 8 dagen na ontvangst te bereiken
        per aangetekend schrijven, en dit op straffe van nietigheid.
      </p>
      <p>
        12. Overeenkomstig artikel 6 van de wet van 2 augustus 2002 moet de
        klant ons vergoeden voor alle invorderingskosten waaronder honoraria en
        kosten van een advocaat en van technische raadgevers waartoe wij ons
        hebben moeten richten ten gevolge van de niet naleving van een van de
        verplichtingen door huidige algemene voorwaarden opgelegd aan de klant
      </p>
      <p>
        13. Bij gebrek aan overeenstemming door de klant meegedeeld binnen de
        termijnen en volgens de modaliteiten vermeld in artikels 14 en 15 zullen
        de defecte goederen naar onze keuze ofwel hersteld ofwel vervangen
        worden. Indien een herstelling of een vervanging onmogelijk of buiten
        alle proporties blijkt, zullen wij een geëigende prijsvermindering
        toekennen of de vervanging door een soortgelijk goed.
      </p>
      <p>
        14. BEPALINGEN OVER PERSOONLIJKE GEGEVENS Alle persoonlijke gegevens die
        de koper ons verstrekt, worden als vertrouwelijk beschouwd en worden
        uitsluitend bewaard en verwerkt in het kader van de relatie tussen de
        koper en ons. Zij worden niet verkocht of ter beschikking gesteld aan
        derden zonder de voorafgaande goedkeuring van de koper. De gegevens
        kunnen echter worden doorgegeven aan een partner of instantie die
        tussenkomt in het kader van onze wettelijke verplichtingen of die
        betrokken is bij de verwerking van de transactie met de koper
        (bijvoorbeeld: onze boekhouder, een leverancier, een onderaannemer…).
        Wij verbinden ons ertoe de best mogelijke veiligheidsmaatregelen te
        nemen om te vermijden dat derden zonder toelating toegang zouden hebben
        tot de persoonlijke gegevens die u ons hebt medegedeeld. De koper heeft
        altijd de mogelijkheid om kennis te nemen van deze persoonlijke gegevens
        die wij in ons bezit hebben en te vragen om deze aan te passen of te
        verwijderen, voor zover dit mogelijk is met het oog op de naleving van
        onze wettelijke verplichtingen inzake de bewaring van gegevens
        betreffende handelstransacties. Voor alle inlichtingen over dit
        onderwerp kunt u uw vragen sturen naar onze maatschappelijke zetel, of
        op info@ubicopia.be
      </p>
      <p>
        15. In het geval van digitale diensten geleverd onder abonnementsvorm,
        bvb. de diensten aangeleverd via https://ubicopia.be/.com, hierna
        vernoemd “Ubicopia”, blijven deze ter aller tijde eigendom van DRO
        Projects BV. Tenzij anders overeengekomen, bvb in het geval van een
        website eigen aan de klant, die als éénmalige som wordt betaald, zal
        geen enkele code betreffende de geleverde digitale diensten eigendom
        worden van de klant. De klant vergoedt DRO Projects bv uitsluitend voor
        het gebruik van de digitale diensten. De broncode en de actieve
        bestanddelen betreffende de dienstverlening blijven steeds volledig
        onder het beheer van DRO Projects bv, en maken deel uit van de
        beschermde intellectuele eigendom van DRO Projects bv.
      </p>
      <p>
        16. Bijkomend aan par.14, erkent de klant dat DRO Projects bv ter aller
        tijde toegang heeft tot alle gegevens opgeslagen door de klant of zijn
        klanten op de systemen beheerd door DRO Projects bv, dit met het oog op
        de continuïteit van de dienstverlening. Bvb voor het debuggen of
        oplossen van een technisch probleem. Het is DRO Projects niet toegelaten
        op enige andere manier gebruik te maken van deze gegevens. Deze vallen
        binnen het kader van de van kracht zijnde GDPR wetgeving.
      </p>
      <p>
        17. Prijsbepaling van de geleverde diensten. De klant erkent dat de
        aanschaf van de digitale dienstverlening via Ubicopia.be een abonnement
        betreft voor de periode van één jaar. Tenzij anders overeengekomen, bvb
        voor het ontwerpen van een éénmalige website. De klant erkent dat er
        voor de digitale dienstverlening een gestaffelde prijs/maand wordt
        bepaald. D.w.z. dat er een prijs wordt overeengekomen, vermeld op de
        website, voor de geleverde diensten met de beperkingen die van kracht
        zijn en in het overzicht worden vermeld. Indien de klant door online
        handelingen/verkopen in een hogere prijscategorie valt zoals aangegeven,
        zal een gewijzigde prijs reeds van toepassing zijn onmiddellijk voor de
        maand waarin deze meer-prestatie van toepassing is. Aldus zal deze
        verhoogde prijs ook worden aangerekend. Bij het bepalen van het
        jaarabonnement in één keer, zal enkel het verschil met het gekozen
        pakket maandelijks worden aangerekend. De vermelde prijzen zijn steeds
        zonder BTW.
      </p>
      <p>
        18. Betreffende de inhoud van de geleverde diensten is deze, tenzij
        anders overeengekomen, beperkt tot louter functionele onderwerpen. Dwz
        in het geval van een website of webshop zal DRO Projects niet instaan
        voor het creëren van een merk, logo, branding of fotomateriaal, nog voor
        enige andere strategische of marketing georiënteerde dienstverlening.
        Bvb zo zal foto, beeldmateriaal alsook bvb het kleurenpallet van een
        website steeds moeten worden aangeleverd. Wel inbegrepen in de vermelde
        prijs is een SEO vriendelijke website met een SEO score van 90 of hoger
        volgens de Lighthouse Report Viewer (Chrome extension), en een volledig
        responsive design geschikt voor alle toestellen. SSL beveiliging is
        steeds inbegrepen. Bij het niet voorzien van een webhosting of
        domeinnaam dient de klant een werkende login en paswoord te voorzien van
        de door hem verkozen leverancier van hosting/domeinnaam – teneinde een
        SSH verbinding tot stand te kunnen brengen en de DNS gegevens bij te
        kunnen werken. Het aanmaken van een website zal, tenzij anders
        overeengekomen bvb bij keuze voor een oplossing op maat zie CompoSite,
        steeds beperkt zijn tot landingpage + 3 bijkomende pagina’s maximaal,
        naar voorbeeld van demosite.ubicopia.be. En bestaat uit 2
        ontwikkelingsiteraties. Bijkomende diensten dienen vergoed te worden aan
        het geldende uurtarief van €90/u excl. BTW. Wijzigingen die achteraf
        dienen aangebracht te worden, dienen ook steeds aan het tarief van €90/u
        excl BTW vergoed te worden.
      </p>
      <p>
        19. Voor het verlengen van het digitale abonnement voor een nieuwe
        periode zal steeds een schriftelijke bevestiging gevraagd worden. Het is
        niet zo dat er een stilzwijgend verlengen wordt toegepast. 20.De klant
        erkent dat het gebruik van de digitale e-commerce diensten enkel is
        toegestaan voor het verhandelen van volledig legale goederen zoals
        gedefinieerd in het territotium waar de klanten toegang hebben tot de
        webshop. De aard en type van de verhandelde goederen dient steeds in de
        verkoopovereenkomst, schriftelijk of digitaal dienen te worden
        aangegeven. Bij het wijzigen van de beschrijving van deze goederen,
        dient men dit onmiddellijk aan te passen, of door te geven. Voor alle
        vragen kan u terecht via info@ubicopia.be of info@droprojects.com .
      </p>
      <p>
        20. Herroepingsrecht. Bij het online aankopen van een dienstverlening
        heeft de consument 14 dagen na de activatie van deze online-dienst te
        tijd om de overeenkomst te herroepen. Hiervoor zullen enkel de
        aantoonbare kosten worden aangerekend. Om een online aankoop te
        herroepen, volstaat het een email te versturen naar info@ubicopia.be,
        met vermelding van de reden van Herroepingsrechtvan de aankoop.
      </p>
    </div>
  );
};

export default GTC;
