import React, { useContext } from "react";
import ControlContext from "../Store/control-context";
import { talen } from "../Data/talen";

const TL = ({ text }) => {
  const controlObject = useContext(ControlContext);
  var translation = "";

  if (talen.filter((t) => t.NL === text).length > 0) {
    switch (controlObject.language) {
      case "NL":
        translation = talen.filter((t) => t.NL === text)[0].NL;
        break;
      case "FR":
        translation = talen.filter((t) => t.NL === text)[0].FR;
        break;
      case "EN":
        translation = talen.filter((t) => t.NL === text)[0].EN;
        break;
    }
  } else {
    translation = text;
  }
  return <>{translation}</>;
};

export default TL;
