import React from "react";
import WhyCard from "./WhyCard";

import efficiency from "../../Media/efficiency.webp";
import easy from "../../Media/easy.webp";
import ownshop from "../../Media/ownshop2.webp";
import hipster from "../../Media/hipster.webp";
import connect from "../../Media/connect.webp";
import turnover from "../../Media/turnover.webp";

const Why = () => {
  return (
    <div>
      <div className="row">
        <div className="col">
          <WhyCard
            image={easy}
            alt="Ubicopia is makkelijk in gebruik"
            title="Makkelijk in gebruik"
            subtitle1="Kan je online shoppen, dan kan je met Ubicopia® werken"
          />
        </div>
        <div className="col">
          <WhyCard
            image={turnover}
            alt="Met Ubicopia verhoog je je omzet"
            title="Verhoog je omzet"
            subtitle1="Uw winkel is altijd open, zelfs buiten de openingsuren"
          />
        </div>
        <div className="col">
          <WhyCard
            image={efficiency}
            alt="verhoog je efficiëntie"
            title="Verhoog je efficiëntie"
            subtitle1="Spaar tijd en laat het systeem voor jou werken"
          />
        </div>
        <div className="col">
          <WhyCard
            image={hipster}
            alt="Ubicopia connecteert je met je klanten"
            title="Ga mee met je tijd"
            subtitle1="Connecteer met de moderne consument"
          />
        </div>
        <div className="col">
          <WhyCard
            image={ownshop}
            alt="ga voor je eigen shop met Ubicopia®"
            title="Een eigen webwinkel"
            subtitle1="Ga voor je eigen identiteit, en onderscheid je"
          />
        </div>
        <div className="col">
          <WhyCard
            image={connect}
            alt="Ubicopia connecteert je met je klanten"
            title="Klantenbinding"
            subtitle1="Je verstevigt je band met je klanten"
          />
        </div>
      </div>
    </div>
  );
};

export default Why;
