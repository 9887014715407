import React, { useState } from "react";
import TL from "./TL";
import { blankLead } from "../Data/data";

const ContactBlock = () => {
  const [clickedInfo, setClickedInfo] = useState(false);
  const [showConsent, setShowConsent] = useState(false);

  const [lead, setLead] = useState(blankLead);

  const createLead = async (data) => {
    var url = `${process.env.REACT_APP_API_URL}capi/lead/create/`;
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (resp.ok) {
          console.log("lead create ok");
        }
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    setLead({ ...lead, [name]: value });
  }

  function sendRequest() {
    lead.text = "from Ubicopia:" + lead.text;
    createLead(lead);
    setClickedInfo(false);
    setShowConsent(false);
  }

  return (
    <div className="contactform w-100">
      <form className="form" onSubmit={sendRequest}>
        <div className="mb-3">
          <label className="form-label" htmlFor="firstname">
            <TL text="Voornaam" /> *
          </label>
          <input
            type="text"
            className="form-control formwhite"
            id="firstname"
            placeholder="first name"
            name="firstname"
            value={lead.firstname}
            onInput={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="name">
            <TL text="Naam" /> *
          </label>
          <input
            type="text"
            className="form-control formwhite"
            id="name"
            placeholder="name"
            name="name"
            value={lead.name}
            onInput={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="phone">
            <TL text="Telefoon" />
          </label>
          <input
            type="phone"
            className="form-control formwhite"
            id="phone"
            placeholder="+32 11 xxxxxx"
            name="phone"
            value={lead.phone}
            onInput={handleChange}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="email">
            Email *
          </label>
          <input
            type="email"
            className="form-control formwhite"
            id="email"
            placeholder="name@example.com"
            name="email"
            value={lead.email}
            onInput={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="text">
            <TL text="Mijn vraag" />
            <span className="ms-4 fs-6 fst-italic">
              ({lead.text.length}/241)
            </span>
          </label>
          <textarea
            className="form-control formwhite"
            id="text"
            rows="3"
            pattern="{1,241}$"
            name="text"
            value={lead.text}
            onInput={handleChange}
            required
          />
        </div>

        <div className="row buttongroup d-flex-row align-center justify-content-between">
          <button
            className="col-4 buttongreen"
            type="submit"
            aria-label="send button"
          >
            <TL text="Verzend" />
          </button>
          <div className="col-4 ts-italic">
            *<TL text="verplicht" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactBlock;
