import React, { Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import TL from "../Components/TL";
import "../home.css";

import bakery from "../Media/bakery.webp";
import vegetables from "../Media/vegetables.webp";
import phone from "../Media/phone.webp";
import HomeTitle from "../Components/Home/HomeTitle";
import HomeInteraction from "../Components/Home/HomeInteraction";
import Why from "../Components/Home/Why";
import WhyList from "../Components/Home/WhyList";
import InfoBox from "../Components/InfoBox";
import { keywordshome } from "../Data/keywordshome";
// import ContactBlock from "../Components/ContactBlock";

const ContactBlock = lazy(() => import("../Components/ContactBlock"));

const Home = () => {
  const bakerystyle = {
    backgroundImage: `url(${bakery})`,
    width: "100%",
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  const vegetablestyle = {
    backgroundImage: `url(${vegetables})`,
    width: "100%",
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  const contactstyle = {
    backgroundImage: `url(${phone})`,
    width: "100%",
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
  };

  return (
    <div className="homepage">
      <Helmet>
        <title>e-commerce voor kleinhandel</title>
        <meta
          name="description"
          content="Ubicopia® is de alles-in-één tool voor de zelfstandige die een eigen webwinkel wil opstarten en de mensen ertoe wil aanzetten meer lokaal te kopen."
        />
        <meta name="twitter:title" content="e-commerce voor kleinhandel" />
        <meta
          name="twitter:description"
          content="Ubicopia® is de alles-in-één tool voor de zelfstandige die een eigen webwinkel wil opstarten en de mensen ertoe wil aanzetten meer lokaal te kopen."
        />
        <meta name="keywords" content={keywordshome} />
        <link rel="canonical" href="https://ubicopia.be" />
        <meta name="robots" content="index,follow" />
      </Helmet>

      <section className="darksection py-3" id="title" style={bakerystyle}>
        <div className="container py-2 boxcornsilk">
          <div className="p-1">
            <HomeTitle />
            <HomeInteraction />
          </div>
        </div>
        <div className="container boxcornsilk">
          <Why />
        </div>
      </section>

      <section className="darksection p-3">
        <div className="container">
          <h2 className="text-center text-white display-5 fw-bold py-5">
            <TL text="Ubicopia® = Besteltool - Afhaaltool - Webwinkel - Orderverwerking - Boekhouding - Mail & Marketing" />
          </h2>
        </div>
      </section>

      <section className="darksection p-3" style={vegetablestyle}>
        <div className="container">
          <WhyList />
        </div>
      </section>

      <section className="darksection p-3">
        <div className="container py-5 boxtransp70">
          <h2 className="text-white text-center display-5 fw-bold">
            <TL text="Schrijf u in op de nieuwsbrief" />
          </h2>
          <InfoBox />
        </div>
      </section>

      <section className="darksection p-3" style={contactstyle}>
        <div className="container py-5">
          <div className="row py-5 boxtransp70 text-white">
            <div className="d-flex flex-row justify-content-center">
              <div>
                <h2 className="h2 text-white  text-center mb-4 display-5 fw-bold">
                  <TL text="Vragen over Ubicopia®" />
                </h2>
                <p>
                  <TL text="Hebt u een specifieke vraag, dan kan u ons die gerust stellen via bijgaand formulier, email of telefoon." />
                </p>
              </div>
            </div>
            <div className="col-lg-6 mb-4 bg-opacity-75 d-flex justify-content-center">
              <div className="px-4">
                <p>
                  <TL text="Email" />
                </p>
                <p>
                  <a
                    href={`mailto:info@ubicopia.com`}
                    className="ms-3 link-info"
                    aria-label="email naar info@ubicopia.be"
                  >
                    info@ubicopia.com
                  </a>
                </p>
                <p>
                  <TL text="Tel" />
                </p>
                <p>
                  <a
                    href={`tel:+3216903806`}
                    className="ms-3 link-info"
                    aria-label="call phone number"
                  >
                    +32 (0)16.903806
                  </a>
                </p>
                <p>
                  <TL text="Maakt u gebruik van het formulier, omschrijf dan goed uw zaak, uw product en/of uw specifieke vraag." />
                </p>
                <p>
                  <TL text="We bekijken uw vraag en nemen zo snel als mogelijk contact op." />
                </p>
                <p>
                  <TL text="Alles is vrijblijvend." />
                </p>
                <h2 className="text-white mt-5 mb-4">
                  <TL text="Voor vragen met een eerder bedrijfstechnisch karakter." />
                </h2>
                <div>
                  <a
                    href={"https://droprojects.com"}
                    className="link-info"
                    aria-label="go to droprojects.com"
                  >
                    <TL text="Ubicopia® is een produkt van DRO Projects BV" />
                  </a>
                </div>

                <h2 className="text-white mt-5 mb-4">
                  <TL text="Consultancies" />
                </h2>
                <div>
                  <TL text="Ook voor consultancies en vragen over digitalisatie, Saas, Headless, ... staan we open." />
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-flex align-items-center">
              <Suspense fallback={<div>Loading</div>}>
                <ContactBlock />
              </Suspense>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
