import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import TL from "../Components/TL";
import BackToCases from "../Components/BackToCases";
import HomeInteraction from "../Components/Home/HomeInteraction";

import truck from "../Media/truck.webp";
import burgers from "../Media/burgers.webp";
import time from "../Media/time.webp";

const CaseFoodTruck = () => {
  useEffect(() => {
    document.getElementById("start").scrollIntoView({ behavior: "smooth" });
  }, []);

  const foodtruckstyle = {
    backgroundImage: `url(${burgers})`,

    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
  };

  const timestyle = {
    backgroundImage: `url(${time})`,
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
  };

  return (
    <div className="casepage" id="start">
      <Helmet>
        <title>Webwinkel voor foodtruck</title>
        <meta
          name="description"
          content="Webwinkel Foodtruck - Webwinkel voor foodtruck starten - Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta name="twitter:title" content="Webwinkel voor foodtrucks" />
        <meta
          name="twitter:description"
          content="Webwinkel Foodtruck - Webwinkel voor foodtruck starten - Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta
          name="keywords"
          content="webwinkel voor foodtruck, webshop voor foodtruck, webwinkel software voor foodtrucks, webshop software voor foodtrucks, webwinkel systeem voor foodtrucks, webshop systeem voor foodtrucks, foodtruck online"
        />
        <link rel="canonical" href="https://ubicopia.be/cases/foodtruck" />
        <meta name="robots" content="index,follow" />
      </Helmet>

      <section className="py-5" style={foodtruckstyle}>
        <div className="container">
          <BackToCases />
        </div>
        <div className="container boxcornsilk" id="title">
          <div className="row d-flex p-3">
            <div className="boxolive col-lg-7 d-flex justify-content-center align-items-center p-3 my-3">
              <div className="text-white p-3">
                <h1>
                  <TL text="Webwinkel voor foodtruck met verse bereidingen" />
                </h1>
                <div className="fs-1">
                  <TL text="Efficiëntie" />
                </div>
                <p>
                  <TL text="Ubicopia® verhoogt niet enkel je online aanwezigheid, maar verhoogt ook je efficientie door de beschikbare tijd nuttiger te besteden." />
                </p>
                <div className="fs-1">
                  <TL text="Klantenbinding" />
                </div>
                <p>
                  <TL text="Je verhoogt tegelijk de service naar je klanten door wachttijden te vermijden." />
                </p>
                <p>
                  <TL text="Je kan een verse bereiding garanderen bij het ophalen van de bestelling." />
                </p>
                <div className="fs-1">
                  <TL text="Je omzet stijgt" />
                </div>
                <p>
                  <TL text="Je opent immers een extra verkoopkanaal." />
                </p>
                <p>
                  <TL text="Je bereikt een ander type klant." />
                </p>
              </div>
            </div>
            <div className="col-lg-5 my-3 p-3 d-flex align-items-center">
              <img
                className="w-100 h-auto"
                src={truck}
                alt="Ubicopia als webwinkel voor de foodtruck"
                width="348"
                height="310"
              />
            </div>
          </div>
        </div>

        <div className="container boxtransp70 text-white p-5 mt-5">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="text-start">
                <TL text="Verhoging van klantenbinding" />
              </h2>
              <h3 className="fs-5">
                <TL text="Reduceer wachttijden." />
              </h3>
              <p className="ms-4">
                <TL text="De keuken heeft slechts een beperkte capaciteit. Komt er dan onverwacht een grote bestelling, dan starten de wachttijden. Je kan zelf aangeven hoeveel werk je per tijdseenheid kan aannemen." />
              </p>
              <p className="ms-4">
                <TL text="Je kan instellen hoeveel tijd op voorhand je nog bestellingen accepteert, dit kan gaan tot enkele dagen, uren of minuten." />
              </p>
              <p className="ms-4">
                <TL text="Met het betaalssyteem kan je ervoor kiezen om je klanten online te laten betalen." />
              </p>
              <h3 className="fs-5">
                <TL text="Klantentevredenheid" />
              </h3>
              <p className="ms-4">
                <TL text="Je klant wordt op tijd geïnformeerd met een instelbare timer wanneer zijn bestelling vers kan worden afgehaald." />
              </p>
              <h3 className="fs-5">
                <TL text="Online aanwezigheid" />
              </h3>
              <p className="ms-4">
                <TL text="Met de ingebouwde marketing-mailer informeer je je klanten pro-actief over nieuwe etenswaren, je dag- of weekschotel, uitzonderlijke situaties, enz." />
              </p>
              <h3 className="fs-5">
                <TL text="Je eigen webshop met je website inclusief" />
              </h3>
              <p className="ms-4">
                <TL text="Ubicopia® kan worden geïnstalleerd met of zonder website naar je eigen wens." />
              </p>
              <p className="ms-4">
                <TL text="De Ubicopia® Microshop is al een website op zich en kan zo worden gebruikt met een eigen email, hosting en webdomeinnaam - dit is dan je eigen website met wat basisinformatie waarop je klanten hun bestellingen kunnen plaatsen." />
              </p>
              <p className="ms-4">
                <TL text="Heb je reeds een website, dan leveren we aan jou of je webmaster een stukje code, onder de vorm van een javascript snippet of iframe, in een mum van tijd start je met je online verkoop op je reeds bestaande website." />
              </p>
              <h2 className="text-start">
                <TL text="Verhoging van efficiëntie" />
              </h2>
              <h3 className="fs-5">
                <TL text="Ubicopia® neemt een aantal taken van je over." />
              </h3>
              <p className="ms-4">
                <TL text="Je klanten bestellen online, je wordt minder gestoord tijdens je werk." />
              </p>
              <p className="ms-4">
                <TL text="Met de getimede preparatie worden je klanten geïnformeerd en werk je gestructureerd." />
              </p>
              <p className="ms-4">
                <TL text="Met een duidelijk overzicht weet je perfect wat er moet gebeuren." />
              </p>
              <p className="ms-4">
                <TL text="Je kan je werk wat verdelen door de tijdslots in te stellen en het maximum aantal bestellingen per slot op te geven." />
              </p>
              <h3 className="fs-5">
                <TL text="Boekhoudkundige hulp" />
              </h3>
              <p className="ms-4">
                <TL text="Ubicopia® bereidt je overzicht van je daginkomsten voor op een overzichtelijke en wettelijk correct manier." />
              </p>
            </div>
            <div className="col-lg-4">
              <img
                className="w-100 h-auto"
                src={time}
                alt="frituur tijd is de essentie"
                width="332"
                height="286"
              />
              <p className="ms-1">
                <TL text="" />
                Tijd is de essentie.
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <HomeInteraction />
        </div>

        <div className="container mt-5 boxtransp70 p-3 text-white">
          <div className="row mt-3">
            <h2>
              <TL text="Ubicopia® biedt een kant-en-klare oplossing als webwinkel voor een foodtruck." />
            </h2>
            <ul className="list-group bg-dark">
              <li className="list-group-item bg-transparent text-white">
                <TL text="Sta je op verschillende locaties, met Ubicopia® kan je deze ingeven." />
              </li>
              <li className="list-group-item bg-transparent text-white">
                <TL text="Vrij te kiezen tijdslots voor de afhalingen." />
              </li>
              <li className="list-group-item bg-transparent text-white">
                <TL text="Maximum aantal bestellingen per timeslot." />
              </li>
              <li className="list-group-item bg-transparent text-white">
                <TL text="Je kan bepalen hoeveel uren er op voorhand er moet besteld worden." />
              </li>
              <li className="list-group-item bg-transparent text-white">
                <TL text="De klant wordt bij de start van de bereiding automatisch verwittigd door het systeem. Zo weet de klant perfect wanneer hij moet komen afhalen, om een verse bereiding mee te nemen." />
              </li>
              <li className="list-group-item bg-transparent text-white">
                <TL text="Boekhoudkundig wordt alles in de achtergrond bijgehouden, en kan er per dag een BTW-overzicht worden gemaakt van de daginkomsten." />
              </li>
              <li className="list-group-item bg-transparent text-white">
                <TL text="Met de ingebouwde marketing-mailtool, worden de klanten persoonlijk geïnformeerd, wanneer er nieuwe producten in promotie, zijn, of wanneer er bvb een omleiding is." />
              </li>
            </ul>

            <div className="col-lg-4"></div>
          </div>
        </div>
        <div className="container">
          <BackToCases />
        </div>
      </section>
    </div>
  );
};

export default CaseFoodTruck;
