import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import TL from "../Components/TL";
import BackToCases from "../Components/BackToCases";
import HomeInteraction from "../Components/Home/HomeInteraction";

import bakery from "../Media/bakery.webp";
import etalagebakker from "../Media/etalage-bakker.webp";
import newgeneration from "../Media/new-generation.webp";
import time from "../Media/time.webp";

const CaseBakker = () => {
  useEffect(() => {
    document.getElementById("start").scrollIntoView({ behavior: "smooth" });
  }, []);

  const bakerystyle = {
    backgroundImage: `url(${bakery})`,
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    filterBrightness: "70%",
  };
  return (
    <div className="casepage" id="start">
      <Helmet>
        <title>Webwinkel voor bakker</title>
        <meta
          name="description"
          content="Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta name="twitter:title" content="Webwinkel voor foodtrucks" />
        <meta
          name="twitter:description"
          content="Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta
          name="keywords"
          content="webwinkel voor bakker, webshop voor bakker, webwinkel software voor bakker, webshop software voor bakker, webwinkel systeem voor bakker, webshop systeem voor bakker, bakker online, online bakker"
        />
        <link rel="canonical" href="https://ubicopia.be/cases/bakker/" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <section style={bakerystyle}>
        <div className="container">
          <BackToCases />
        </div>
        <div className="container boxcornsilk" id="title">
          <div className="row d-flex p-3">
            <div className="boxdark col-lg-6 d-flex justify-content-center align-items-center p-3 my-3">
              <div className="text-white p-3">
                <h1>
                  <TL text="Webwinkel voor de bakker" />
                </h1>
                <p>
                  <TL text="Ubicopia® biedt tal van voordelen voor bakkers die een webwinkel wensen op te starten." />
                </p>
                <div className="fs-1">
                  <TL text="Klantenbinding" />.
                </div>
                <p>
                  <TL text="Je connecteert met de moderne consument en organiseert je orderflow." />
                </p>
                <div className="fs-1">
                  <TL text="Efficiëntie" />
                </div>
                <p>
                  <TL text="Het systeem werkt voor jou en automatiseert vele van je taken." />
                </p>
                <div className="fs-1">
                  <TL text="Omzetstijging" />
                </div>
                <p>
                  <TL text="Je zet in op een extra verkoopkanaal. Je connecteert met een ander type consument." />
                </p>
              </div>
            </div>
            <div className="col-lg-6 my-3 p-3 d-flex align-items-center justify-content-center">
              <div>
                <img
                  className="w-100 h-auto"
                  src={etalagebakker}
                  alt="webwinkel voor bakker"
                  width="538"
                  height="358"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container boxtransp70 text-white p-5 my-5">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="text-start">
                <TL text="Verhoging klantenbinding" />
              </h2>
              <h3 className="fs-5">
                <TL text="Online marketing" />
              </h3>
              <p className="ms-4">
                <TL text="Vele zelfstandigen zijn reeds vertrouwd met het gebruik van facebook en hebben dikwijls 1000+ volgers." />
              </p>
              <p className="ms-4">
                <TL text="Nieuwe producten, uitzonderlijke sluitingen, speciale acties worden vie Facebook aan de klandizie meegedeeld. Dit is echter passieve communicatie." />
              </p>
              <p className="ms-4">
                <TL text="Met het Ubicopia® marketing-mail pakket, standaard inclusief, kan je mailen naar je klantenbestand en zo actief je klandizie informeren over je initiatief of nieuws." />
              </p>
              <h3 className="fs-5">
                <TL text="Een duidelijke, snelle webwinkel" />
              </h3>
              <p className="ms-4">
                <TL text="Simpel, snel en efficiënt zijn de sleutelwoorden. Klanten kunnen snel hun aankooplijst samenstellen, afhaalmoment kiezen en indien gewenst reeds online betalen." />
              </p>
              <h2 className="text-start">
                <TL text="Verhoging efficiëntie" />
              </h2>
              <p className="ms-4">
                <TL text="Tijd is tegenwoordig een kostbaar goed geworden. Vele mensen leven in een rush, hebben een drukke job, en wensen daarnaast zich zoveel als mogelijk te ontspannen. Online aankopen maken tegenwoordig deel uit van het dagelijkse leven van de moderne consument." />
              </p>
              <h3 className="fs-5">
                <TL text="Het systeem werkt voor jou en organiseert je orderflow." />
              </h3>
              <p className="ms-4">
                <TL text="Je kan zelf de hoeveelheid en duur van de tijdslots aangeven per dag van de week." />
              </p>
              <p className="ms-4">
                <TL text="Je definieert productgroepen die gebonden zijn aan levertijden." />
              </p>
              <p className="ms-4">
                <TL text="Indien je bijvoorbeeld bij de feestdagen wil dat je klanten op tijd bestellen voor taart. Met Ubicopia® kan je dit instellen en wordt dit mee aangegeven bij het shoppen en kiezen van het levermoment." />
              </p>
              <p className="ms-4">
                <TL text="Het systeem bundelt de te maken bereidingen per leverdag of levermoment, zo verhoog je je efficiëntie bij de bereidingen." />
              </p>
              <h2 className="text-start">
                <TL text="Stijging omzet" />
              </h2>
              <h3 className="fs-5">
                <TL text="De moderne consument" />
              </h3>
              <p className="ms-4">
                <TL text="Het is geweten dat de moderne consument meer en meer online gaat shoppen. Met een eigen gepersonaliseerde webwinkel trek je de moderne consument aan en verhoog je je omzet." />
              </p>
            </div>
            <div className="col-lg-4">
              <div>
                <img
                  className="w-100 h-auto"
                  src={newgeneration}
                  alt="webshop to connect with the new generation consumer"
                  width="332"
                  height="375"
                />
                <p className="ms-1">
                  <TL text="Connecteren met de moderne consument" />
                </p>
              </div>
              <div className="mt-5">
                <img
                  className="w-100 h-auto"
                  src={time}
                  alt="tijdwinst is essentieel"
                  width=""
                  height=""
                />
                <p className="ms-1">
                  <TL text="Tijdswinst is essentieel" />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <HomeInteraction />
        </div>
        <div className="container mt-5 boxtransp70 p-5 text-white">
          <div className="row">
            <h2>
              <TL text="Dankzij de configureerbare interface biedt Ubicopia® een  kant-en-klare oplossing voor de bakkerij." />
            </h2>
            <p>
              <strong>
                <TL text="Bovendien wordt via het pakket Ubicopia® microSite het verkoopkanaal en een eenvoudige website samen aangeboden." />
              </strong>
            </p>
            <p>
              <a
                className="link-primary"
                href="https://demosite.ubicopia.be"
                aria-label="go to demosite of Ubicopia"
              >
                <TL text="Ga naar voorbeeld" />
                <i className="bi bi-arrow-right ms-3"></i>
              </a>
            </p>
            <ul className="list-group bg-dark">
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Door de partnering met Worldline kan de klant op voorhand betalen, zo verliezen we geen tijd meer bij het afhaalmoment." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="De afhaalmomenten kunnen vrij worden bepaald, en kan je verhogen naar het weekend toe, wanneer het drukker wordt. Zo kan je je personeel ontlasten en piekmomenten vermijden. Het werk kan gelijkmatiger worden verdeeld." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Bovendien kan je het maximum aantal bestellingen per timeslot ingeven, en een levertijd, in uren of dagen, hanteren, zodat je ruim de tijd hebt om voor voldoende ingrediënten te zorgen." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Via gepersonaliseerde emails wordt de klant op de hoogte gehouden van de voortgang van zijn bestelling." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Wat met het persoonlijke contact - bij het verwerken zie je bij elke bestelling de naam en contactgegevens, door hierop te klikken bel je automatisch (Google Android), of start je een email op. Zo blijf je steeds in nauw contact met de klant, en kan je hem/haar snel contacteren wanneer er iets niet duidelijk is." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Ubicopia® voorziet de mogelijkheid marketing mails te versturen naar je klanten. Zo informeer je je volledige klantenbestand in één klap !" />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Door de makkelijke overzichten voor de boekhouding en de orderverwerking win je al snel veel tijd en kan je je focussen op je zaak en je leven, ipv administratie." />
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="container">
          <BackToCases />
        </div>
      </section>
    </div>
  );
};

export default CaseBakker;
