import React from "react";
import { Helmet } from "react-helmet";
import "../products.css";
import TL from "../Components/TL";
import Configurator from "../Components/Configurator";
import { keywordsproduct } from "../Data/keywordsproduct";

import vegetables from "../Media/vegetables.webp";
import blocks from "../Media/blocks.webp";

const HomeProductPage = () => {
  const styleVegetables = {
    backgroundImage: `url(${vegetables})`,
    height: "660px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "bottom",
  };

  const styleBlocks = {
    backgroundImage: `url(${blocks})`,

    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "bottom",
  };

  const v = <i className="bi bi-check-circle-fill"></i>;
  const n = <i className="bi bi-dash-circle"></i>;

  return (
    <div className="productpage">
      <Helmet>
        <title>Ubicopia® - Producten</title>
        <meta
          name="description"
          content="Ontdek hier de prijzen voor de verschillende Ubicopia® oplossingen. U start al een webwinkel vanaf 55€/maand."
        />
        <meta name="twitter:title" content="Ubicopia® - Producten" />
        <meta
          name="twitter:description"
          content="Ontdek hier de prijzen voor de verschillende Ubicopia® oplossingen. U start al een webwinkel vanaf 55€/maand."
        />
        <meta name="keywords" content={keywordsproduct} />
        <link rel="canonical" href="https://ubicopia.be/products/" />
        <meta name="robots" content="index,follow" />
      </Helmet>

      <section
        className="darksection producttitlesection"
        style={styleVegetables}
      >
        <div className="container py-5 mb-5">
          <div className="coverbox boxtransp mb-5">
            <div className="container-fluid py-5">
              <div className="d-flex align-items-center row">
                <div className="col-lg-7 text-center">
                  <div>
                    <h1 className="display-5 fw-bold text-white">
                      <TL text="Ontdek de verschillende producten van Ubicopia®" />
                    </h1>
                    <p className="fs-4">
                      <TL text="En vind je oplossing met de configurator." />
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 coverbox boxolive text-black d-flex align-items-center text-white text-center">
                  <div className="row">
                    <p className="fs-2 fw-bold">
                      <TL text="Het enige verschil zit in de grootte van je webshop, en de architectuur van je webpagina. Geen verborgen verrassingen." />
                    </p>
                    {/* <img
                      className="butcher"
                      src={butcher}
                      alt="slager digitaal"
                    /> */}
                  </div>
                </div>
                <div className="col-md-1"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-white">
        <div className="my-3" id="configurator">
          <Configurator />
        </div>
      </section>
      <section className="darksection py-3" style={styleBlocks}>
        <div className="container bg-black">
          <div className="d-flex align-items-center row coverbox ">
            <div className="col-lg-7 text-center">
              <div>
                <h2 id="vergelijk" className="display-5 fw-bold text-white">
                  <TL text="Vergelijk de kenmerken van elk pakket" />
                </h2>
                <p className="fs-4">
                  <TL text="en check of het voldoet aan je verwachtingen" />
                </p>
              </div>
            </div>
            <div className="col-lg-4 coverbox boxolive boxright boxhigh  d-flex align-items-center text-center">
              <div className="row">
                <p className="fs-2 fw-bold">
                  <TL text="De Ubicopia® microShop heeft steeds dezelfde kenmerken, welke grootte of pakket u ook kiest." />
                </p>
                {/* <img
                      className="butcher"
                      src={butcher}
                      alt="slager digitaal"
                    /> */}
              </div>
            </div>
            <div className="col-md-1"></div>
          </div>
        </div>

        <div className="container">
          <table className="table table-sm text-white boxtransp mt-3">
            <thead>
              <tr>
                <th scope="col">
                  <TL text="Ubicopia® Product" />
                </th>
                <th scope="col">micro-Shop</th>
                <th scope="col">micro-Site</th>
                <th scope="col">compo-Site</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              <tr>
                <th scope="row">
                  <TL text="Technologie" />
                </th>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <TL text="Snelle interface - 'Headless' architectuur" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Onbeperkte upgrades" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Zelf uitbreidbare architectuur" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Dagelijkse databaseback-ups" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <th scope="row">
                  <TL text="Webshop ervaring" />
                </th>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <td>
                  <TL text="Levertijd per product in te stellen" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Product opties vrij in te stellen" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>

              <tr>
                <td>
                  <TL text="Volledige checkout flow" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Meerdere addressen per klant" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Bestelhistoriek klant" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Orderbevestiging, faktuur pdf" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Onbeperkte afhaal locaties" /> (POS)
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Onbeperkte afhaal momenten" /> (POS)
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Instelbare timeslots" /> (POS)
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Instelbaar aantal bestellingen per timeslot" />{" "}
                  (POS)
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Online betalingen" /> (Worldline)<sup>(3)</sup>
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Verzendingen" /> (PostNL)<sup>(4)</sup>
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Gepersonaliseerde meldingen" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Bestelstatus opvolgen" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Opvolgen levering" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Professionele verwerking van bestellingen" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Verse bereidingen met gedeelde timer" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Alle instellingen vrij te kiezen" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <th scope="row">
                  <TL text="Inzichten en Customer relations" />
                </th>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <TL text="Downloadbare klantengegevens" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Ingebouwde marketing emails" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <th scope="row">
                  <TL text="Boekhoudkundige functionaliteiten" />
                </th>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <TL text="Dagontvangsten per BTW groep" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Automatische orderbevestiging, faktuur pdf" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <th scope="row">
                  <TL text="Webshop Integratie" />
                </th>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <TL text="Snelle integratie in i-frame" />
                </td>
                <td>{v}</td>
                <td>{v}</td>
                <td>{n}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Integratie van de webshop in de website" />
                </td>
                <td>{n}</td>
                <td>{v}</td>
                <td>{n}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Samenstelbare modulaire integratie" />
                </td>
                <td>{n}</td>
                <td>{n}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <th scope="row">
                  <TL text="Website" />
                </th>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <TL text="Landingpage + 3" />
                </td>
                <td>{n}</td>
                <td>{v}</td>
                <td>{n}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Gepersonaliseerde website" />
                </td>
                <td>{n}</td>
                <td>{n}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Webhosting inbegrepen" />
                </td>
                <td>{n}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Domeinnaam inclusief" />
                  <sup>(2)</sup>
                </td>
                <td>{n}</td>
                <td>{v}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td>
                  <TL text="Site d'internet met CMS" />
                </td>
                <td>{n}</td>
                <td>{n}</td>
                <td>{v}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
          <div className="fst-italic text-white">
            <p>
              (1) <TL text="BTW niet inbegrepen" />
            </p>
            <p>
              (2) <TL text="max.20€/jr" />
            </p>
            <p>
              (3) <TL text="vereist een account met Worldline" />
            </p>
            <p>
              (4) <TL text="vereist een account met PostNL" />
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeProductPage;
