export const talen = [
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ Header ----------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  { NL: "Cases", EN: "Cases", FR: "Des cas" },
  {
    NL: "Producten",
    EN: "Products",
    FR: "Produits",
  },
  { NL: "Tech", EN: "Tech", FR: "Tech" },
  { NL: "Contact", EN: "Contact", FR: "Contact" },
  { NL: "Over", EN: "About", FR: "À propos" },
  { NL: "Lees meer", EN: "Read more", FR: "lisez plus" },
  { NL: "Lees minder", EN: "Read less", FR: "lisez moins" },
  {
    NL: "Enkele uitgewerkte cases waarin u zich kan herkennen.",
    EN: "Some detailed cases in which you can recognize your business.",
    FR: "Quelques cas détaillés dans lesquels vous pouvez reconnaître votre entreprise.",
  },
  {
    NL: "Zo komt u te weten of Ubicopia iets voor u is.",
    EN: "So you can find out if Ubicopia® is something for you.",
    FR: "Ainsi, vous pourrez découvrir si Ubicopia® est fait pour vous.",
  },
  {
    NL: "Case: U hebt een kleinhandel, maar nog geen website.",
    EN: "Case: You have a retail business, but no website.",
    FR: "Cas: Commerce de détail, vous n'avez pas encore votre propre site web.",
  },
  {
    NL: "Case: U hebt een zaak met verschillende winkels.",
    EN: "Case: Small retail, with multiple shops.",
    FR: "Cas: La vente au détail. Vous avez plusieurs magasins.",
  },
  {
    NL: "Case : Traiteur.",
    EN: "Case : Catering.",
    FR: "Cas : Traiteur.",
  },
  {
    NL: "Case : De foodtruck. Verse bereidingen.",
    EN: "Case : Foodtruck. Fresh preparations. ",
    FR: "Cas : Food-truck. Préparations fraîches.",
  },
  {
    NL: "Case : De klassieke webshop",
    EN: "Case : A classic webshop",
    FR: "Cas : Ubicopia® comme boutique en ligne classique",
  },
  {
    NL: "Ontdek de unieke producten van Ubicopia®, oplossingen speciaal voor kleinhandel ontwikkeld.",
    EN: "Discover the unique products of Ubicopia®, solutions especially developed for retail.",
    FR: "Découvrez les produits uniques d'Ubicopia®, des solutions spécialement développées pour le commerce de detail.",
  },
  {
    NL: "Geen zware investering",
    EN: "Not a large investment",
    FR: "Pas de gros investissement",
  },
  {
    NL: "Makkelijk te gebruiken",
    EN: "Easy to use",
    FR: "Facile à utiliser",
  },
  {
    NL: "Onmiddellijk beschikbaar",
    EN: "Immediately available",
    FR: "Immédiatement disponible",
  },
  {
    NL: "microShop - de supersnelle, gemakkelijk implementeerbare oplossing",
    EN: "microShop - superfast, easy to implement solution",
    FR: "microShop - solution ultra-rapide et facile à mettre en œuvre",
  },

  {
    NL: "microSite - supersnel, je eigen website met webshop",
    EN: "microSite - superfast, your own website with webshop",
    FR: "microSite - super rapide, votre propre site web avec boutique en ligne",
  },

  {
    NL: "compoSite - volledig op maat gemaakt",
    EN: "compoSite - completely custom made",
    FR: "compoSite - entièrement sur mesure",
  },

  {
    NL: "Kom meer te weten over de gebruikte technologie, en wat Ubicopia® zo uniek maakt voor u.",
    EN: "Find out more about the technology used, and what makes Ubicopia® unique to you.",
    FR: "Découvrez la technologie utilisée et ce qui rend Ubicopia® unique pour vous.",
  },

  {
    NL: "Lees meer over Saas",
    EN: "Read more about Saas",
    FR: "En savoir plus sur Saas",
  },

  {
    NL: "Contacteer ons op de manier die voor u het makkelijkst is",
    EN: "Contact us in the way that is most convenient for you",
    FR: "Contactez-nous de la manière qui vous convient le mieux",
  },

  {
    NL: "Telefoon",
    EN: "Phone",
    FR: "Téléphone",
  },

  {
    NL: "Contact Formulier",
    EN: "Contact Form",
    FR: "Formulaire de contact",
  },

  {
    NL: "Technische ondersteuning",
    EN: "Technical support",
    FR: "Support technique",
  },

  {
    NL: "Kom meer te weten over het verhaal achter Ubicopia®",
    EN: "Learn more about the story behind Ubicopia®",
    FR: "En savoir plus sur l'histoire d'Ubicopia®",
  },

  {
    NL: "Het verhaal",
    EN: "The story",
    FR: "L'histoire",
  },

  {
    NL: "Het bedrijf",
    EN: "The company",
    FR: "L'entreprise",
  },
  {
    NL: "Het team",
    EN: "The team",
    FR: "L'équipe",
  },
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ Homepage --------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Ubicopia® is de alles-in-één tool voor de zelfstandige die een eigen webwinkel wil opstarten.",
    EN: "UbiCopia® is the all-in-one tool for the retailer who wants to setup his own webshop.",
    FR: "UbiCopia® est l'outil tout-en-un pour le commerçant qui souhaite créer sa propre boutique en ligne.",
  },
  {
    NL: "UbiCopia® is gericht op de kleinhandel, die op een eenvoudige en efficiënte wijze online activiteiten met e-commerce wil opstarten.",
    EN: "UbiCopia® is aimed at the small retailer who looks up to a traditionally complicated, lengthy and costly start-up of a website with webshop.",
    FR: "UbiCopia® s'adresse au commerce de détail qui s'attend à un démarrage traditionnellement compliqué, long et coûteux d'un site Web avec boutique en ligne.",
  },
  {
    NL: "UbiCopia® is een digitaal initiatief om de mensen ertoe aan te zetten lokaal te kopen.",
    EN: "UbiCopia® is a digital initiative to encourage people to buy locally.",
    FR: "UbiCopia® est une initiative digitale pour encourager les gens à acheter localement.",
  },
  {
    NL: "De online partner voor de kleinhandel",
    EN: "The online partner for retail",
    FR: "Le partenaire en ligne pour le commerce de détail",
  },
  {
    NL: "Dankzij nieuwe technologieën valt e-commerce binnen bereik van elke kleinhandel of micro-onderneming.",
    EN: "Thanks to new technologies, e-commerce is within reach of any retail or micro-enterprise.",
    FR: "Grâce aux nouvelles technologies, le e-commerce est à la portée de tout retail ou micro-entreprise.",
  },
  {
    NL: "Simpel, Snel, en efficient.",
    EN: "Simple, Fast, and Efficient.",
    FR: "Simple, rapide et efficace.",
  },

  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ HomeCarrousel --------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Webwinkel voor de bakker",
    EN: "Online shop for the baker",
    FR: "Boutique en ligne pour le boulanger",
  },
  {
    NL: "Ideaal als webshop tool voor bakkers.",
    EN: "Ideal as a webshop tool for bakers.",
    FR: "Idéal comme outil de boutique en ligne pour les boulangers.",
  },
  {
    NL: "Buitengewoon handig voor meerdere winkels of ophaalpunten.",
    EN: "Extremely useful for multiple stores or collection points.",
    FR: "Extrêmement utile pour plusieurs magasins ou points de collecte.",
  },
  {
    NL: "Besteltool en webwinkel voor de broodjeszaak",
    EN: "Your (business) customers order in advance, pay and invoice is fully automatic.",
    FR: "Vos clients (professionnels) commandent à l'avance, paient et facturent de manière entièrement automatique.",
  },
  {
    NL: "Jouw (zakelijke) klanten bestellen op voorhand, betalen en factureren verloopt volledig automatisch.",
    EN: "Your (business) customers order in advance, pay and invoice is fully automatic.",
    FR: "Vos clients (professionnels) commandent à l'avance, paient et facturent de manière entièrement automatique.",
  },
  {
    NL: "Een overzichtelijke orderverwerking met afhaaltimer laat alles ordelijk verlopen.",
    EN: "Clear order processing with a pick-up timer ensures that everything runs smoothly.",
    FR: "Un traitement clair des commandes avec une minuterie de ramassage garantit que tout se déroule sans heurts.",
  },
  {
    NL: "Besteltool en webwinkel voor de detailhandel - traiteur",
    EN: "Ordering tool and webshop for the retail trade - caterer",
    FR: "Outil de commande et boutique en ligne pour le commerce de détail - traiteur",
  },
  {
    NL: "Alles-in-één tool voor bij feestdagen - overzichtelijke orderverwerking voor de vele bestellingen.",
    EN: "All-in-one tool for busy periods - clear order processing for the many orders.",
    FR: "Outil tout-en-un pour les jours de fëtes - traitement clair des commandes pour les nombreuses commandes.",
  },
  {
    NL: "Instelbare hoeveelheid bestellingen per afhaalslot om de werklast te verdelen.",
    EN: "Adjustable amount of orders per collection slot to distribute the workload.",
    FR: "Quantité ajustable de commandes par créneau de collecte pour répartir la charge de travail.",
  },
  {
    NL: "Besteltool en webwinkel voor de slager - traiteur",
    EN: "Ordering tool and webshop for the butcher - caterer",
    FR: "Outil de commande et boutique en ligne pour le boucher - traiteur",
  },
  {
    NL: "Bestellen per gewicht, opties voor aantal personen per schotel, samenstellen gerechten, ...",
    EN: "Order by weight, options for number of people per dish, compose dishes, ...",
    FR: "Commande au poids, choix du nombre de personnes par plat, composition des plats, ...",
  },
  {
    NL: "Overzichtelijke orderverwerking - verdeling van afhaalmomenten om de vele bestellingen bij piekmomenten aan te kunnen.",
    EN: "Clear order processing - distribution of collection times to handle the many orders at peak times.",
    FR: "Traitement clair des commandes - répartition des heures de collecte pour traiter les nombreuses commandes aux heures de pointe.",
  },
  {
    NL: "Besteltool en afhaaltool voor de foodtruck",
    EN: "Order tool and pick-up tool for the food truck",
    FR: "Outil de commande et outil de ramassage pour le food truck",
  },
  {
    NL: "Voor warme en verse bereidingen wordt de afhaaltimer met email of sms gebruikt.",
    EN: "For hot and fresh preparations, the pick-up timer with email or SMS is used.",
    FR: "Pour les préparations chaudes et fraîches, la minuterie de prise en charge avec e-mail ou SMS est utilisée.",
  },
  {
    NL: "Zelf te definiëren afhaalslots en aantal bestellingen per slot.",
    EN: "Self-definable pick-up slots and number of orders per slot.",
    FR: "Créneaux de ramassage auto-définissables et nombre de commandes par créneau.",
  },
  {
    NL: "Zelf te definiëren afhaalslots en aantal bestellingen per slot.",
    EN: "Self-definable pick-up slots and number of orders per slot.",
    FR: "Créneaux de ramassage auto-définissables et nombre de commandes par créneau.",
  },
  {
    NL: "Besteltool en afhaaltool voor de frituur",
    EN: "Ordering tool and pick-up tool for fries",
    FR: "Outil de commande et outil de ramassage pour la friterie",
  },
  {
    NL: "Voor warme en verse bereidingen wordt de timer met email of sms gebruikt.",
    EN: "For hot and fresh preparations, the timer with email or SMS is used.",
    FR: "Pour les préparations chaudes et fraîches, le minuteur avec e-mail ou SMS est utilisé.",
  },
  {
    NL: "Zelf te definiëren afhaalslots. Weg met wachttijd.",
    EN: "Self-definable pick-up slots. Eliminate waiting time.",
    FR: "Emplacements de ramassage auto-définissables. Éliminer le temps d'attente.",
  },
  {
    NL: "Vorige",
    EN: "Previous",
    FR: "Précédent",
  },
  {
    NL: "Volgende",
    EN: "Next",
    FR: "suivant",
  },
  {
    NL: "",
    EN: "",
    FR: "",
  },
  {
    NL: "",
    EN: "",
    FR: "",
  },

  {
    NL: "Boek een Demo",
    EN: "Book a Demo",
    FR: "Réserver une démo",
  },
  {
    NL: "Info & prijzen",
    EN: "Info & Pricing",
    FR: "Info & Prix",
  },
  {
    NL: "Eerst even praten",
    EN: "Let's talk first",
    FR: "Parlons d'abord",
  },
  {
    NL: "Persoonlijk contact",
    EN: "Personal contact",
    FR: "Contact personnel",
  },
  {
    NL: "Ook wij vinden een persoonlijk contact zéér belangrijk.",
    EN: "Personal contact is also very important to us.",
    FR: "Le contact personnel est également très important pour nous.",
  },
  {
    NL: "Daarom mag u ons steeds vrijblijvend contacteren met uw vragen.",
    EN: "That is why you can always contact us without obligation with your questions.",
    FR: "C'est pourquoi vous pouvez toujours nous contacter, sans engagement, avec vos questions.",
  },
  {
    NL: "Een telefonisch contact is steeds mogelijk tijdens de werkuren.",
    EN: "A phone contact is always possible during traditional working hours.",
    FR: "Un contact téléphonique est toujours possible pendant les heures de travail.",
  },
  {
    NL: "Wij spreken Nederlands, Engels, Frans en Duits.",
    EN: "We speak Dutch, English, French and German.",
    FR: "Nous parlons néerlandais, anglais, français et allemand.",
  },
  {
    NL: "Ons telefoonnummer is :",
    EN: "Our phone number is :",
    FR: "Notre numéro de téléphone est :",
  },
  {
    NL: "Indien u toevallig het antwoordapparaat treft, laat dan steeds een bericht, we nemen dan spoedig contact met u op.",
    EN: "If you happen to end on the answering machine, please leave a message and we will contact you as soon as possible.",
    FR: "Si vous vous retrouvez sur le répondeur, laissez un message et nous vous répondrons dans les plus brefs délais.",
  },
  {
    NL: "Ook een video-conferentie is steeds mogelijk.",
    EN: "A video conference is also always possible.",
    FR: "Une visioconférence est également toujours possible.",
  },
  {
    NL: "Hiervoor stuurt u dan best een mailtje via",
    EN: "It is best to send an email for this",
    FR: "Il est préférable d'envoyer un e-mail pour cela",
  },
  {
    NL: "Een persoonlijk informatief bezoek, kan uiteraard ook, dit kan via het boeken van een demo. Die steeds kosteloos en vrijblijvend is.",
    EN: "A personal informative visit is of course also possible, this can be done by booking a demo. Which is always free of charge and without obligation.",
    FR: "Une visite informative personnelle est bien sûr également possible, cela peut être fait en réservant une démo. Ce qui est toujours gratuit et sans engagement.",
  },
  {
    NL: "Boek een demo",
    EN: "Book a demo",
    FR: "Réserver une démo",
  },
  {
    NL: "Geef hier je gegevens, en je voorkeur voor de demo op op zodat we je kunnen contacteren.",
    EN: "Enter your details and your preference for the demo here so that we can contact you.",
    FR: "Entrez vos coordonnées et votre préférence pour la démo ici afin que nous puissions vous contacter.",
  },
  {
    NL: "Voorkeur Dag /Tijd",
    EN: "Preferred Day / Time",
    FR: "Jour/Heure préféré(e)",
  },
  {
    NL: "geen voorkeur",
    EN: "no preference",
    FR: "pas de préférence",
  },
  {
    NL: "Maandag",
    EN: "Monday",
    FR: "Lundi",
  },
  {
    NL: "Dinsdag",
    EN: "Tuesday",
    FR: "Mardi",
  },
  {
    NL: "Woensdag",
    EN: "Wednesday",
    FR: "Mercredi",
  },
  {
    NL: "Donderdag",
    EN: "Thursday",
    FR: "Jeudi",
  },
  {
    NL: "Vrijdag",
    EN: "Friday",
    FR: "Vendredi",
  },
  {
    NL: "Zaterdag",
    EN: "Saturday",
    FR: "Samedi",
  },
  {
    NL: "Tijdstip",
    EN: "Time",
    FR: "Temps",
  },
  {
    NL: "Fysiek",
    EN: "In Person",
    FR: "Personnel",
  },
  {
    NL: "Teams videoconference",
    EN: "Teams videoconference",
    FR: "Vidéoconférence Teams",
  },
  {
    NL: "Opmerkingen",
    EN: "Remarks",
    FR: "Commentaires",
  },
  {
    NL: "Annuleer",
    EN: "Cancel",
    FR: "Annuler",
  },
  {
    NL: "Makkelijk in gebruik",
    EN: "Easy to use",
    FR: "Facile à utiliser",
  },
  {
    NL: "Kan je online shoppen, dan kan je met Ubicopia® werken",
    EN: "If you can online shop, you can work with Ubicopia®",
    FR: "Si vous pouvez acheter en ligne, vous pouvez travailler avec Ubicopia®",
  },
  {
    NL: "Verhoog je omzet",
    EN: "Increase your turnover",
    FR: "Augmentez votre chiffre d'affaires",
  },
  {
    NL: "Uw winkel is altijd open, zelfs buiten de openingsuren",
    EN: "Your store is always open, even outside opening hours",
    FR: "Votre magasin est toujours ouvert, même en dehors des heures d'ouverture",
  },
  {
    NL: "Verhoog je efficiëntie",
    EN: "Increase your efficiency",
    FR: "Augmentez votre efficacité",
  },
  {
    NL: "Ga mee met je tijd",
    EN: "Evolve with the time",
    FR: "Évoluer avec le temps",
  },
  {
    NL: "Connecteer met de moderne consument",
    EN: "Connect with the modern consumer",
    FR: "Connectez-vous avec le consommateur moderne",
  },
  {
    NL: "Een eigen webwinkel",
    EN: "Your own webshop",
    FR: "Votre propre boutique en ligne",
  },
  {
    NL: "Ga voor je eigen identiteit, en onderscheid je",
    EN: "Go for your own identity, and stand out",
    FR: "Optez pour votre propre identité et démarquez-vous",
  },
  {
    NL: "Klantenbinding",
    EN: "Customer loyalty",
    FR: "Fidélité du consommateur",
  },
  {
    NL: "Je verstevigt je band met je klanten",
    EN: "Strengthen your  customer relationship",
    FR: "Vous renforcez votre relation avec vos clients",
  },
  {
    NL: "Spaar tijd en laat het systeem voor jou werken",
    EN: "Save time and let the system work for you",
    FR: "Gagnez du temps et laissez le système travailler pour vous",
  },
  {
    NL: "Ubicopia® = Besteltool - Afhaaltool - Webwinkel - Orderverwerking - Boekhouding - Mail & Marketing",
    EN: "Ubicopia® = Order tool - Collection tool - Web shop - Order processing - Accounting - Mail & Marketing",
    FR: "Ubicopia® = Outil de commande - Outil de récupérer - Boutique en ligne - Traitement des commandes - Comptabilité - Courrier & Marketing",
  },
  {
    NL: "Ubicopia® is makkelijk in gebruik",
    EN: "Ubicopia® is easy to use",
    FR: "Ubicopia® est facile à utiliser",
  },
  {
    NL: "Via eenvoudige menu's configureer je volledig jouw webwinkel. Je activeert of deactiveert je producten. Kies je categoriën, je afhaalmomenten, het aantal leveringen per afhaalmoment, de levertijden, locaties, enz....",
    EN: "You can fully configure your webshop via simple menus. You activate or deactivate your products. Choose your categories, your pick-up times, the number of deliveries per pick-up time, the delivery times, locations, etc....",
    FR: "Vous pouvez entièrement configurer votre boutique en ligne par des menus simples. Vous activez ou désactivez vos produits. Choisissez vos catégories, vos heures de récupérer, le nombre de livraisons par heure de récupérer, les horaires de livraison, les lieux, etc...",
  },
  {
    NL: "Een grondige opleiding om vertrouwd te raken met de mogelijkheden is steeds inbegrepen.",
    EN: "Thorough training to familiarize yourself with the possibilities is always included.",
    FR: "Une formation approfondie pour vous familiariser avec les possibilités est toujours incluse.",
  },
  {
    NL: "Met de video-tutorials, kan je alles nog eens nagaan.",
    EN: "With the video tutorials, you can check everything again.",
    FR: "Avec les didacticiels vidéo, vous pouvez tout vérifier à nouveau.",
  },
  {
    NL: "Heb je nadien nog vragen, kan je steeds rekenen op een stevige support.",
    EN: "If you still have questions afterwards, you can always count on solid support.",
    FR: "Si vous avez encore des questions par la suite, vous pouvez toujours compter sur un soutien solide.",
  },
  {
    NL: "Met Ubicopia® verhoog je je omzet.",
    EN: "With Ubicopia® you increase your turnover.",
    FR: "Avec Ubicopia® vous augmentez votre chiffre d'affaires.",
  },
  {
    NL: "Je winkel is altijd open, zelfs buiten de openingsuren.",
    EN: "Your store is always open, even outside opening hours.",
    FR: "Votre magasin est toujours ouvert, même en dehors des heures d'ouverture.",
  },
  {
    NL: "Klanten kunnen steeds online shoppen bij jou. Ook al is jouw winkel gesloten.",
    EN: "Customers can always shop online with you. Even if your store is closed.",
    FR: "Les clients peuvent toujours faire leurs achats en ligne avec vous. Même si votre magasin est fermé.",
  },
  {
    NL: "Je hebt toegang tot klanten die je anders moeilijk of niet bereikt.",
    EN: "You have access to customers that are otherwise difficult or impossible to reach.",
    FR: "Vous avez accès à des clients qui sont autrement difficiles ou impossibles à atteindre.",
  },
  {
    NL: "Mensen met een drukke job hebben niet altijd de tijd om rustig naar de buurtwinkel te wandelen. Die gaan snel even naar de supermarkt, of doen hun boodschappen bij een grote online webwinkel.",
    EN: "People with a busy job don't always have the time to take a leisurely stroll to the convenience store. They quickly go to the supermarket, or do their shopping at a large online web store.",
    FR: "Les personnes qui ont un travail chargé n'ont pas toujours le temps de se promener tranquillement au dépanneur. Ils vont rapidement au supermarché, ou font leurs courses dans une grande boutique en ligne.",
  },
  {
    NL: "Ubicopia® geeft je toegang tot deze klanten.",
    EN: "Ubicopia® gives you access to these customers.",
    FR: "Ubicopia® vous donne accès à ces clients.",
  },
  {
    NL: "Met Ubicopia® verhoog je je efficiëntie.",
    EN: "With Ubicopia® you increase your efficiency.",
    FR: "Avec Ubicopia® vous augmentez votre efficacité.",
  },
  {
    NL: "Laat het systeem voor jou werken.",
    EN: "Let the system work for you.",
    FR: "Laissez le système travailler pour vous.",
  },
  {
    NL: "Als handelaar in de voeding heb je dikwijls te maken met piekmomenten, denk aan de verschillende feestdagen, of bij de eerste zonnestralen wanneer iedereen plots zijn BBQ bovenhaalt. Het verwerken van deze piekmomenten vraagt extra personeel, extra middelen, en veel stressbestendigheid.",
    EN: "As a food trader, you often have to deal with peak times, such as the various holidays, or the first rays of sunshine when everyone suddenly takes out their BBQ. Processing these peak moments requires extra staff, extra resources, and a lot of stress resistance.",
    FR: "En tant que commerçant alimentaire, vous devez souvent composer avec des heures de pointe, comme les différentes fêtes, ou les premiers rayons de soleil lorsque tout le monde sort soudainement son BBQ. Le traitement de ces moments de pointe nécessite du personnel supplémentaire, des ressources supplémentaires et une grande résistance au stress.",
  },
  {
    NL: "Met Ubicopia® win je veel tijd doordat het systeem veel taken van je overneemt.",
    EN: "With Ubicopia® you save a lot of time because the system takes over many tasks for you.",
    FR: "Avec Ubicopia®, vous gagnez beaucoup de temps car le système prend en charge de nombreuses tâches à votre place.",
  },
  {
    NL: "Het systeem doet de volledige online orderverwerking",
    EN: "The system does the entire online order processing",
    FR: "Le système effectue l'intégralité du traitement de la commande en ligne",
  },
  {
    NL: "Verzorgt indien gewenst de facturatie en betalingen.",
    EN: "If desired, takes care of invoicing and payments.",
    FR: "Si vous le souhaitez, s'occupe de la facturation et des paiements.",
  },
  {
    NL: "Houdt je klanten automatisch op de hoogte over de status van hun bestelling.",
    EN: "Automatically keep your customers informed about the status of their order.",
    FR: "Informez automatiquement vos clients de l'état de leur commande.",
  },
  {
    NL: "Ubicopia® creëert een overzicht van je daginkomsten voor de wettelijk verplichte boekhouding.",
    EN: "Ubicopia® creates an overview of your daily income for the legally required accounting.",
    FR: "Ubicopia® crée un aperçu de vos revenus quotidiens pour la comptabilité légalement requise.",
  },
  {
    NL: "Ubicopia® geeft duidelijk weer wat je voor wie, waar en wanneer moet klaarmaken.",
    EN: "Ubicopia® clearly shows what you have to prepare for whom, where and when.",
    FR: "Ubicopia® montre clairement ce que vous devez préparer pour qui, où et quand.",
  },
  {
    NL: "Het systeem zorgt zelfs voor een overzicht per product zodat je deze efficiënter kan klaarmaken, als het ware een productieplanning.",
    EN: "The system even provides an overview per product so that you can prepare it more efficiently, as if it were a production schedule.",
    FR: "Le système fournit même une vue d'ensemble par produit afin que vous puissiez le préparer plus efficacement, comme s'il s'agissait d'un planning de production.",
  },
  {
    NL: "Zo kan je je gewonnen tijd gebruiken, en je aandacht toespitsen op andere aspecten van je zaak, zoals meer tijd spenderen aan het klaarmaken van je producten, of bedenken van nieuwe recepten, of wat tijd voor jezelf en je familie.",
    EN: "This way you can use the time you have gained and focus on other aspects of your business, such as spending more time preparing your products, or inventing new recipes, or some time for yourself and your family.",
    FR: "De cette façon, vous pouvez utiliser le temps que vous avez gagné et vous concentrer sur d'autres aspects de votre entreprise, comme passer plus de temps à préparer vos produits, ou inventer de nouvelles recettes, ou du temps pour vous et votre famille.",
  },
  {
    NL: "Indien je efficiëntie stijgt, stijgt hiermee je omzet en je winst.",
    EN: "If your efficiency increases, your turnover and profit will increase.",
    FR: "Si votre efficacité augmente, votre chiffre d'affaires et vos bénéfices augmenteront.",
  },
  {
    NL: "Vind aansluiting met de moderne consument",
    EN: "Connect with the modern consumer",
    FR: "Connectez-vous avec le consommateur moderne",
  },
  {
    NL: "Het is belangrijk mee te evolueren met je tijd.",
    EN: "It is important to evolve with the times.",
    FR: "Il est important d'évoluer avec son temps.",
  },
  {
    NL: "De traditionele buurtwinkel heeft zeker nog zijn waarde, en geen enkele dorps- of stadskern kan zich volwaardig noemen zonder zijn bakker, slager, frituur, ... Ook zorgt dit voor het behoud van het sociale contact.",
    EN: "The traditional local shop certainly still has its value, and no village or city center can call itself fully fledged without its baker, butcher, chip shop, ... This also ensures that social contact is maintained.",
    FR: "Le commerce de proximité traditionnel a certes encore sa valeur, et aucun village ou centre-ville ne peut se dire à part entière sans son boulanger, boucher, friterie,... C'est aussi la garantie du maintien du lien social.",
  },
  {
    NL: "Echter is het zo dat consumenten steeds meer en meer online zoeken en kopen. Mede ten gevolge van de coronacrisis koopt tegenwoordig 2 op 3 consumenten zowel online als offline.",
    EN: "However, consumers are increasingly searching and buying online. Partly as a result of the corona crisis, 2 out of 3 consumers nowadays buy both online and offline.",
    FR: "Cependant, les consommateurs recherchent et achètent de plus en plus en ligne. En partie à cause de la crise corona, 2 consommateurs sur 3 achètent aujourd'hui à la fois en ligne et hors ligne.",
  },
  {
    NL: "80% van de consumenten informeert zich eerst online alvorens de winkel fysiek op te zoeken.",
    EN: "80% of consumers first inquire online before physically visiting the store.",
    FR: "80% des consommateurs se renseignent d'abord en ligne avant de se rendre physiquement en magasin.",
  },
  {
    NL: "Dit duidt erop dat je je als handelaar tegenwoordig niet meer kan permitteren e-commerce aan je te laten voorbijgaan.",
    EN: "This indicates that as a trader you can no longer afford to miss out on e-commerce.",
    FR: "Cela indique qu'en tant que commerçant, vous ne pouvez plus vous permettre de passer à côté du commerce électronique.",
  },
  {
    NL: "Behoud je eigen identiteit",
    EN: "Keep your own identity",
    FR: "Gardez votre propre identité",
  },
  {
    NL: "Meer en meer handelaars gebruiken een eigen webshop (op maat)",
    EN: "More and more traders are using their own webshop (tailor-made)",
    FR: "De plus en plus de commerçants utilisent leur propre boutique en ligne (sur mesure)",
  },
  {
    NL: "Belangrijk om weten is ook dat er een sterke stijging is in de verkoop via een eigen webshop op maat.",
    EN: "It is also important to know that there is a strong increase in sales via our own tailor-made webshop.",
    FR: "Il est également important de savoir qu'il y a une forte augmentation des ventes via notre propre boutique en ligne sur mesure.",
  },
  {
    NL: "De online verkoop via online marktplaatsen is aan het terugvallen, de trend is een eigen webshop al dan niet of maat, of kant-en-klare software, zoals Ubicopia® die aanbiedt.",
    EN: "Online sales via online marketplaces are declining, the trend is for your own web shop, whether or not custom-made, or ready-to-use software, such as Ubicopia® offers.",
    FR: "Les ventes en ligne via les places de marché en ligne sont en baisse, la tendance est à votre propre boutique en ligne, sur mesure ou non, ou à des logiciels prêts à l'emploi, comme les offres Ubicopia®.",
  },
  {
    NL: "Net zoals je je met je zaak in het toonbeeld van de straat wil onderscheiden, is dit online ook het geval.",
    EN: "Just as you want to distinguish yourself with your business in the paragon of the street, this is also the case online.",
    FR: "Tout comme vous souhaitez vous distinguer avec votre entreprise dans le parangon de la rue, c'est également le cas en ligne.",
  },
  {
    NL: "Je hebt je eigen identiteit. Met een eigen gepersonaliseerde webwinkel, verstevig je je imago zowel online als offline.",
    EN: "You have your own identity. With your own personalized webshop, you strengthen your image both online and offline.",
    FR: "Vous avez votre propre identité. Avec votre propre boutique en ligne personnalisée, vous renforcez votre image en ligne et hors ligne.",
  },
  {
    NL: "Verhoog je klantenbinding",
    EN: "Increase your customer loyalty",
    FR: "Augmentez la fidélité de votre clientèle",
  },
  {
    NL: "Je hebt een bijkomende mogelijkheid om met je klanten te communiceren.",
    EN: "You have an additional opportunity to communicate with your customers.",
    FR: "Vous avez une possibilité supplémentaire de communiquer avec vos clients.",
  },
  {
    NL: "Uiteraard volledig in lijn met de GDPR wetgeving, verzorgt Ubicopia® je klantenbestand. Ingebouwd in Ubicopia kan je mailings naar je klanten versturen, hen op de hoogte te houden van bvb. nieuwe producten, speciale acties, onverwachte gebeurtenissen zoals een onverwachte sluiting wegens ziekte of overmacht.",
    EN: "Of course fully in line with the GDPR legislation, Ubicopia® takes care of your customer base. Built into Ubicopia® you can send mailings to your customers, keep them informed of e.g. new products, special promotions, unexpected events such as an unexpected closure due to illness or force majeure.",
    FR: "Bien entendu en totale conformité avec la législation RGPD, Ubicopia® prend soin de votre clientèle. Intégré à Ubicopia®, vous pouvez envoyer des mailings à vos clients, les tenir informés, par ex. nouveaux produits, promotions spéciales, événements imprévus tels qu'une fermeture imprévue pour cause de maladie ou de force majeure.",
  },
  {
    NL: "Bovendien zet je met Ubicopia een extra verkoopkanaal op waardoor je voor je klanten beter bereikbaar wordt. In tegenstelling tot eerder passieve communicatie zoals facebook, zorgt het Ubicopia mail systeem voor actieve en rechtstreekse communicatie met je klanten.",
    EN: "Moreover, with Ubicopia® you set up an extra sales channel that makes you more accessible to your customers. In contrast to rather passive communication such as Facebook, the Ubicopia mail system ensures active and direct communication with your customers.",
    FR: "De plus, avec Ubicopia®, vous mettez en place un canal de vente supplémentaire qui vous rend plus accessible à vos clients. Contrairement à une communication plutôt passive comme Facebook, le système de messagerie Ubicopia assure une communication active et directe avec vos clients.",
  },
  {
    NL: "Dit is trouwens een werkend voorbeeld.",
    EN: "This is a working example by the way.",
    FR: "Ceci est un exemple fonctionnel.",
  },
  {
    NL: "Ook hier gaat u onmiddellijk aan de slag.",
    EN: "Also here, you immediately get started.",
    FR: "Ici aussi, vous pouvez commencer tout de suite.",
  },
  {
    NL: "Bekijk de video",
    EN: "Watch the video",
    FR: "Voir la vidéo",
  },
  {
    NL: "Werkt Ubicopia ook voor mijn zaak?",
    EN: "Would Ubicopia® also work for my business?",
    FR: "Ubicopia® est-il utile pour mon entreprise?",
  },
  {
    NL: "Op de pagina 'cases' vindt u enkele uitgewerkte real-life situaties voor de kleinhandel. Hierin worden al de mogelijkheden ruim toegelicht.",
    EN: "On the 'cases' page you will find some detailed real-life situations for the retail trade. All the options are fully explained.",
    FR: "Sur la page 'cas', vous trouverez des situations concrètes détaillées pour le commerce de détail. Toutes les options sont expliquées en détail.",
  },
  {
    NL: "Ga naar cases",
    EN: "Go to cases",
    FR: "Aller aux cas",
  },
  {
    NL: "Hebt u een specifieke vraag, dan kan u ons die gerust stellen via bijgaand formulier, email of telefoon.",
    EN: "If you have a specific question, feel free to ask us via the enclosed form, email or telephone.",
    FR: "Si vous avez une question spécifique, n'hésitez pas à nous la poser via le formulaire ci-joint, par e-mail ou par téléphone.",
  },
  {
    NL: "Maakt u gebruik van het formulier, omschrijf dan goed uw zaak, uw product en hoe u uw producten tot bij uw klant brengt of wenst te brengen.",
    EN: "If you use the form, please describe your business, your product and how you bring or wish to bring your products to your customer.",
    FR: "Si vous utilisez le formulaire, veuillez décrire votre entreprise, votre produit et comment vous apportez ou souhaitez apporter vos produits à votre client.",
  },
  {
    NL: "We bekijken dan uw specifieke case en nemen zo snel als mogelijk contact op.",
    EN: "We will then review your specific case and contact you as soon as possible.",
    FR: "Nous examinerons ensuite votre cas spécifique et vous contacterons dans les plus brefs délais.",
  },
  {
    NL: "Alles is vrijblijvend.",
    EN: "No strings attached.",
    FR: "Tout est sans engagement.",
  },
  {
    NL: "Is Ubicopia® iets voor mij?",
    EN: "Is Ubicopia® right for me?",
    FR: "Ubicopia® est-il fait pour moi?",
  },
  {
    NL: "Voornaam",
    EN: "First name",
    FR: "Prénom",
  },
  {
    NL: "Naam",
    EN: "Name",
    FR: "Nom",
  },
  {
    NL: "Telefoon",
    EN: "Phone",
    FR: "Téléphone",
  },
  {
    NL: "Naam van je zaak",
    EN: "Your business name",
    FR: "Votre business",
  },
  {
    NL: "Mijn vraag",
    EN: "My question",
    FR: "Ma question",
  },
  {
    NL: "Verzend",
    EN: "Send",
    FR: "Envoyer",
  },
  {
    NL: "verplicht",
    EN: "required",
    FR: "obligatoire",
  },
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ HomeCasePage ----------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Ontdek hier de vele mogelijkheden van Ubicopia®",
    EN: "Discover the many possibilities of Ubicopia® here",
    FR: "Découvrez ici les nombreuses possibilités d'Ubicopia®",
  },
  {
    NL: "Webwinkel voor bakker",
    EN: "Webshop for bakery",
    FR: "Boutique en ligne pour boulangerie",
  },
  {
    NL: "Webwinkel voor slager",
    EN: "Webshop for butcher",
    FR: "Boutique en ligne pour boucher",
  },
  {
    NL: "Webwinkel voor traiteur",
    EN: "Webshop for caterer",
    FR: "Webshop pour traiteur",
  },
  {
    NL: "Webwinkel voor frituur",
    EN: "Webshop for fries",
    FR: "Boutique en ligne pour friterie",
  },
  {
    NL: "Webwinkel voor foodtruck",
    EN: "Webshop for foodtruck",
    FR: "Boutique en ligne pour food truck",
  },
  {
    NL: "Webwinkel voor kleinhandel",
    EN: "Webshop for small retail",
    FR: "Magasin de détail en ligne",
  },
  {
    NL: "Ook als standaard webshop",
    EN: "Also as a standard webshop",
    FR: "Également en tant que boutique en ligne standard",
  },
  {
    NL: "Bekijk de cases.",
    EN: "View the cases.",
    FR: "Voir les cas.",
  },
  {
    NL: "Zo komt u te weten of het iets voor jouw zaak is.",
    EN: "So you can find out the suitability for your business.",
    FR: "Ainsi, vous pouvez découvrir la pertinence pour votre entreprise.",
  },
  {
    NL: "De foodtruck. Voorbeeld voor verse bereidingen.",
    EN: "The food truck. Example for fresh preparations.",
    FR: "Le food-truck. Exemple pour les préparations fraîches.",
  },
  {
    NL: "We staan op verschillende plaatsen, op veranderende tijdstippen. Bijvoorbeeld op markten.",
    EN: "We are in different places, in changing times. For example in the markets.",
    FR: "Nous sommes dans des endroits différents, à des moments changeants. Par exemple sur les marchés.",
  },
  {
    NL: "Plots komen er grote bestellingen binnen, we hebben slechts een beperkte capaciteit en er staan mensen aan de truck te wachten.",
    EN: "Suddenly large orders are coming in, we only have a limited capacity and people are waiting at the truck.",
    FR: "Soudain, de grosses commandes arrivent, nous n'avons qu'une capacité limitée et les gens attendent au camion.",
  },
  {
    NL: "naar case",
    EN: "to case",
    FR: "vers le cas",
  },
  {
    NL: "De kleinhandel, u hebt nog geen eigen website",
    EN: "You are a small retailer, and don't have a website yet.",
    FR: "Commerce de détail, vous n'avez pas encore votre propre site web.",
  },
  {
    NL: "U merkt dat er minder binding is met de jongere generatie klanten.",
    EN: "You notice that there is less connection with the younger generation of customers.",
    FR: "Vous remarquez qu'il y a moins de connexion avec la jeune génération de clients.",
  },
  {
    NL: "Soms is het nodig de klanten te informeren bvb. door wegenwerken, of wegens ziekte of andere onderbrekingen.",
    EN: "Sometimes it is necessary to inform the customers eg. due to road works, or because of illness or other interruptions.",
    FR: "Parfois, il est nécessaire d'informer les clients, par exemple. en raison de travaux routiers, de maladie ou d'autres interruptions.",
  },
  {
    NL: "U kijkt er echter tegenop nog een nieuwe avontuur op te starten, er is al zoveel werk en administratie.",
    EN: "However, you fear to start a new adventure, there is already so much work and administration.",
    FR: "Cependant, vous craignez de vous lancer dans une nouvelle aventure, il y a déjà tellement de travail et d'administration.",
  },
  // {
  //   NL: "De kleinhandel. U hebt verschillende winkels.",
  //   EN: "Retail. You have several stores.",
  //   FR: "La vente au détail. Vous avez plusieurs magasins.",
  // },
  // {
  //   NL: "U bent een klein- of detailhandel, bijvoorbeeld bakker of slager, en hebt door de jaren heen, of zelfs door generaties heen hard gewerkt en enkele bijkomende vestigingen geopend.",
  //   EN: "You are a retail or retail trade, for example a baker or butcher, and have worked hard over the years, or even generations, and opened a few additional branches.",
  //   FR: "Vous êtes un commerce de détail, par exemple un boulanger ou un boucher, et avez travaillé dur au fil des années, voire des générations, et avez ouvert quelques succursales supplémentaires.",
  // },
  // {
  //   NL: "U wenst de digitale trein niet te missen, maar weet niet onmiddellijk hoe dit aan te pakken.",
  //   EN: "You don't want to miss the digital train, but you don't immediately know how to go about it.",
  //   FR: "Vous ne voulez pas rater le train digital, mais vous ne savez pas tout de suite comment vous y prendre.",
  // },
  {
    NL: "De traiteurzaak. Verse bereidingen, piekmomenten, weinig tijd.",
    EN: "The catering business. Fresh preparations, peak moments, little time.",
    FR: "Le métier de traiteur. Des préparations fraîches, des moments forts, peu de temps.",
  },
  {
    NL: "Of u nu een kleinhandel hebt, met een seizoensgebonden traiteurdienst, of een traiteur bent in hoofdberoep. Ongetwijfeld kent u de bijbehorende stress wanneer de leveringen naderbij komen en de klanten komen afhalen.",
    EN: "Whether you have a retail business, with a seasonal catering service, or are a caterer in your main occupation. No doubt you know the associated stress when deliveries approach and customers come to pick up.",
    FR: "Que vous ayez un commerce de détail, un service traiteur saisonnier ou que vous soyez traiteur dans votre activité principale. Vous connaissez sans doute le stress associé lorsque les livraisons approchent et que les clients viennent chercher.",
  },
  {
    NL: "Ubicopia® als klassieke webshop",
    EN: "Ubicopia® as a classic webshop",
    FR: "Ubicopia® comme boutique en ligne classique",
  },
  {
    NL: "Voor de online handelaar, ook als Micro-Onderneming, biedt Ubicopia® de klassieke mogelijkheden van een webshop zoals we deze allemaal kennen.",
    EN: "For the online merchant, also as a Micro-Enterprise, Ubicopia® offers the classic possibilities of a webshop as we all know them.",
    FR: "Pour le commerçant en ligne, également en tant que micro-entreprise, Ubicopia® offre les possibilités classiques d'une boutique en ligne telles que nous les connaissons tous.",
  },
  {
    NL: "Online betalen, verzending en tracking. Net zoals de klassieke webshop.",
    EN: "Online payment, shipping and tracking. Just like a classic webshop.",
    FR: "Paiement, expédition et suivi en ligne. Tout comme la boutique en ligne classique.",
  },
  {
    NL: "De frituur. Efficiëntie is van belang.",
    EN: "'De frituur' - efficiency counts",
    FR: "La friterie. L'efficacité compte.",
  },
  {
    NL: "Plots komen er grote bestellingen binnen, we hebben slechts een beperkte capaciteit en er staan mensen aan te schuiven.",
    EN: "Suddenly large orders are coming in, we only have a limited capacity and people are queuing up.",
    FR: "Soudain, de grosses commandes arrivent, nous n'avons qu'une capacité limitée et les gens font la queue.",
  },
  {
    NL: "Alles moet snel gaan. Laat het systeem voor jou werken.",
    EN: "Everything has to go fast. Let the system work for you.",
    FR: "Tout doit aller vite. Laissez le système travailler pour vous.",
  },
  {
    NL: "Connecteer met je klanten, met het ingebouwde marketing platform.",
    EN: "Connect with your customers, with built-in marketing platform.",
    FR: "Connectez-vous avec vos clients grâce au marketing intégré.",
  },
  {
    NL: "De slager",
    EN: "The butcher",
    FR: "Le boucher",
  },
  {
    NL: "U merkt dat er minder binding is met de jongere generatie klanten.",
    EN: "You notice that there is less connection with the younger generation of customers.",
    FR: "Vous remarquez qu'il y a moins de connexion avec la jeune génération de clients.",
  },
  {
    NL: "Soms is het nodig de klanten te informeren bvb. door wegenwerken, of wegens ziekte of andere onderbrekingen.",
    EN: "Sometimes it is necessary to inform the customers eg. due to road works, or because of illness or other interruptions.",
    FR: "Parfois, il est nécessaire d'informer les clients, par exemple. en raison de travaux routiers, de maladie ou d'autres interruptions.",
  },
  {
    NL: "De bakker",
    EN: "The Baker",
    FR: "Le boulanger",
  },

  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ Case Bakker  ----------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Webwinkel voor de bakker",
    EN: "Online shop for the baker",
    FR: "Boutique en ligne pour le boulanger",
  },
  {
    NL: "Ubicopia® biedt tal van voordelen voor bakkers die een webwinkel wensen op te starten.",
    EN: "Ubicopia® offers numerous advantages for bakers who wish to start an online store.",
    FR: "Ubicopia® offre de nombreux avantages aux boulangers qui souhaitent démarrer une boutique en ligne.",
  },
  {
    NL: "Klantenbinding",
    EN: "Customer loyalty",
    FR: "Fidélité du consommateur",
  },
  {
    NL: "Je connecteert met de moderne consument en organiseert je orderflow.",
    EN: "You connect with the modern consumer and organize your order flow.",
    FR: "Vous vous connectez avec le consommateur moderne et organisez votre flux de commandes.",
  },
  {
    NL: "Efficiëntie",
    EN: "Efficiency",
    FR: "Efficacité",
  },
  {
    NL: "Het systeem werkt voor jou en automatiseert vele van je taken.",
    EN: "The system works for you and automates many of your tasks.",
    FR: "Le système travaille pour vous et automatise bon nombre de vos tâches.",
  },
  {
    NL: "Omzetstijging",
    EN: "Sales increase",
    FR: "Augmentation des ventes",
  },
  {
    NL: "Je zet in op een extra verkoopkanaal. Je connecteert met een ander type consument.",
    EN: "You bet on an extra sales channel. You connect with a different type of consumer.",
    FR: "Vous misez sur un canal de vente supplémentaire. Vous vous connectez avec un autre type de consommateur.",
  },
  {
    NL: "Verhoging klantenbinding",
    EN: "Increase customer loyalty",
    FR: "Augmenter la fidélité des clients",
  },
  {
    NL: "Online marketing",
    EN: "Online marketing",
    FR: "Marketing en ligne",
  },
  {
    NL: "Vele zelfstandigen zijn reeds vertrouwd met het gebruik van facebook en hebben dikwijls 1000+ volgers.",
    EN: "Many self-employed people are already familiar with the use of facebook and often have 1000+ followers.",
    FR: "De nombreux indépendants sont déjà familiarisés avec l'utilisation de facebook et compte souvent plus de 1000 abonnés.",
  },
  {
    NL: "Nieuwe producten, uitzonderlijke sluitingen, speciale acties worden vie Facebook aan de klandizie meegedeeld. Dit is echter passieve communicatie.",
    EN: "New products, exceptional closures, special promotions are communicated to customers via Facebook. However, this is passive communication.",
    FR: "Nouveautés, fermetures exceptionnelles, promotions spéciales sont communiquées aux clients via Facebook. Cependant, il s'agit d'une communication passive.",
  },
  {
    NL: "Met het Ubicopia® marketing-mail pakket, standaard inclusief, kan je mailen naar je klantenbestand en zo actief je klandizie informeren over je initiatief of nieuws.",
    EN: "With the Ubicopia® marketing mail package, which is included as standard, you can send mail to your customer base and thus actively inform your customers about your initiative or news.",
    FR: "Avec le package de courrier marketing Ubicopia®, inclus en standard, vous pouvez envoyer du courrier à votre clientèle et ainsi informer activement vos clients de votre initiative ou de votre actualité.",
  },
  {
    NL: "Een duidelijke, snelle webwinkel",
    EN: "A clear, fast webshop",
    FR: "Une boutique en ligne claire et rapide",
  },
  {
    NL: "Simpel, snel en efficiënt zijn de sleutelwoorden. Klanten kunnen snel hun aankooplijst samenstellen, afhaalmoment kiezen en indien gewenst reeds online betalen.",
    EN: "Simple, fast and efficient are the keywords. Customers can quickly compile their purchase list, choose a pick-up time and, if desired, pay online.",
    FR: "Simple, rapide et efficace sont les maîtres mots. Les clients peuvent établir rapidement leur liste d'achats, choisir une heure de retrait et, s'ils le souhaitent, payer en ligne.",
  },
  {
    NL: "Verhoging efficiëntie",
    EN: "Increase efficiency",
    FR: "Accroître l'efficacité",
  },
  {
    NL: "Tijd is tegenwoordig een kostbaar goed geworden. Vele mensen leven in een rush, hebben een drukke job, en wensen daarnaast zich zoveel als mogelijk te ontspannen. Online aankopen maken tegenwoordig deel uit van het dagelijkse leven van de moderne consument.",
    EN: "Time has become a precious commodity these days. Many people live in a rush, have a busy job, and also wish to relax as much as possible. Online purchases are now part of the everyday life of the modern consumer.",
    FR: "Le temps est devenu une denrée précieuse de nos jours. Beaucoup de gens vivent pressés, ont un travail chargé et souhaitent également se détendre le plus possible. Les achats en ligne font désormais partie du quotidien du consommateur moderne.",
  },
  {
    NL: "Het systeem werkt voor jou en organiseert je orderflow.",
    EN: "The system works for you and organizes your order flow.",
    FR: "Le système travaille pour vous et organise votre flux de commandes.",
  },
  {
    NL: "Je kan zelf de hoeveelheid en duur van de tijdslots aangeven per dag van de week.",
    EN: "You can indicate the amount and duration of the time slots per day of the week.",
    FR: "Vous pouvez indiquer le nombre et la durée des plages horaires par jour de la semaine.",
  },
  {
    NL: "Je definieert productgroepen die gebonden zijn aan levertijden.",
    EN: "You define product groups that are bound by delivery times.",
    FR: "Vous définissez des groupes de produits liés par des délais de livraison.",
  },
  {
    NL: "Indien je bijvoorbeeld bij de feestdagen wil dat je klanten op tijd bestellen voor taart. Met Ubicopia® kan je dit instellen en wordt dit mee aangegeven bij het shoppen en kiezen van het levermoment.",
    EN: "For example, if you want your customers to order cake in time for the holidays. With Ubicopia® you can set this and this is also indicated when shopping and choosing the delivery time.",
    FR: "Par exemple, si vous souhaitez que vos clients commandent un gâteau à temps pour les fêtes. Avec Ubicopia®, vous pouvez définir cela et cela est également indiqué lors de l'achat et du choix du délai de livraison.",
  },
  {
    NL: "Het systeem bundelt de te maken bereidingen per leverdag of levermoment, zo verhoog je je efficiëntie bij de bereidingen.",
    EN: "The system bundles the preparations to be made per delivery day or delivery time, so you increase your efficiency with the preparations.",
    FR: "Le système regroupe les préparations à effectuer par jour ou heure de livraison, vous augmentez ainsi votre efficacité avec les préparations.",
  },
  {
    NL: "Stijging omzet",
    EN: "Increase in turnover",
    FR: "Chiffre d'affaires en hausse",
  },
  {
    NL: "Connecteren met de moderne consument",
    EN: "Connecting with the modern consumer",
    FR: "Se connecter avec le consommateur moderne",
  },
  {
    NL: "Tijdswinst is essentieel",
    EN: "Saving time is essential",
    FR: "Le gain de temps est essentiel",
  },
  {
    NL: "Dankzij de configureerbare interface biedt Ubicopia® een kant-en-klare oplossing voor de bakkerij.",
    EN: "Thanks to its configurable interface, Ubicopia® offers a turnkey solution for the bakery.",
    FR: "Grâce à son interface paramétrable, Ubicopia® propose une solution clé en main pour la boulangerie.",
  },
  {
    NL: "De moderne consument",
    EN: "The modern consumer",
    FR: "Le consommateur moderne",
  },
  {
    NL: "Het is geweten dat de moderne consument meer en meer online gaat shoppen. Met een eigen gepersonaliseerde webwinkel trek je de moderne consument aan en verhoog je je omzet.",
    EN: "It is known that the modern consumer is shopping more and more online. With your own personalized webshop you attract the modern consumer and increase your turnover.",
    FR: "On sait que le consommateur moderne achète de plus en plus en ligne. Avec votre propre boutique en ligne personnalisée, vous attirez le consommateur moderne et augmentez votre chiffre d'affaires.",
  },
  {
    NL: "Bovendien wordt via het pakket Ubicopia® microSite het verkoopkanaal en een eenvoudige website samen aangeboden.",
    EN: "In addition, the Ubicopia® microSite package offers the sales channel and a simple website together.",
    FR: "De plus, le pack Ubicopia® microSite offre à la fois le canal de vente et un simple site web.",
  },
  {
    NL: "Dankzij de configureerbare interface biedt Ubicopia® een  kant-en-klare oplossing voor de bakkerij.",
    EN: "Thanks to its configurable interface, Ubicopia® offers a turnkey solution for the bakery.",
    FR: "Grâce à son interface paramétrable, Ubicopia® propose une solution clé en main pour la boulangerie.",
  },
  {
    NL: "Ubicopia® voorziet de mogelijkheid marketing mails te versturen naar je klanten. Zo informeer je je volledige klantenbestand in één klap !",
    EN: "Ubicopia® provides the possibility to send marketing emails to your customers. This is how you inform your entire customer base in one fell swoop!",
    FR: "Ubicopia® offre la possibilité d'envoyer des e-mails marketing à vos clients. C'est ainsi que vous informez toute votre clientèle d'un seul coup !",
  },

  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ Case Slager  ----------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Webwinkel voor de slager",
    EN: "Online shop for the butcher",
    FR: "Boutique en ligne pour le boucher",
  },
  {
    NL: "Ubicopia® biedt tal van voordelen voor het verhogen van de rendabiliteit van je zaak.",
    EN: "Ubicopia® offers numerous benefits for increasing the profitability of your business.",
    FR: "Ubicopia® offre de nombreux avantages pour augmenter la rentabilité de votre entreprise.",
  },
  {
    NL: "Verhoging Klantenbinding.",
    EN: "Increase Customer Loyalty.",
    FR: "Augmentez la fidélité des clients.",
  },
  {
    NL: "Je communiceert beter met je klantenbestand.",
    EN: "You communicate better with your customer base.",
    FR: "Vous communiquez mieux avec votre clientèle.",
  },
  {
    NL: "Het systeem werkt voor jou en automatiseert vele van je taken.",
    EN: "The system works for you and automates many of your tasks.",
    FR: "Le système travaille pour vous et automatise bon nombre de vos tâches.",
  },
  {
    NL: "Je verbetert je orderverwerking.",
    EN: "You improve your order processing.",
    FR: "Vous améliorez le traitement de vos commandes.",
  },
  {
    NL: "Het bestellen door de klant wordt voor een groot deel geautomatiseerd.",
    EN: "Ordering by the customer is largely automated.",
    FR: "La commande par le client est largement automatisée.",
  },
  {
    NL: "Je zet in op een extra verkoopkanaal.",
    EN: "You bet on an extra sales channel.",
    FR: "Vous misez sur un canal de vente supplémentaire.",
  },
  {
    NL: "Je bereikt een nieuw type klant.",
    EN: "You reach a new type of customer.",
    FR: "Vous touchez un nouveau type de client.",
  },
  {
    NL: "De webwinkel verhoogt je klantenbinding",
    EN: "The webshop increases your customer loyalty",
    FR: "La boutique en ligne fidélise votre clientèle",
  },
  {
    NL: "Vele zelfstandigen zijn reeds vertrouwd met het gebruik van facebook en hebben dikwijls 1000+ volgers.",
    EN: "Many self-employed people are already familiar with the use of Facebook and often have 1000+ followers.",
    FR: "De nombreux indépendants sont déjà familiarisés avec l'utilisation de Facebook et ont souvent plus de 1000 abonnés.",
  },
  {
    NL: "Nieuwe producten, uitzonderlijke sluitingen, speciale acties worden vie Facebook aan de klandizie meegedeeld. Dit is echter passieve communicatie.",
    EN: "New products, exceptional closures, special promotions are communicated to customers via Facebook. However, this is passive communication.",
    FR: "Nouveautés, fermetures exceptionnelles, promotions spéciales sont communiquées aux clients via Facebook. Cependant, il s'agit d'une communication passive.",
  },
  {
    NL: "Met het Ubicopia® marketing-mail pakket, standaard inclusief, kan je mailen naar je klantenbestand en zo actief je klandizie informeren over je initiatief of nieuws.",
    EN: "With the Ubicopia® marketing mail package, which is included as standard, you can send mail to your customer base and thus actively inform your customers about your initiative or news.",
    FR: "Avec le package de courrier marketing Ubicopia®, inclus en standard, vous pouvez envoyer du courrier à votre clientèle et ainsi informer activement vos clients de votre initiative ou de votre actualité.",
  },
  {
    NL: "Online connecteren",
    EN: "Connect online",
    FR: "Connectez-vous en ligne",
  },
  {
    NL: "Ubicopia® geeft je ook de kans eventuele dringende mededelingen, bvb. wegenwerken, actief door te geven aan je klantenbestand om zo je bereikbaarheid te garanderen.",
    EN: "Ubicopia® also gives you the opportunity to send any urgent messages, e.g. road works, to actively pass on to your customer base in order to guarantee your accessibility.",
    FR: "Ubicopia® vous offre également la possibilité d'envoyer tout message urgent, par ex. travaux de voirie, à transmettre activement à votre clientèle afin de garantir votre accessibilité.",
  },
  {
    NL: "Je bekomt een verhoging van je efficiëntie",
    EN: "You get an increase in your efficiency",
    FR: "Vous gagnez en efficacité",
  },
  {
    NL: "Het systeem werkt voor jou en organiseert je orderflow.",
    EN: "The system works for you and organizes your order flow.",
    FR: "Le système travaille pour vous et organise votre flux de commandes.",
  },
  {
    NL: "Je kan zelf de hoeveelheid en duur van de tijdslots aangeven per dag van de week.",
    EN: "You can indicate the amount and duration of the time slots per day of the week.",
    FR: "Vous pouvez indiquer le nombre et la durée des plages horaires par jour de la semaine.",
  },
  {
    NL: "Je definieert productgroepen die gebonden zijn aan levertijden.",
    EN: "You define product groups that are bound by delivery times.",
    FR: "Vous définissez des groupes de produits liés par des délais de livraison.",
  },
  {
    NL: "Indien je bijvoorbeeld 2 levermomenten hebt voor wild, en dit dient 2 dagen op voorhand besteld te worden. Met Ubicopia® kan je dit instellen en wordt dit mee aangegeven bij het shoppen en kiezen van het levermoment.",
    EN: "For example, if you have 2 delivery times for game, and this must be ordered 2 days in advance. With Ubicopia® you can set this and this is also indicated when shopping and choosing the delivery time.",
    FR: "Par exemple, si vous avez 2 délais de livraison pour le jeu, et que celui-ci doit être commandé 2 jours à l'avance. Avec Ubicopia®, vous pouvez définir cela et cela est également indiqué lors de l'achat et du choix du délai de livraison.",
  },
  {
    NL: "Het systeem bundelt de bereidingen per leverdag of levermoment, zo verhoog je je efficiëntie bij de bereidingen.",
    EN: "The system bundles the preparations per delivery day or delivery time, so you increase your efficiency with the preparations.",
    FR: "Le système regroupe les préparations par jour de livraison ou heure de livraison, ce qui vous permet d'augmenter votre efficacité avec les préparations.",
  },
  {
    NL: "De webwinkel neemt een aantal taken over.",
    EN: "The webshop takes over a number of tasks.",
    FR: "La boutique en ligne prend en charge un certain nombre de tâches.",
  },
  {
    NL: "De winkelbediende wordt deels ontlast doordat er meer bestellingen online worden doorgegeven.",
    EN: "The shop assistant is partly relieved because more orders are placed online.",
    FR: "La vendeuse est en partie soulagée car davantage de commandes sont passées en ligne.",
  },
  {
    NL: "Verhoging van je omzet",
    EN: "Increasing your turnover",
    FR: "Augmenter votre chiffre d'affaires",
  },
  {
    NL: "De online slager",
    EN: "The online butcher",
    FR: "La boucherie en ligne",
  },
  {
    NL: "Je opent een nieuwe verkoopkanaal. Het is geweten dat de moderne consument meer en meer online gaat shoppen. Met een eigen webwinkel trek je de moderne consument aan, en verhoog je je omzet.",
    EN: "You open a new sales channel. It is known that the modern consumer is shopping more and more online. With your own webshop you attract the modern consumer and increase your turnover.",
    FR: "Vous ouvrez un nouveau canal de vente. On sait que le consommateur moderne achète de plus en plus en ligne. Avec votre propre boutique en ligne, vous attirez le consommateur moderne et augmentez votre chiffre d'affaires.",
  },
  {
    NL: "Een ruimer aanbod",
    EN: "A wider range",
    FR: "Une gamme plus large",
  },
  {
    NL: "In de winkel koopt de klant enkel wat er in de toonbank ligt. Op de website staan echter al je beschikbare producten en krijgt de klant je volledige aanbod te zien.",
    EN: "In the store, the customer only buys what is on the counter. However, the website lists all your available products and the customer gets to see your full range.",
    FR: "Dans le magasin, le client n'achète que ce qui est sur le comptoir. Cependant, le site Web répertorie tous vos produits disponibles et le client peut voir votre gamme complète.",
  },
  {
    NL: "De webwinkel geeft je ook de kans producten te verkopen vooraleer je deze aankoopt. Zo kan je je aanbod uitbreiden met een assortiment dat bvb. enkel op bestelling te verkrijgen is.",
    EN: "The webshop also gives you the chance to sell products before you buy them. This way you can expand your offer with an assortment that includes e.g. is only available on order.",
    FR: "La boutique en ligne vous donne également la possibilité de vendre des produits avant de les acheter. De cette façon, vous pouvez élargir votre offre avec un assortiment qui comprend par ex. n'est disponible que sur commande.",
  },
  {
    NL: "Dankzij de configureerbare interface biedt Ubicopia® een kant-en-klare oplossing voor de kleinhandel.",
    EN: "Thanks to its configurable interface, Ubicopia® offers a turnkey solution for retail.",
    FR: "Grâce à son interface paramétrable, Ubicopia® propose une solution clé en main pour le retail.",
  },
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ Case Frituur  ----------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Een groot aantal bestellingen op korte tijd vraag een goede organisatie.",
    EN: "A large number of orders in a short period of time requires good organisation.",
    FR: "Un grand nombre de commandes en peu de temps nécessite une bonne organisation.",
  },
  {
    NL: "Ubicopia® neemt een aantal taken van je over.",
    EN: "Ubicopia® takes over a number of tasks from you.",
    FR: "Ubicopia® prend en charge un certain nombre de tâches.",
  },
  {
    NL: "Ubicopia® verhoogt niet enkel je online aanwezigheid, maar verhoogt ook je efficientie door de beschikbare tijd nuttiger te besteden.",
    EN: "Ubicopia® not only increases your online presence, but also increases your efficiency by spending the available time more usefully.",
    FR: "Ubicopia® augmente non seulement votre présence en ligne, mais augmente également votre efficacité en utilisant le temps disponible de manière plus utile.",
  },
  {
    NL: "Je verhoogt tegelijk de service naar je klanten door wachttijden te vermijden, en tegelijk steeds een verse bereiding aan te bieden. Hierdoor verhoog je je klantenbinding.",
    EN: "At the same time you increase the service to your customers by avoiding waiting times, and at the same time always offering a fresh preparation. This increases your customer loyalty.",
    FR: "En même temps, vous augmentez le service à vos clients en évitant les temps d'attente, et en même temps en offrant toujours une préparation fraîche. Cela augmente la fidélité de votre clientèle.",
  },
  {
    NL: "Tijd is de essentie.",
    EN: "Time is the essence.",
    FR: "Le temps est l'essence.",
  },
  {
    NL: "Je klanten bestellen online, je wordt minder gestoord tijdens je werk.",
    EN: "Your customers order online, you are less disturbed during your work.",
    FR: "Vos clients commandent en ligne, vous êtes moins dérangé pendant votre travail.",
  },
  {
    NL: "Met de getimede preparatie worden je klanten geïnformeerd en werk je gestructureerd.",
    EN: "With the timed preparation, your customers are informed and you work in a structured way.",
    FR: "Avec la préparation chronométrée, vos clients sont informés et vous travaillez de manière structurée.",
  },
  {
    NL: "Met een duidelijk overzicht weet je perfect wat er moet gebeuren.",
    EN: "With a clear overview you know exactly what needs to be done.",
    FR: "Avec une vue d'ensemble claire, vous savez exactement ce qui doit être fait.",
  },
  {
    NL: "Je kan je werk wat verdelen door de tijdslots in te stellen en het maximum aantal bestellingen per slot op te geven.",
    EN: "You can divide your work by setting the time slots and specifying the maximum number of orders per slot.",
    FR: "Vous pouvez diviser votre travail en fixant les créneaux horaires et en spécifiant le nombre maximum de commandes par créneau.",
  },
  {
    NL: "Boekhoudkundige hulp",
    EN: "Accounting help",
    FR: "Aide comptable",
  },
  {
    NL: "Ubicopia® bereidt je overzicht van je daginkomsten voor op een overzichtelijke en wettelijk correct manier.",
    EN: "Ubicopia® prepares your overview of your daily income in a clear and legally correct manner.",
    FR: "Ubicopia® prépare votre aperçu de vos revenus quotidiens de manière claire et juridiquement correcte.",
  },
  {
    NL: "Klantenbinding stijgt",
    EN: "Customer loyalty is increasing",
    FR: "La fidélité des clients augmente",
  },
  {
    NL: "Vermijden van wachttijden",
    EN: "Avoiding waiting times",
    FR: "Éviter les temps d'attente",
  },
  {
    NL: "De keuken heeft slechts een beperkte capaciteit, komt er dan onverwacht een grote bestelling, dan starten de wachttijden.",
    EN: "The kitchen only has a limited capacity, if a large order arrives unexpectedly, the waiting times start.",
    FR: "La cuisine n'a qu'une capacité limitée, si une commande importante arrive à l'improviste, les temps d'attente commencent.",
  },
  {
    NL: "Je kan zelf aangeven hoeveel werk je per online tijdslot kan aannemen.",
    EN: "You can indicate how much work you can take on per online time slot.",
    FR: "Vous pouvez indiquer la quantité de travail que vous pouvez effectuer par créneau horaire en ligne.",
  },
  {
    NL: "Je kan instellen hoeveel tijd op voorhand je nog bestellingen accepteert, dit kan gaan tot enkele dagen, uren of zelfs minuten.",
    EN: "You can set how much time in advance you still accept orders, this can go up to a few days, hours or even minutes.",
    FR: "Vous pouvez définir combien de temps à l'avance vous acceptez encore les commandes, cela peut aller jusqu'à quelques jours, heures ou même minutes.",
  },
  {
    NL: "Doordat de klant op voorhand betaalt, wordt er snel opgehaald.",
    EN: "Because the customer pays in advance, collection is quick.",
    FR: "Parce que le client paie à l'avance, l'encaissement est rapide.",
  },
  {
    NL: "De klant weet ongeveer wanneer hij zijn bestelling kan verwachten en zal op tijd worden geïnformeerd wanneer exact deze kan worden opgehaald. Dit gebeurt via sms of email.",
    EN: "The customer knows approximately when he can expect his order and will be informed in time when exactly it can be picked up. This is done via SMS or email.",
    FR: "Le client sait approximativement quand il peut attendre sa commande et sera informé à temps du moment exact où il pourra être récupéré. Cela se fait par SMS ou par e-mail.",
  },
  {
    NL: "Altijd vers",
    EN: "Always fresh",
    FR: "Toujours frais",
  },
  {
    NL: "Je klant wordt op tijd geïnformeerd met een instelbare timer wanneer zijn bestelling vers kan worden afgehaald. Dit kan per email of sms.",
    EN: "Your customer will be informed in time with an adjustable timer when his order can be picked up fresh. This can be done by email or SMS.",
    FR: "Votre client sera informé à temps grâce à une minuterie réglable lorsque sa commande pourra être récupérée fraîche. Cela peut se faire par e-mail ou SMS.",
  },
  {
    NL: "Online aanwezigheid",
    EN: "Online presence",
    FR: "Présence en ligne",
  },
  {
    NL: "Met de ingebouwde marketing-mailer informeer je je klanten pro-actief over nieuwe etenswaren, je dag- of weekschotel, uitzonderlijke situaties, enz.",
    EN: "With the built-in marketing mailer you proactively inform your customers about new foods, your daily or weekly special, exceptional situations, etc.",
    FR: "Avec le mailer marketing intégré, vous informez de manière proactive vos clients sur les nouveaux aliments, votre plat du jour ou de la semaine, les situations exceptionnelles, etc.",
  },
  {
    NL: "Ubicopia® kan worden geïnstalleerd met of zonder website naar je eigen wens.",
    EN: "Ubicopia® can be installed with or without a website according to your own wishes.",
    FR: "Ubicopia® peut être installé avec ou sans site internet selon vos envies.",
  },
  {
    NL: "De Ubicopia® Microshop is al een website op zich en kan zo worden gebruikt met een eigen email, hosting en webdomeinnaam - dit is dan je eigen website met wat basisinformatie waarop je klanten hun bestellingen kunnen plaatsen.",
    EN: "The Ubicopia® Microshop is already a website in itself and can be used with its own email, hosting and web domain name - this is your own website with some basic information on which your customers can place their orders.",
    FR: "Ubicopia® Microshop est déjà un site Web en soi et peut être utilisé avec son propre e-mail, hébergement et nom de domaine Web - c'est votre propre site Web avec quelques informations de base sur lesquelles vos clients peuvent passer leurs commandes.",
  },
  {
    NL: "Heb je reeds een website, dan leveren we aan jou of je webmaster een stukje code, onder de vorm van een javascript snippet of iframe, in een mum van tijd start je met je online verkoop.",
    EN: "If you already have a website, we will provide you or your webmaster with a piece of code in the form of a javascript snippet or iframe, so you can start your online sales in no time.",
    FR: "Si vous avez déjà un site Web, nous vous fournirons, à vous ou à votre webmaster, un morceau de code sous la forme d'un extrait de code javascript ou d'une iframe, afin que vous puissiez démarrer vos ventes en ligne en un rien de temps.",
  },
  {
    NL: "Omzet stijgt",
    EN: "Turnover is rising",
    FR: "Le chiffre d'affaires est en hausse",
  },
  {
    NL: "Je connecteert met een nieuwe type klant en opent een extra verkoopskanaal.",
    EN: "You connect with a new type of customer and open an extra sales channel.",
    FR: "Vous vous connectez avec un nouveau type de client et ouvrez un canal de vente supplémentaire.",
  },
  {
    NL: "Je kan bepalen hoeveel uren, of zelfs minuten er op voorhand moet besteld worden.",
    EN: "You can determine how many hours, or even minutes, to order in advance.",
    FR: "Vous pouvez déterminer combien d'heures, voire de minutes, commander à l'avance.",
  },

  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ Case De Foodtruck  ----------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------

  {
    NL: "Webwinkel voor foodtruck met verse bereidingen",
    EN: "Web shop for food truck with fresh preparations",
    FR: "Boutique en ligne pour food truck avec préparations fraîches",
  },
  {
    NL: "Ubicopia® verhoogt niet enkel je online aanwezigheid, maar verhoogt ook je efficientie door de beschikbare tijd nuttiger te besteden.",
    EN: "Ubicopia® not only increases your online presence, but also increases your efficiency by spending the available time more usefully.",
    FR: "Ubicopia® augmente non seulement votre présence en ligne, mais augmente également votre efficacité en utilisant le temps disponible de manière plus utile.",
  },
  {
    NL: "Klantenbinding",
    EN: "Customer loyalty",
    FR: "Fidélité du consommateur",
  },
  {
    NL: "Je verhoogt tegelijk de service naar je klanten door wachttijden te vermijden.",
    EN: "At the same time you increase the service to your customers by avoiding waiting times.",
    FR: "En même temps vous augmentez le service à vos clients en évitant les temps d'attente.",
  },
  {
    NL: "Je kan een verse bereiding garanderen bij het ophalen van de bestelling.",
    EN: "You can guarantee fresh preparation when you pick up the order.",
    FR: "Vous pouvez garantir une préparation fraîche lorsque vous récupérez la commande.",
  },
  {
    NL: "Je omzet stijgt",
    EN: "Your turnover increases",
    FR: "Votre chiffre d'affaires augmente",
  },
  {
    NL: "Je opent immers een extra verkoopkanaal.",
    EN: "After all, you open an extra sales channel.",
    FR: "Après tout, vous ouvrez un canal de vente supplémentaire.",
  },
  {
    NL: "Je bereikt een ander type klant.",
    EN: "You reach a different type of customer.",
    FR: "Vous touchez un autre type de client.",
  },

  {
    NL: "Verhoging van klantenbinding",
    EN: "Increasing customer loyalty",
    FR: "Accroître la fidélité des clients",
  },
  {
    NL: "Reduceer wachttijden.",
    EN: "Reduce waiting times.",
    FR: "Réduire les temps d'attente.",
  },
  {
    NL: "De keuken heeft slechts een beperkte capaciteit. Komt er dan onverwacht een grote bestelling, dan starten de wachttijden. Je kan zelf aangeven hoeveel werk je per tijdseenheid kan aannemen.",
    EN: "The kitchen has only limited capacity. If a large order arrives unexpectedly, the waiting times will start. You can indicate yourself how much work you can take on per time unit.",
    FR: "La cuisine n'a qu'une capacité limitée. Si une commande importante arrive à l'improviste, les délais d'attente commenceront. Vous pouvez indiquer vous-même la quantité de travail que vous pouvez effectuer par unité de temps.",
  },
  {
    NL: "Je kan instellen hoeveel tijd op voorhand je nog bestellingen accepteert, dit kan gaan tot enkele dagen, uren of minuten.",
    EN: "You can set how much time in advance you still accept orders, this can go up to a few days, hours or minutes.",
    FR: "Vous pouvez définir combien de temps à l'avance vous acceptez encore les commandes, cela peut aller jusqu'à quelques jours, heures ou minutes.",
  },
  {
    NL: "Met het betaalssyteem kan je ervoor kiezen om je klanten online te laten betalen.",
    EN: "With the payment system you can choose to have your customers pay online.",
    FR: "Avec le système de paiement, vous pouvez choisir de faire payer vos clients en ligne.",
  },
  {
    NL: "Klantentevredenheid",
    EN: "Customer satisfaction",
    FR: "Satisfaction du client",
  },
  {
    NL: "Je klant wordt op tijd geïnformeerd met een instelbare timer wanneer zijn bestelling vers kan worden afgehaald.",
    EN: "Your customer will be informed in time with an adjustable timer when his order can be picked up fresh.",
    FR: "Votre client sera informé à temps grâce à une minuterie réglable lorsque sa commande pourra être récupérée fraîche.",
  },

  {
    NL: "Met de ingebouwde marketing-mailer informeer je je klanten pro-actief over nieuwe etenswaren, je dag- of weekschotel, uitzonderlijke situaties, enz.",
    EN: "With the built-in marketing mailer you proactively inform your customers about new foods, your daily or weekly special, exceptional situations, etc.",
    FR: "Avec le mailer marketing intégré, vous informez de manière proactive vos clients sur les nouveaux aliments, votre plat du jour ou de la semaine, les situations exceptionnelles, etc.",
  },
  {
    NL: "Je eigen webshop met je website inclusief",
    EN: "Your own webshop with your website included",
    FR: "Votre propre boutique en ligne avec votre site Web inclus",
  },
  {
    NL: "Ubicopia® kan worden geïnstalleerd met of zonder website naar je eigen wens.",
    EN: "Ubicopia® can be installed with or without a website according to your own wishes.",
    FR: "Ubicopia® peut être installé avec ou sans site internet selon vos envies.",
  },
  {
    NL: "De Ubicopia® Microshop is al een website op zich en kan zo worden gebruikt met een eigen email, hosting en webdomeinnaam - dit is dan je eigen website met wat basisinformatie waarop je klanten hun bestellingen kunnen plaatsen.",
    EN: "The Ubicopia® Microshop is already a website in itself and can be used with its own email, hosting and web domain name - this is your own website with some basic information on which your customers can place their orders.",
    FR: "Ubicopia® Microshop est déjà un site Web en soi et peut être utilisé avec son propre e-mail, hébergement et nom de domaine Web - c'est votre propre site Web avec quelques informations de base sur lesquelles vos clients peuvent passer leurs commandes.",
  },
  {
    NL: "Heb je reeds een website, dan leveren we aan jou of je webmaster een stukje code, onder de vorm van een javascript snippet of iframe, in een mum van tijd start je met je online verkoop op je reeds bestaande website.",
    EN: "If you already have a website, we will provide you or your webmaster with a piece of code in the form of a javascript snippet or iframe, so that you can start selling online on your existing website in no time.",
    FR: "Si vous avez déjà un site Web, nous vous fournirons, à vous ou à votre webmaster, un morceau de code sous la forme d'un extrait de code javascript ou d'une iframe, afin que vous puissiez commencer à vendre en ligne sur votre site Web existant en un rien de temps.",
  },
  {
    NL: "Verhoging van efficiëntie",
    EN: "Increasing efficiency",
    FR: "Accroître l'efficacité",
  },
  {
    NL: "Met de getimede preparatie worden je klanten geïnformeerd en werk je gestructureerd.",
    EN: "With the timed preparation, your customers are informed and you work in a structured way.",
    FR: "Avec la préparation chronométrée, vos clients sont informés et vous travaillez de manière structurée.",
  },

  {
    NL: "Met een duidelijk overzicht weet je perfect wat er moet gebeuren.",
    EN: "With a clear overview you know exactly what needs to be done.",
    FR: "Avec une vue d'ensemble claire, vous savez exactement ce qui doit être fait.",
  },
  {
    NL: "Je kan je werk wat verdelen door de tijdslots in te stellen en het maximum aantal bestellingen per slot op te geven.",
    EN: "You can divide your work by setting the time slots and specifying the maximum number of orders per slot.",
    FR: "Vous pouvez diviser votre travail en fixant les créneaux horaires et en spécifiant le nombre maximum de commandes par créneau.",
  },
  {
    NL: "Boekhoudkundige hulp",
    EN: "Accounting help",
    FR: "Aide comptable",
  },
  {
    NL: "Ubicopia® bereidt je overzicht van je daginkomsten voor op een overzichtelijke en wettelijk correct manier.",
    EN: "Ubicopia® prepares your overview of your daily income in a clear and legally correct manner.",
    FR: "Ubicopia® prépare votre aperçu de vos revenus quotidiens de manière claire et juridiquement correcte.",
  },
  {
    NL: "Ubicopia® biedt een kant-en-klare oplossing als webwinkel voor een foodtruck.",
    EN: "Ubicopia® offers a turnkey solution as a webshop for a food truck.",
    FR: "Ubicopia® propose une solution clé en main en tant que boutique en ligne pour un food truck.",
  },
  {
    NL: "Sta je op verschillende locaties, met Ubicopia® kan je deze ingeven.",
    EN: "If you are at different locations, you can enter these with Ubicopia®.",
    FR: "Si vous vous trouvez à différents endroits, vous pouvez les saisir avec Ubicopia®.",
  },
  {
    NL: "Met de ingebouwde marketing-mailtool, worden de klanten persoonlijk geïnformeerd, wanneer er nieuwe producten in promotie, zijn, of wanneer er bvb een omleiding is.",
    EN: "With the built-in marketing email tool, customers are personally informed when there are new products in promotion, or when there is a diversion, for example.",
    FR: "Grâce à l'outil de messagerie marketing intégré, les clients sont personnellement informés lorsqu'il y a de nouveaux produits en promotion ou lorsqu'il y a un détournement, par exemple.",
  },
  {
    NL: "Er komen dikwijls onverwachts grote bestellingen binnen per telefoon voor een gans gezin, of soms zelfs een klein feestje. Wanneer dit dan niet gepland of afgesproken is, en er staan mensen te wachten op hun eten aan de foodtruck, dan leidt dit soms tot frustraties.",
    EN: "Large orders often come in unexpectedly by telephone for a whole family, or sometimes even a small party. When this is not planned or ordered upfront, and people are waiting for their food at the food truck, this sometimes leads to frustrations.",
    FR: "Les grosses commandes arrivent souvent de manière inattendue par téléphone pour toute une famille, ou parfois même pour une petite fête. Lorsque cela n'est pas planifié ou commandé à l'avance et que les gens attendent leur nourriture au food truck, cela entraîne parfois des frustrations.",
  },
  {
    NL: "De keuken heeft slechts een beperkte capaciteit, en dan starten er plots wachttijden.",
    EN: "The kitchen only has a limited capacity, and then suddenly waiting times start.",
    FR: "La cuisine n'a qu'une capacité limitée, et puis soudain, les temps d'attente commencent.",
  },
  {
    NL: "Ubicopia® biedt hiervoor een kant-en-klare oplossing.",
    EN: "Ubicopia® offers a ready-made solution for this.",
    FR: "Ubicopia® propose une solution toute faite pour cela.",
  },
  {
    NL: "Vrij te kiezen tijdslots voor de afhalingen.",
    EN: "Freely selectable time slots for collections.",
    FR: "Créneau horaires librement sélectionnables pour les collectes.",
  },
  {
    NL: "Maximum aantal bestellingen per timeslot.",
    EN: "Maximum number of orders per time slot.",
    FR: "Nombre maximum de commandes par créneau horaire.",
  },
  {
    NL: "Je kan bepalen hoeveel uren er op voorhand er moet besteld worden.",
    EN: "You can even determine how many hours in advance to order.",
    FR: "Vous pouvez même déterminer combien d'heures à l'avance pour commander.",
  },
  {
    NL: "De klant wordt bij de start van de bereiding automatisch verwittigd door het systeem. Zo weet de klant perfect wanneer hij moet komen afhalen, om een verse bereiding mee te nemen.",
    EN: "The customer is automatically notified by the system at the start of the preparation. This way the customer knows exactly when to pick up, to take a fresh preparation with him.",
    FR: "Le client est notifié automatiquement par le système au début de la préparation. De cette façon, le client sait exactement quand arriver, pour emporter une préparation fraîche avec lui.",
  },
  {
    NL: "Boekhoudkundig wordt alles in de achtergrond bijgehouden, en kan er per dag een BTW-overzicht worden gemaakt van de daginkomsten.",
    EN: "Accounting Everything is kept in the background, and a VAT overview can be made per day of the daily income.",
    FR: "Comptabilité Tout est gardé en backend, et un aperçu de la TVA peut être fait par jour du revenu quotidien.",
  },
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ Case De kleinhandel ---------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Webwinkel voor de kleinhandel",
    EN: "Online retail store",
    FR: "Magasin de détail en ligne",
  },
  {
    NL: "Ubicopia® verhoogt je klantenbinding door actief met je klanten te communiceren.",
    EN: "Ubicopia® increases your customer loyalty by actively communicating with your customers.",
    FR: "Ubicopia® fidélise votre clientèle en communiquant activement avec vos clients.",
  },
  {
    NL: "Door je online aanwezigheid connecteer je met de moderne consument.",
    EN: "Through your online presence you connect with the modern consumer.",
    FR: "Grâce à votre présence en ligne, vous vous connectez avec le consommateur moderne.",
  },
  {
    NL: "Door een overzichtelijke orderverwerking verhoogt je efficiëntie.",
    EN: "Clear order processing increases your efficiency.",
    FR: "Un traitement clair des commandes augmente votre efficacité.",
  },
  {
    NL: "De klant plaatst op voorhand zijn bestelling, ook al sta je op verschillende plaatsen.",
    EN: "The customer places his order in advance, even if you are in different places.",
    FR: "Le client passe sa commande à l'avance, même si vous êtes à des endroits différents.",
  },
  {
    NL: "Met een webwinkel verhoog je je klantenbinding en efficiëntie.",
    EN: "With a webshop you increase your customer loyalty and efficiency.",
    FR: "Avec une boutique en ligne, vous augmentez la fidélité de vos clients et votre efficacité.",
  },
  {
    NL: "Online shoppen",
    EN: "Online shopping",
    FR: "Shopping en ligne",
  },
  {
    NL: "Het is geweten dat de moderne consument meer en meer online gaat shoppen. Met een eigen webwinkel trek je de moderne consument aan, en verhoog je je omzet.",
    EN: "It is known that the modern consumer is shopping more and more online. With your own webshop you attract the modern consumer and increase your turnover.",
    FR: "On sait que le consommateur moderne achète de plus en plus en ligne. Avec votre propre boutique en ligne, vous attirez le consommateur moderne et augmentez votre chiffre d'affaires.",
  },
  {
    NL: "Het systeem werkt voor jou, niet omgekeerd.",
    EN: "The system works for you, not the other way around.",
    FR: "Le système fonctionne pour vous, et non l'inverse.",
  },
  {
    NL: "Initieel zal je je winkel wel moeten opzetten, foto's uploaden en prijzen ingeven. Reeds bij de eerste bestellingen zal je merken dat je deze geïnvesteerde tijd al snel terugwint doordat het systeem zichzelf organiseert.",
    EN: "Initially you will have to set up your store, upload photos and enter prices. Already with the first orders you will notice that you quickly recoup this invested time because the system organizes itself.",
    FR: "Au départ, vous devrez configurer votre boutique, télécharger des photos et saisir les prix. Dès les premières commandes, vous remarquerez que vous récupérez rapidement ce temps investi car le système s'organise tout seul.",
  },
  {
    NL: "Flexibiliteit",
    EN: "Flexibility",
    FR: "La flexibilité",
  },
  {
    NL: "Ook al sta je bvb als marktkramer op verschillende locaties, de klant kan steeds de locatie en het tijdslot kiezen dat hem uitkomt.",
    EN: "Even if, for example, you are at different locations as a market vendor, the customer can always choose the location and time slot that suits him.",
    FR: "Même si, par exemple, vous êtes à différents endroits en tant que vendeur du marché, le client peut toujours choisir le lieu et le créneau horaire qui lui conviennent.",
  },
  {
    NL: "Dankzij de configureerbare interface biedt Ubicopia® biedt een kant-en-klare oplossing voor de kleinhandel.",
    EN: "Thanks to its configurable interface, Ubicopia® offers a turnkey solution for retail.",
    FR: "Grâce à son interface paramétrable, Ubicopia® propose une solution clé en main pour le retail.",
  },
  {
    NL: "Bovendien wordt via het pakket Ubicopia® microSite het verkoopkanaal en een eenvoudige landingpage samen aangeboden.",
    EN: "In addition, via the Ubicopia® microSite package, the digital sales channel and a simple landing page are offered in combination.",
    FR: "De plus, via le pack Ubicopia® microSite, le canal de vente numérique et une simple landing page sont proposés en combinaison.",
  },
  {
    NL: "Ga naar voorbeeld",
    EN: "Go to example",
    FR: "Vers l'exemple",
  },
  {
    NL: "Door de partnering met Worldline kan de klant op voorhand betalen, zo verliezen we geen tijd meer bij het afhaalmoment.",
    EN: "Through our partnering with Worldline, the customer can pay in advance, so we no longer lose time at the pick-up time.",
    FR: "En s'associant à Worldline, le client peut payer à l'avance, nous ne perdons donc plus de temps au moment de la prise en charge.",
  },
  {
    NL: "De afhaalmomenten kunnen vrij worden bepaald, en kan je verhogen naar het weekend toe, wanneer het drukker wordt. Zo kan je je personeel ontlasten en piekmomenten vermijden. Het werk kan gelijkmatiger worden verdeeld.",
    EN: "The pick-up times can be freely determined, and you can increase them towards the weekend when it gets busier. This way you can relieve your staff and avoid peak times. The work can be distributed more evenly.",
    FR: "Les heures de prise en charge peuvent être librement déterminées, et vous pouvez les augmenter vers le week-end quand il y a plus de monde. De cette façon, vous pouvez soulager votre personnel et éviter les heures de pointe. Le travail peut être réparti plus uniformément.",
  },
  {
    NL: "Bovendien kan je het maximum aantal bestellingen per timeslot ingeven, en een levertijd, in uren of dagen, hanteren, zodat je ruim de tijd hebt om voor voldoende ingrediënten te zorgen.",
    EN: "You can also enter the maximum number of orders per time slot, and set a delivery time in hours or days, so that you have plenty of time to ensure that you have enough ingredients.",
    FR: "Vous pouvez également saisir le nombre maximum de commandes par créneau horaire, et définir un délai de livraison en heures ou en jours, afin d'avoir tout le temps nécessaire pour vous assurer d'avoir suffisamment d'ingrédients.",
  },
  {
    NL: "Via gepersonaliseerde emails wordt de klant op de hoogte gehouden van de voortgang van zijn bestelling.",
    EN: "The customer is kept informed of the progress of his order via personalized emails.",
    FR: "Le client est tenu informé de l'évolution de sa commande via des emails personnalisés.",
  },
  {
    NL: "Wat met het persoonlijke contact - bij het verwerken zie je bij elke bestelling de naam en contactgegevens, door hierop te klikken bel je automatisch (Google Android), of start je een email op. Zo blijf je steeds in nauw contact met de klant, en kan je hem/haar snel contacteren wanneer er iets niet duidelijk is.",
    EN: "What about the personal contact - when processing you see the name and contact details with every order, by clicking on this you automatically call (Google Android), or you start an email. This way you always stay in close contact with the customer, and you can contact him/her easily if something is not clear.",
    FR: "Qu'en est-il du contact personnel - lors du traitement, vous voyez le nom et les coordonnées de chaque commande, en cliquant dessus, vous appelez automatiquement (Google Android) ou vous démarrez un e-mail. De cette façon, vous restez toujours en contact étroit avec le client et vous pouvez le contacter facilement si quelque chose n'est pas clair.",
  },
  {
    NL: "Ubicopia® voorziet de mogelijkheid HTML mails te versturen naar je klanten. Zo informeer je je volledige klantenbestand in één klap !",
    EN: "Ubicopia® provides the possibility to send HTML mails to your customers. This is how you inform your entire customer base in one swoop!",
    FR: "Ubicopia® offre la possibilité d'envoyer des mails HTML à vos clients. C'est ainsi que vous informez toute votre clientèle d'un seul coup !",
  },
  {
    NL: "Door de makkelijke overzichten voor de boekhouding en de orderverwerking win je al snel veel tijd en kan je je focussen op je zaak en je leven, ipv administratie.",
    EN: "Thanks to the easy overviews for accounting and order processing, you quickly save a lot of time and you can focus on your business and your life, instead of administration.",
    FR: "Grâce aux aperçus simples de la comptabilité et du traitement des commandes, vous gagnez rapidement beaucoup de temps et vous pouvez vous concentrer sur votre entreprise et votre vie, plutôt que sur l'administration.",
  },
  {
    NL: "Case: De kleinhandel. U hebt verschillende winkels.",
    EN: "Case : Retail. You have several stores.",
    FR: "Cas : La vente au détail. Vous avez plusieurs magasins.",
  },
  {
    NL: "U hebt verschillende vestigingen en vermoedt dat het samenstellen van een online dienstverlening naast de bestaande een hoge investering is.",
    EN: "You have several shops and suspect that putting together an online service in addition to the existing channels is a high investment.",
    FR: "Vous avez plusieurs magasins et pensez que mettre en place un service en ligne en plus des canaux existants est un investissement important.",
  },
  {
    NL: "Ook kijkt u op tegen de extra complexiteit die deze orderverwerking met zich meebrengt.",
    EN: "You also worry about the extra complexity that this order processing entails.",
    FR: "Vous vous inquiétez également de la complexité supplémentaire qu'implique ce traitement de commande.",
  },
  {
    NL: "U denkt dat dit veel tijd in beslag neemt.",
    EN: "You think this takes a lot of time.",
    FR: "Vous pensez que cela prend beaucoup de temps.",
  },
  {
    NL: "Nieuwe technologiën laten een onmiddellijke opstart toe.",
    EN: "New technologies allow an immediate start-up.",
    FR: "Les nouvelles technologies permettent un démarrage immédiat.",
  },
  {
    NL: "Dankzij de nieuwe technologieën, die een decentralisatie van de digitale dienstverlening mogelijk maakt, was het nooit makkelijker een online dienstverlening op te starten.",
    EN: "Thanks to the new technologies, which enable decentralization of digital services, it has never been easier to start an online service.",
    FR: "Grâce aux nouvelles technologies, qui permettent la décentralisation des services digitales, il n'a jamais été aussi facile de démarrer un service en ligne.",
  },
  {
    NL: "Doordat de structuur van de dienstverlening reeds bestaat, en niet telkens opnieuw moet worden uitgevonden of opgebouwd, kan u vrijwel onmiddellijk aan de slag.",
    EN: "Because the structure of the service already exists, and does not have to be invented or built up again and again, you can get started almost immediately.",
    FR: "Parce que la structure du service existe déjà et qu'il n'est pas nécessaire de l'inventer ou de la recréer encore et encore, vous pouvez commencer presque immédiatement.",
  },
  {
    NL: "U kan met Ubicopia® onmiddellijk aan de slag.",
    EN: "You can get started immediately.",
    FR: "vous pouvez commencer immédiatement.",
  },
  {
    NL: "Doordat de structuur reeds bestaat in de backend, en moduleerbaar te activeren is, kan u met Ubicopia® onmiddellijk aan de slag.",
    EN: "Because the structure already exists in the backend and can be activated in a modular way, with Ubicopia®, you get started  immediately.",
    FR: "Parce que la structure existe déjà dans le backend et peut être activée de manière modulaire, avec Ubicopia®, vous vous lancez immédiatement.",
  },
  {
    NL: "Of u nu al een website hebt of nog niet, de microShop oplossingen van Ubicopia® kunnen u steeds verderhelpen.",
    EN: "Whether you already have a website or not yet, the microShop solutions from Ubicopia® can always help you.",
    FR: "Que vous ayez déjà un site web ou pas encore, les solutions microShop d'Ubicopia® peuvent toujours vous aider.",
  },
  {
    NL: "In al uw vestigingen kan u, per vestiging, de ophaalmomenten vrij bepalen.",
    EN: "In all your shops, you can freely determine the collection times per branch.",
    FR: "Dans toutes vos magasins, vous pouvez librement déterminer les horaires de collecte par agence.",
  },
  {
    NL: "Zo kan de klant niet alleen kiezen wanneer, maar ook in welk ophaalpunt hij het beste terecht kan.",
    EN: "In this way, the customer can choose not only when, but also at which collection point it is best to go.",
    FR: "De cette façon, le client peut non seulement choisir quand, mais aussi à quel point de retrait il est préférable de se rendre.",
  },
  {
    NL: "Door de partnering met Worldline kan de klant op voorhand online betalen.",
    EN: "By partnering with Worldline, the customer can pay online in advance.",
    FR: "En s'associant à Worldline, le client peut payer en ligne à l'avance.",
  },
  {
    NL: "De orderverwerking, of die nu centraal gebeurt of niet, is per locatie en per moment te raadplegen.",
    EN: "The order processing, whether it is done centrally or not, can be consulted per location and per moment.",
    FR: "Le traitement des commandes, qu'il soit centralisé ou non, peut être consulté par lieu et par instant.",
  },
  {
    NL: "De afhaalmomenten kunnen vrij worden bepaald, en kan je verhogen naar het weekend toe, wanneer het drukker wordt. Zo kan je je personeel ontlasten van piekmomenten en het werk gelijkmatiger verdelen. Hierdoor stijgt de efficiëntie.",
    EN: "The pick-up times can be freely determined, and you can increase them towards the weekend when it gets busier. This way you can relieve your staff of peak moments and distribute the work more evenly. This increases efficiency.",
    FR: "Les heures de prise en charge peuvent être librement déterminées, et vous pouvez les augmenter vers le week-end quand il y a plus de monde. De cette façon, vous pouvez soulager votre personnel des moments de pointe et répartir le travail plus uniformément. Cela augmente l'efficacité.",
  },
  {
    NL: "Bovendien kan je het maximum aantal bestellingen per timeslot ingeven, en een levertijd hanteren, zodat je ruim de tijd hebt om voor voldoende ingrediënten te zorgen.",
    EN: "You can also enter the maximum number of orders per time slot and set a delivery time, so that you have plenty of time to ensure that you have enough ingredients.",
    FR: "Vous pouvez également entrer le nombre maximum de commandes par créneau horaire et définir une heure de livraison, afin d'avoir suffisamment de temps pour vous assurer d'avoir suffisamment d'ingrédients.",
  },
  {
    NL: "Via gepersonaliseerde emails wordt de klant op de hoogte gehouden van de voortgang van zijn bestelling.",
    EN: "The customer is kept informed of the progress of his order via personalized emails.",
    FR: "Le client est tenu informé de l'évolution de sa commande via des emails personnalisés.",
  },
  {
    NL: "Wat met het persoonlijke contact ? Bij het verwerken zie je bij elke bestelling de naam en contactgegevens, door hierop te klikken bel je automatisch (Google Android), of start je een email op. Zo blijf je steeds in nauw contact met de klant, en kan je hem/haar snel contacteren wanneer er iets niet duidelijk is.",
    EN: "What about personal contact? When processing you see the name and contact details with every order, by clicking on this you automatically call (Google Android), or you start an email. This way you always stay in close contact with the customer, and you can contact him/her quickly if something is not clear.",
    FR: "Qu'en est-il du contact personnel ? Lors du traitement, vous voyez le nom et les coordonnées de chaque commande, en cliquant dessus, vous appelez automatiquement (Google Android) ou vous démarrez un e-mail. De cette façon, vous restez toujours en contact étroit avec le client et vous pouvez le contacter rapidement si quelque chose n'est pas clair.",
  },
  {
    NL: "Ubicopia® voorziet de mogelijkheid HTML mails te versturen naar je klanten. Zo informeer je je klantenbestand in één klap, of doe je een email marketingcampagne.",
    EN: "Ubicopia® provides the possibility to send HTML mails to your customers. This way, you can inform your customer base in one fell swoop, or run an email marketing campaign.",
    FR: "Ubicopia® offre la possibilité d'envoyer des mails HTML à vos clients. De cette façon, vous pouvez informer votre clientèle d'un seul coup ou lancer une campagne de marketing par e-mail.",
  },
  {
    NL: "Door de makkelijke overzichten voor de boekhouding en de orderverwerking win je al snel veel tijd en kan je je focussen op je zaak en je leven, ipv administratie.",
    EN: "Thanks to the easy overviews for accounting and order processing, you quickly save a lot of time and you can focus on your business and your life, instead of administration.",
    FR: "Grâce aux aperçus simples de la comptabilité et du traitement des commandes, vous gagnez rapidement beaucoup de temps et vous pouvez vous concentrer sur votre entreprise et votre vie, plutôt que sur l'administration.",
  },
  {
    NL: "Facturen en orderbevestigingen worden automatisch opgemaakt en, volgens uw ingestelde voorkeuren, al dan niet verstuurd naar de klant. Deze kan zijn documenten ook altijd achteraf downloaden via de microShop.",
    EN: "Invoices and order confirmations are automatically generated and, according to your set preferences, whether or not sent to the customer. He/she can always download his documents afterwards via the microShop.",
    FR: "Les factures et les confirmations de commande sont automatiquement générées et, selon vos préférences définies, envoyées ou pas au client. Il peut toujours télécharger ses documents par la suite via le microShop.",
  },
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ Case De Traiteur  ----------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Webwinkel voor de traiteur",
    EN: "Online shop for the caterer",
    FR: "Boutique en ligne pour le traiteur",
  },
  {
    NL: "Ubicopia® biedt tal van voordelen voor het verhogen van de rendabiliteit van je zaak.",
    EN: "Ubicopia® offers numerous benefits for increasing the profitability of your business.",
    FR: "Ubicopia® offre de nombreux avantages pour augmenter la rentabilité de votre entreprise.",
  },
  {
    NL: "Je communiceert rechtstreeks met je klantenbestand.",
    EN: "You communicate directly with your customer base.",
    FR: "Vous communiquez directement avec votre clientèle.",
  },
  {
    NL: "Het biedt de ideale hulp die je nodig hebt tijdens drukke piekmomenten.",
    EN: "It offers the ideal help you need during busy peak times.",
    FR: "Il offre l'aide idéale dont vous avez besoin pendant les heures de pointe.",
  },
  {
    NL: "Een webwinkel voor traiteur verhoogt je klantenbinding",
    EN: "A webshop for caterers increases your customer loyalty",
    FR: "Une boutique en ligne pour traiteurs fidélise votre clientèle",
  },
  {
    NL: "Met het Ubicopia® marketing-mail pakket, standaard inclusief, kan je mailen naar je klantenbestand en zo actief je klandizie informeren over je laatste initiatief.",
    EN: "With the Ubicopia® marketing-mail package, which is included as standard, you can mail to your customer base and thus actively inform your customers about your latest initiative.",
    FR: "Avec le package de marketing-mail Ubicopia®, qui est inclus en standard, vous pouvez envoyer un courrier à votre clientèle et ainsi informer activement vos clients de votre dernière initiative.",
  },
  {
    NL: "Indien gewenst kan je je klant actief op de hoogte houden ivm drukke momenten, en zo bvb adviseren om tijdig te bestellen.",
    EN: "If desired, you can actively keep your customer informed about busy moments, and thus advise, for example, to order in time.",
    FR: "Si vous le souhaitez, vous pouvez informer activement votre client des moments de pointe et ainsi lui conseiller, par exemple, de commander à temps.",
  },
  {
    NL: "Met de webwinkel bekom je een stijging in je efficiëntie",
    EN: "With the webshop you achieve an increase in your efficiency",
    FR: "Avec la boutique en ligne, vous augmentez votre efficacité",
  },
  {
    NL: "Het systeem werkt voor jou en organiseert je orderflow.",
    EN: "The system works for you and organizes your order flow.",
    FR: "Le système travaille pour vous et organise votre flux de commandes.",
  },
  {
    NL: "Het systeem bundelt de bereidingen per leverdag of levermoment, en locatie, zo verhoog je je efficiëntie bij de bereidingen.",
    EN: "The system bundles the preparations per delivery day or delivery time, and location, so you increase your efficiency with the preparations.",
    FR: "Le système regroupe les préparations par jour de livraison ou heure de livraison et lieu, de sorte que vous augmentez votre efficacité avec les préparations.",
  },
  {
    NL: "Ubicopia® maakt actief komaf met archaïsche orderverwerking en bezorgt je mooie overzichten van de bestellingen.",
    EN: "Ubicopia® actively puts an end to archaic order processing and provides you with nice order overviews.",
    FR: "Ubicopia® met activement fin au traitement archaïque des commandes et vous offre de bons aperçus des commandes.",
  },
  {
    NL: "Zo kan je zien welke bestellingen al dan niet reeds online betaald zijn. Welke bestellingen reeds zijn afgehaald, en welke bvb nog moeten worder verwerkt.",
    EN: "This way you can see which orders have already been paid online or not. Which orders have already been collected, and which, for example, still need to be processed.",
    FR: "De cette façon, vous pouvez voir quelles commandes ont déjà été payées en ligne ou non. Quelles commandes ont déjà été collectées et lesquelles, par exemple, doivent encore être traitées.",
  },
  {
    NL: "De webwinkel verhoogt je omzet",
    EN: "The webshop increases your turnover",
    FR: "La boutique en ligne augmente votre chiffre d'affaires",
  },
  {
    NL: "De online traiteur",
    EN: "The online caterer",
    FR: "Le traiteur en ligne",
  },
  {
    NL: "Je opent een nieuwe verkoopkanaal. Het is geweten dat de moderne consument meer en meer online gaat shoppen. Met een eigen webwinkel trek je de moderne consument aan, en verhoog je je omzet.",
    EN: "You open a new sales channel. It is known that the modern consumer is shopping more and more online. With your own webshop you attract the modern consumer and increase your turnover.",
    FR: "Vous ouvrez un nouveau canal de vente. On sait que le consommateur moderne achète de plus en plus en ligne. Avec votre propre boutique en ligne, vous attirez le consommateur moderne et augmentez votre chiffre d'affaires.",
  },
  {
    NL: "Ubicopia® werd net voor deze nood aan organisatie ontwikkeld.",
    EN: "Ubicopia® was developed just for this need for organization.",
    FR: "Ubicopia® a été développé précisément pour ce besoin d'organisation.",
  },
  {
    NL: "Eenmaal de feestdagen voorbij, wordt terug overgeschakeld op het klassieke aanbod, bvb. bbq gerechten in de zomer, gourmetschotels in de winter, en dient de website, webshop en alle bijbehorende weer snel aangepast te worden.",
    EN: "Once the holidays are over, we switch back to the classic offer, e.g. bbq dishes in the summer, gourmet dishes in the winter, and the website, webshop and all associated weather needs to be adjusted quickly.",
    FR: "Une fois les vacances terminées, nous revenons à l'offre classique, par ex. des plats de barbecue en été, des plats gastronomiques en hiver, et le site Web, la boutique en ligne et toutes les conditions météorologiques associées doivent être ajustés rapidement.",
  },
  {
    NL: "Dankzij de zelf configuurbare inhoud van de webshop kan u de producten en rubrieken en subrubrieken zelf kiezen. Per product kan u bovendien opties bepalen, zo kan je definiëren dat dezelfde schotel voor 2 personen, 4 of 6 personen, een andere prijs krijgt en op een andere, correcte manier wordt verwerkt. Tegen het aanbreken van de feestdagen zet u enkele feestschotels in promotie, deze verschijnen vooraan op de pagina, u voegt enkele rubrieken en subrubrieken toe voor de feestschotels en plaatst deze vooraan in de webshop microShop). Eenmaal de feesten voorbij, past u de inhoud aan, en kan u terug naar het normale productassortiment.",
    EN: "Thanks to the self-configurable content of the webshop, you can choose the products, categories and subcategories yourself. You can also determine options per product, so you can define that the same dish for 2 people, 4 or 6 people, will receive a different price and will be processed in a different, correct way. Towards the start of the holidays, you put some festive dishes in promotion, they appear at the front of the page, you add a few categories and subcategories for the festive dishes and place them at the front of the microShop webshop). Once the parties are over, you adjust the content and you can return to the normal product range.",
    FR: "Grâce au contenu auto-configurable de la boutique en ligne, vous pouvez choisir vous-même les produits, catégories et sous-catégories. Vous pouvez également déterminer des options par produit, ainsi vous pouvez définir qu'un même plat pour 2 personnes, 4 ou 6 personnes, recevra un prix différent et sera traité d'une manière différente et correcte. Vers le début des fêtes, vous mettez des plats festifs en promotion, ils apparaissent en tête de page, vous ajoutez quelques catégories et sous-catégories pour les plats festifs et les placez en tête de la boutique en ligne microShop). Une fois les soirées terminées, vous ajustez le contenu et vous pouvez revenir à la gamme de produits normale.",
  },
  {
    NL: "Na de feestdagen, wenst u enkele dagen rust. In de afhaalmomenten kan u deze wekelijks of 2 of 3-wekelijks laten herhalen. Bij verlof geeft u de verlofdagen in en wordt de afhaalkalender voor uw verlofdagen geblokkeerd. Op deze manier kan de klant geen bestellingen boeken om af te halen wanneer u bent gesloten.",
    EN: "After the holidays, you wish for a few days of rest. You can have this repeated weekly or every 2 or 3 weeks in the collection times. For vacation, you enter the days off and the collection calendar for your days off is blocked. This way the customer cannot book orders for pick up when you are closed.",
    FR: "Après les vacances, vous souhaitez quelques jours de repos. Vous pouvez le répéter chaque semaine ou toutes les 2 ou 3 semaines dans les heures de collecte. Pour les vacances, vous renseignez les jours chômés et le calendrier de collecte de vos jours chômés est bloqué. De cette façon, le client ne peut pas réserver de commandes pour le ramassage lorsque vous êtes fermé.",
  },
  {
    NL: "Online betalen kan worden ingeschakeld. Door de partnering met Worldline kan de klant online op voorhand betalen. U en uw klanten winnen tijd en efficiëntie bij het afhalen.",
    EN: "Online payment can be enabled. Through the partnering with Worldline, the customer can pay online in advance. You and your customers gain time and efficiency when picking up.",
    FR: "Le paiement en ligne peut être activé. Grâce au partenariat avec Worldline, le client peut payer en ligne à l'avance. Vous et vos clients gagnez en temps et en efficacité lors de l'enlèvement.",
  },
  {
    NL: "Wat echter indien er misbruik wordt gemaakt bij het bestellen? Iedereen die een webshop of ophaalsysteem heeft, heeft ofwel er al eens bij stilgestaan, of in het slechtste geval, het al meegemaakt. Wat als de klant een nepaccount aanmaakt, en een grote bestelling plaatst en deze dan niet komt afhalen? Ubicopia® heef het optionele systeem ingebouwd, dat bijvoorbeeld de eerste x aantal bestellingen online dienen betaald te worden, alvorens betaling bij afhalen kan worden ingeschakeld.",
    EN: "However, what if there is abuse during ordering? Anyone who has a webshop or collection system has either thought about it, or in the worst case, experienced it. What if the customer creates a fake account and places a large order and then doesn't pick it up? Ubicopia® has built in the optional system that, for example, the first x number of orders must be paid online before payment on collection can be enabled.",
    FR: "Cependant, que se passe-t-il s'il y a abus lors de la commande ? Quiconque possède une boutique en ligne ou un système de collecte y a pensé ou, dans le pire des cas, en a fait l'expérience. Que se passe-t-il si le client crée un faux compte et passe une commande importante, puis ne la récupère pas ? Ubicopia® a intégré le système optionnel selon lequel, par exemple, le premier x nombre de commandes doit être payé en ligne avant que le paiement à l'enlèvement puisse être activé.",
  },
  {
    NL: "De klant wordt via gepersonaliseerde emails op de hoogte gehouden van de voortgang van zijn bestelling. Deze kunnen elk afzonderlijk worden aan of afgeschakeld. Doet u bvb     geen verzending, dan hoeft er ook geen mail verstuurd te worden wanneer de bestelling klaar is.",
    EN: "The customer is kept informed of the progress of his order via personalized emails. These can be switched on or off individually. For example, if you do not ship, then no email needs to be sent when the order is ready.",
    FR: "Le client est tenu informé de l'évolution de sa commande via des emails personnalisés. Ceux-ci peuvent être activés ou désactivés individuellement. Par exemple, si vous n'expédiez pas, aucun e-mail ne doit être envoyé lorsque la commande est prête.",
  },
  {
    NL: "Wat met het persoonlijke contact? Bij het verwerken zie je bij elke bestelling de naam en contactgegevens, door hierop te klikken bel je automatisch (Google Android), of start je een email op. Zo blijf je steeds in nauw contact met de klant, en kan je hem/haar snel contacteren wanneer er iets niet duidelijk is.",
    EN: "What about personal contact? When processing you see the name and contact details with every order, by clicking on this you automatically call (Google Android), or you start an email. This way you always stay in close contact with the customer, and you can contact him/her quickly if something is not clear.",
    FR: "Qu'en est-il du contact personnel ? Lors du traitement, vous voyez le nom et les coordonnées de chaque commande, en cliquant dessus, vous appelez automatiquement (Google Android) ou vous démarrez un e-mail. De cette façon, vous restez toujours en contact étroit avec le client et vous pouvez le contacter rapidement si quelque chose n'est pas clair.",
  },
  {
    NL: "Door de makkelijke overzichten voor de boekhouding en de orderverwerking win je al snel veel tijd en kan je je focussen op je zaak en je leven, ipv administratie.",
    EN: "Thanks to the easy overviews for accounting and order processing, you quickly save a lot of time and you can focus on your business and your life, instead of administration.",
    FR: "Grâce aux aperçus simples de la comptabilité et du traitement des commandes, vous gagnez rapidement beaucoup de temps et vous pouvez vous concentrer sur votre entreprise et votre vie, plutôt que sur l'administration.",
  },
  {
    NL: "Facturen en orderbevestigingen worden automatisch opgemaakt en, naargelang uw ingestelde voorkeuren, al dan niet verstuurd naar de klant. Deze kan zijn documenten ook altijd achteraf downloaden via de microShop.",
    EN: "Invoices and order confirmations are automatically generated and, depending on your set preferences, whether or not sent to the customer. He/she can always download his documents afterwards via the microShop.",
    FR: "Les factures et les confirmations de commande sont automatiquement générées et, selon vos préférences définies, envoyées ou non au client. Il/elle peut toujours télécharger ses documents par la suite via le microShop.",
  },
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ Case Normale webshop --------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Ubicopia® als een normale webshop",
    EN: "Ubicopia® as a classic webshop ?",
    FR: "Ubicopia® comme webshop classique ?",
  },
  {
    NL: "Ubicopia® levert alle mogelijkheden zoals een normale webshop. Maar gaat natuurlijk veel verder.",
    EN: "Ubicopia® provides all possibilities like a normal webshop. And.. off course.. much more.",
    FR: "Ubicopia® offre toutes les possibilités comme une boutique en ligne normale. Et... bien sûr... beaucoup plus.",
  },
  {
    NL: "Ubicopia® biedt tal van voordelen voor het verhogen van de rendabiliteit van je zaak.",
    EN: "Ubicopia® offers numerous benefits for increasing the profitability of your business.",
    FR: "Ubicopia® offre de nombreux avantages pour augmenter la rentabilité de votre entreprise.",
  },
  {
    NL: "Online betalingen zijn mogelijk via de partnering met Worldline.",
    EN: "Online payments are possible through the partnership with Worldline.",
    FR: "Les paiements en ligne sont possibles grâce au partenariat avec Worldline.",
  },
  {
    NL: "Via PostNL verzend je je pakketten naar je klanten.",
    EN: "You send your packages to your customers via PostNL.",
    FR: "Vous envoyez vos colis à vos clients via PostNL.",
  },
  {
    NL: "Simpel, snel en efficiënt",
    EN: "Simple, fast and efficient",
    FR: "Simple, rapide et efficace",
  },
  {
    NL: "Hoe minder klikken, hoe beter.",
    EN: "The fewer clicks, the better.",
    FR: "Moins il y a de clics, mieux c'est.",
  },
  {
    NL: "Hoe sneller, hoe beter.",
    EN: "The faster the better.",
    FR: "Le plus rapide sera le mieux.",
  },
  {
    NL: "De Ubicopia® webwinkel is SEO klaar",
    EN: "The Ubicopia® webshop is SEO ready",
    FR: "La boutique en ligne Ubicopia® est prête pour le référencement SEO",
  },
  {
    NL: "Gemeten met de Google Lighthouse tool ligt de SEO score steeds hoger dan 95%.",
    EN: "Measured with the Google Lighthouse tool, the SEO score is always higher than 95%.",
    FR: "Mesuré avec l'outil Google Lighthouse, le score SEO est toujours supérieur à 95%.",
  },
  {
    NL: "Net als een normale webshop",
    EN: "Just like a normal webshop",
    FR: "Comme une boutique en ligne normale",
  },
  {
    NL: "Categoriën en subcategoriën",
    EN: "Categories and subcategories",
    FR: "Catégories et sous-catégories",
  },
  {
    NL: "Zoals het moet om je klanten wegwijs te maken,werk je met categoriën en subcategoriën.",
    EN: "As you should to familiarize your customers, you work with categories and subcategories.",
    FR: "Comme il se doit pour familiariser vos clients, vous travaillez avec des catégories et des sous-catégories.",
  },
  {
    NL: "Alhoewel Ubicopia® specifiek voor de kleinhandel is ontwikkeld, is deze perfect inzetbaar als normale webshop. Dit biedt trouwens enkele belangrijke voordelen.",
    EN: "Although Ubicopia® has been developed specifically for retail, it can be used perfectly as a normal web shop. By the way, this offers some important advantages.",
    FR: "Bien qu'Ubicopia® ait été développé spécifiquement pour le commerce de détail, il peut parfaitement être utilisé comme une boutique en ligne normale. Soit dit en passant, cela offre des avantages importants.",
  },
  {
    NL: "Je definieert je producten in productgroepen, en subcategoriën. De klant ziet steeds waar hij is door de breadcrumb.",
    EN: "You define your products in product groups and subcategories. The customer always sees where he is through the breadcrumb.",
    FR: "Vous définissez vos produits dans des groupes de produits et des sous-catégories. Le client voit toujours où il en est à travers le fil d'Ariane.",
  },
  {
    NL: "Doordat deze op grote hoeveelheden is ingesteld, is deze storefront supersnel. Hiervoor wordt gebruik gemaakt van de buffer-technologie via AWS.",
    EN: "Because it is set to large quantities, this storefront is super fast. The buffer technology via AWS is used for this.",
    FR: "Parce qu'il est réglé sur de grandes quantités, cette vitrine est super rapide. La technologie de tampon via AWS est utilisée pour cela.",
  },
  {
    NL: "Online betalen en verzenden",
    EN: "Pay and ship online",
    FR: "Payez et expédiez en ligne",
  },
  {
    NL: "Verzendingen kun je laten gebeurten via PostNL. De API-interface met PostNL is standaard voorzien.",
    EN: "Shipments can be made via PostNL. The API interface with PostNL is provided as standard.",
    FR: "Les envois peuvent être effectués via PostNL. L'interface API avec PostNL est fournie en standard.",
  },
  {
    NL: "Online betalen kan via de interface met de Wordline Saferpay app.",
    EN: "You can pay online via the interface with the Wordline Saferpay app.",
    FR: "Vous pouvez payer en ligne via l'interface avec l'application Wordline Saferpay.",
  },
  {
    NL: "Samen met de orderflow wordt de klant naar wens geïnformeerd over de vooruitgang van zijn pakket.",
    EN: "Together with the order flow, the customer is informed as desired about the progress of his package.",
    FR: "En même temps que le flux de commande, le client est informé comme il le souhaite de l'état d'avancement de son colis.",
  },
  {
    NL: "Een efficiënte oplossing",
    EN: "An efficient solution",
    FR: "Une solution efficace",
  },
  {
    NL: "Het systeem werkt voor jou en organiseert je orderflow.",
    EN: "The system works for you and organizes your order flow.",
    FR: "Le système travaille pour vous et organise votre flux de commandes.",
  },
  {
    NL: "Doordat alles automatische en ordelijk gebeurt kan je op beide oren slapen terwijl je klant goed en duidelijk wordt geïnformeerd.",
    EN: "Because everything happens automatically and orderly, you can sleep soundly while your customer is well and clearly informed.",
    FR: "Parce que tout se passe automatiquement et de manière ordonnée, vous pouvez dormir sur vos deux oreilles tandis que votre client est bien et clairement informé.",
  },
  {
    NL: "Je definieert productgroepen die gebonden zijn aan levertijden, indien van toepassing. Het verzendmoment dat door de klant wordt gekozen houdt hiermee rekening.",
    EN: "You define product groups that are bound by delivery times, if applicable. The shipping time chosen by the customer takes this into account.",
    FR: "Vous définissez des groupes de produits liés par des délais de livraison, le cas échéant. Le délai d'expédition choisi par le client en tient compte.",
  },
  {
    NL: "Het systeem bundelt de bestellingen per dag of verzendmoment, zo verhoog je je efficiëntie bij het verpakken.",
    EN: "The system bundles the orders per day or shipping time, so you increase your efficiency when packing.",
    FR: "Le système regroupe les commandes par jour ou par délai d'expédition, ce qui vous permet d'augmenter votre efficacité lors de l'emballage.",
  },
  {
    NL: "Je kan je klant ook laten ophalen op verschillende locaties met vrij te kiezen ophaalmomenten.",
    EN: "You can also have your customer picked up at different locations with freely selectable pick-up times.",
    FR: "Vous pouvez également faire ramasser votre client à différents endroits avec des heures de ramassage librement sélectionnables.",
  },
  {
    NL: "Belangrijk is dat je wordt gevonden. De webwinkels van Ubicopia® scoren hoog in SEO. We garanderen een SEO score >95%. Gemeten met de Lighthouse tool van Google.",
    EN: "It is important that you are found. The web shops of Ubicopia® score high in SEO. We guarantee an SEO score >95%. Measured with Google's Lighthouse tool.",
    FR: "Il est important que vous soyez trouvé. Les boutiques en ligne d'Ubicopia® obtiennent de bons résultats en matière de référencement. Nous garantissons un score SEO >95%. Mesuré avec l'outil Lighthouse de Google.",
  },
  {
    NL: "Start je eigen webshop met Ubicopia®",
    EN: "Start your own webshop with Ubicopia®",
    FR: "Créez votre propre boutique en ligne avec Ubicopia®",
  },
  {
    NL: "Ubicopia® is vrij configureerbaar en biedt hiervoor een kant-en klare oplossing.",
    EN: "Ubicopia® is freely configurable and offers a ready-made solution for this.",
    FR: "Ubicopia® est librement configurable et offre une solution prête à l'emploi pour cela.",
  },
  {
    NL: "Je klanten kunnen online shoppen, hun bestelling afhandelen, hun lever- en facturatie adressen ingeven.",
    EN: "Your customers can shop online, complete their order, enter their delivery and billing addresses.",
    FR: "Vos clients peuvent effectuer leurs achats en ligne, finaliser leur commande, saisir leurs adresses de livraison et de facturation.",
  },
  {
    NL: "Online, per overschrijving of bij ophalen betalen.",
    EN: "Pay online, by bank transfer or upon collection.",
    FR: "Payez en ligne, par virement bancaire ou à l'enlèvement.",
  },
  {
    NL: "De verzending of afhaalmoment en locatie kiezen.",
    EN: "Choose the shipment or pick-up time and location.",
    FR: "Choisissez l'heure et le lieu d'expédition ou de ramassage.",
  },
  {
    NL: "De bestelling online opvolgen en de verzending traceren tot de levering.",
    EN: "Track the order online and track the shipment until delivery.",
    FR: "Suivez la commande en ligne et suivez l'expédition jusqu'à la livraison.",
  },
  {
    NL: "De online betalingen worden verzorgd door de partnering met Worldline. PostNl verzorgt de leveringen.",
    EN: "The online payments are provided by the partnering with Worldline. PostNL takes care of the deliveries.",
    FR: "Les paiements en ligne sont fournis par le partenariat avec Worldline. PostNL s'occupe des livraisons.",
  },
  {
    NL: "Hiervoor zijn geen plugins nodig, dit is ingebouwd in het Ubicopia® platform.",
    EN: "No plugins are required for this, this is built into the Ubicopia® platform.",
    FR: "Aucun plugin n'est requis pour cela, cela est intégré à la plateforme Ubicopia®.",
  },
  {
    NL: "Het verzenden van de gepersonaliseerde emails om de klanten op de hoogte te houden van bvb betaling, verzending, orderverwerking, is standaard voorzien, en kan desgewenst worden in of uitgeschakeld.",
    EN: "Sending personalized emails to keep customers informed of eg payment, shipping, order processing, is provided as standard, and can be switched on or off if desired.",
    FR: "L'envoi d'e-mails personnalisés pour tenir les clients informés, par exemple, du paiement, de l'expédition, du traitement des commandes, est fourni en standard et peut être activé ou désactivé comme vous le souhaitez.",
  },
  {
    NL: "Het versturen van HTML emails is gepersonaliseerd met jouw logo en persoonlijke aanspreking van jouw klant.",
    EN: "Sending HTML emails is personalized with your logo and a personal addressing of your customer.",
    FR: "L'envoi d'emails HTML est personnalisé avec votre logo et un adressage personnel de votre client.",
  },
  {
    NL: "Orderbevestigingen en facturen worden automatisch aangemaakt, en volgens je ingestelde voorkeuren, al dan niet verstuurd naar de klant. Deze orderbevestigingen, en facturen gebruik je voor je boekhouding.",
    EN: "Order confirmations and invoices are automatically created and, according to your set preferences, whether or not sent to the customer. You use these order confirmations and invoices for your accounting.",
    FR: "Les confirmations de commande et les factures sont automatiquement créées et, selon vos préférences définies, envoyées ou non au client. Vous utilisez ces confirmations de commande et factures pour votre comptabilité.",
  },
  {
    NL: "De status van zijn bestelling is online door de klant te volgen.",
    EN: "The status of his order can be followed online by the customer.",
    FR: "L'état de sa commande peut être suivi en ligne par le client.",
  },
  {
    NL: "Nieuwe producten kan je promoten, deze verschijnen automatisch op de homepage.",
    EN: "You can promote new products, which will automatically appear on the homepage.",
    FR: "Vous pouvez promouvoir de nouveaux produits, qui apparaîtront automatiquement sur la page d'accueil.",
  },
  {
    NL: "Je kan je klantenbestand downloaden, en hen mailings sturen (ingebouwd in Ubicopia, geen extra kosten !)",
    EN: "You can download your customer base, and send them mailings (built into Ubicopia, no extra costs!)",
    FR: "Vous pouvez télécharger votre base clients, et leur envoyer des mailings (intégrés à Ubicopia, sans surcoût !)",
  },
  {
    NL: "De orderverwerking verloopt overzichtelijk, ook al zijn het er veel tegelijk.",
    EN: "The order processing runs smoothly, even if there are many orders simultaneously.",
    FR: "Le traitement des commandes se déroule sans problème, même s'il y a plusieurs commandes simultanément.",
  },
  {
    NL: "terug",
    EN: "back",
    FR: "retour",
  },
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ HomeProductPage -------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Meest gekozen",
    EN: "Most chosen",
    FR: "Le plus choisi",
  },
  {
    NL: "Ontdek de verschillende producten van Ubicopia®",
    EN: "Discover the different Ubicopia® products",
    FR: "Découvrez les différents produits Ubicopia®",
  },
  {
    NL: "En vind je oplossing met de configurator.",
    EN: "And find your solution with the configurator.",
    FR: "Et trouvez votre solution avec le configurateur.",
  },
  {
    NL: "Het enige verschil zit in de grootte van je webshop, en de architectuur van je webpagina. Geen verborgen verrassingen.",
    EN: "The only difference is in the size of your webshop and the architecture of your web page. No hidden surprises.",
    FR: "La seule différence réside dans la taille de votre boutique en ligne et l'architecture de votre page Web. Pas de surprises cachées.",
  },
  {
    NL: "Vergelijk de kenmerken van elk pakket",
    EN: "Compare the features of each package",
    FR: "Comparez les fonctionnalités de chaque solution",
  },
  {
    NL: "en check of het voldoet aan je verwachtingen",
    EN: "and check whether it meets your expectations",
    FR: "et vérifiez s'il répond à vos attentes",
  },
  {
    NL: "De Ubicopia® microShop heeft steeds dezelfde kenmerken, welke grootte of pakket u ook kiest.",
    EN: "The Ubicopia® microShop always has the same features, whichever size or package you choose.",
    FR: "Le microShop Ubicopia® a toujours les mêmes fonctionnalités, quelle que soit la taille ou le package que vous choisissez.",
  },
  {
    NL: "Ubicopia® Product",
    EN: "Ubicopia® Product",
    FR: "Produit Ubicopia®",
  },
  {
    NL: "Tech",
    EN: "Tech",
    FR: "La tech",
  },
  {
    NL: "Snelle interface - 'Headless' architectuur",
    EN: "Fast interface - Headless architecture",
    FR: "Interface rapide - Architecture 'headless'",
  },
  {
    NL: "Onbeperkte upgrades",
    EN: "Unlimited upgrades",
    FR: "Upgrades illimitées",
  },
  {
    NL: "Zelf uitbreidbare architectuur",
    EN: "Self expandable architecture",
    FR: "Architecture auto-extensible",
  },
  {
    NL: "Dagelijkse databaseback-ups",
    EN: "Daily database backups",
    FR: "Sauvegardes quotidiennes de la base de données",
  },
  {
    NL: "Webshop ervaring",
    EN: "Webshop experience",
    FR: "Expérience de la boutique en ligne",
  },
  {
    NL: "Levertijd per product in te stellen",
    EN: "Product configurable leadtimes",
    FR: "Le délai de livraison peut être défini par produit",
  },
  {
    NL: "Product opties vrij in te stellen",
    EN: "Product options configurable",
    FR: "Options produit configurable",
  },
  {
    NL: "Volledige checkout flow",
    EN: "Full customer checkout flow",
    FR: "Flow de paiement complet",
  },
  {
    NL: "Meerdere addressen per klant",
    EN: "Customer Adress management",
    FR: "Plusieurs adresses par client",
  },
  {
    NL: "Bestelhistoriek klant",
    EN: "Customer Order history",
    FR: "Historique des commandes client",
  },
  {
    NL: "Orderbevestiging, faktuur pdf",
    EN: "Orderconfirmation, invoice pdf",
    FR: "Confirmation de commande, facture pdf",
  },
  {
    NL: "Onbeperkte afhaal locaties",
    EN: "Unlimited Pickup locations",
    FR: "Lieux à emporter illimités",
  },
  {
    NL: "Onbeperkte afhaal momenten",
    EN: "Unlimited Pickup timings",
    FR: "Horaires à emporter illimités",
  },
  {
    NL: "Instelbare timeslots",
    EN: "Adjustable timeslots",
    FR: "Horaires à emportes ajustables",
  },
  {
    NL: "Instelbaar aantal bestellingen per timeslot",
    EN: "Adjustable orders per timeslot",
    FR: "Nombre de commandes ajustable par plage horaire",
  },
  {
    NL: "Online betalingen",
    EN: "Online payments",
    FR: "Paiements en ligne",
  },
  {
    NL: "Verzendingen",
    EN: "Parcel Shipments",
    FR: "Expéditions de colis",
  },
  {
    NL: "Gepersonaliseerde meldingen",
    EN: "Customized notifications",
    FR: "Notifications personnalisées",
  },
  {
    NL: "Bestelstatus opvolgen",
    EN: "Order status tracking",
    FR: "Suivi de l'état de la commande",
  },
  {
    NL: "Opvolgen levering",
    EN: "Order shipment tracking",
    FR: "Suivi de l'expédition de la commande",
  },
  {
    NL: "Professionele verwerking van bestellingen",
    EN: "Advanced professionel order processing",
    FR: "Traitement professionnel des commandes",
  },
  {
    NL: "Verse bereidingen met gedeelde timer",
    EN: "Fresh cooking with shared timer",
    FR: "Traitement des commandes par minuterie partagées",
  },
  {
    NL: "Alle instellingen vrij te kiezen",
    EN: "Configurable preferences",
    FR: "Tous les paramètres librement sélectionnables",
  },
  {
    NL: "Inzichten en Customer relations",
    EN: "Insights & relations",
    FR: "Insights et relations clients",
  },
  {
    NL: "Downloadbare klantengegevens",
    EN: "Downloadable customer data",
    FR: "Données clients téléchargeables",
  },
  {
    NL: "Ingebouwde marketing emails",
    EN: "Built in marketing mails",
    FR: "E-mails marketing intégrés",
  },
  {
    NL: "Boekhoudkundige functionaliteiten",
    EN: "Accounting functionality",
    FR: "Fonctionnalité comptable",
  },
  {
    NL: "Dagontvangsten per BTW groep",
    EN: "Day-income overviews per VAT",
    FR: "Recettes quotidiennes par groupe de TVA",
  },
  {
    NL: "Automatische orderbevestiging, faktuur pdf",
    EN: "Generated orderconfirmation, invoice in pdf",
    FR: "Confirmation commande, facture automatique en pdf",
  },
  {
    NL: "Webshop Integratie",
    EN: "Webshop Integration",
    FR: "Intégration de la boutique en ligne",
  },
  {
    NL: "Snelle integratie in i-frame",
    EN: "Fast integration within i-frame",
    FR: "Intégration rapide dans i-frame",
  },
  {
    NL: "Integratie van de webshop in de website",
    EN: "Webshop integration within website",
    FR: "Intégration webshop dans le site Web",
  },
  {
    NL: "Samenstelbare modulaire integratie",
    EN: "Composable modular integration",
    FR: "Intégration modulaire composable",
  },
  {
    NL: "Website",
    EN: "Website",
    FR: "Site Internet",
  },
  {
    NL: "Landingpage + 3",
    EN: "Landingpage + 3",
    FR: "Page de destination + 3",
  },
  {
    NL: "Gepersonaliseerde website",
    EN: "Customized website",
    FR: "Site Web personnalisé",
  },
  {
    NL: "Webhosting inbegrepen",
    EN: "Webhosting included",
    FR: "Webhosting site d'internet inclus",
  },
  {
    NL: "Domeinnaam inclusief",
    EN: "Domain name included",
    FR: "Nom de domaine inclus",
  },
  {
    NL: "Site d'internet met CMS",
    EN: "Website with CMS",
    FR: "Site d'internet avec CMS",
  },
  {
    NL: "BTW niet inbegrepen",
    EN: "VAT not included",
    FR: "TVA non inclus",
  },
  {
    NL: "max.20€/jr",
    EN: "max.20€/yr",
    FR: "max.20€/ans",
  },
  {
    NL: "vereist een account met Worldline",
    EN: "requires an account with Worldline",
    FR: "nécessite un compte chez Worldline",
  },
  {
    NL: "vereist een account met PostNL",
    EN: "requires an account with PostNL",
    FR: "nécessite un compte chez PostNL",
  },
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ Configurator ----------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Ubicopia® product Configurator",
    EN: "Ubicopia® product Configurator",
    FR: "Configurateur de produits Ubicopia®",
  },
  {
    NL: "Stap 1:",
    EN: "Step 1:",
    FR: "Étape 1:",
  },
  {
    NL: "Kies hier de grootte van je webshop",
    EN: "Choose the size of your webshop here",
    FR: "Choisissez ici la taille de votre boutique en ligne",
  },
  {
    NL: "Dit heeft te maken met het aantal server-interacties die nodig zijn, en de hoeveelheid data die wordt gebruikt. Hierop zijn immers onze kosten gebaseerd.",
    EN: "This has to do with the number of server interactions required and the amount of data used. After all, our costs are based on this.",
    FR: "Cela a à voir avec le nombre d'interactions de serveur requises et la quantité de données utilisées. Après tout, nos coûts sont basés sur cela.",
  },
  {
    NL: "Kies hier de groote van je webshop",
    EN: "Choose the size of your webshop here",
    FR: "Choisissez ici la taille de votre boutique en ligne",
  },
  {
    NL: "Stap 1 voltooid:",
    EN: "Step 1 completed:",
    FR: "Étape 1 terminée :",
  },
  {
    NL: "Selecteer",
    EN: "Select",
    FR: "Sélectionner",
  },
  {
    NL: "Maak ongedaan",
    EN: "Cancel selection",
    FR: "Annuler la sélection",
  },
  {
    NL: "Je hebt gekozen voor volgend product",
    EN: "You have chosen the following product",
    FR: "Vous avez choisi le produit suivant",
  },
  {
    NL: "producten",
    EN: "products",
    FR: "produits",
  },
  {
    NL: "bestellingen/m",
    EN: "orders/m",
    FR: "commandes/m",
  },
  {
    NL: "meer dan 800 producten",
    EN: "more than 800 products",
    FR: "plus que 800 produits",
  },
  {
    NL: "meer dan 800 bestellingen/m",
    EN: "more than 800 orders/m",
    FR: "plus que 800 commandes/m",
  },
  {
    NL: "prijs op aanvraag",
    EN: "price on request",
    FR: "prix sur demande",
  },
  {
    NL: "Stap 2:",
    EN: "Step 2:",
    FR: "Étape 2:",
  },
  {
    NL: "Kies vervolgens hoe je de webshop wil presenteren en integreren",
    EN: "Choose how you want to present and integrate the webshop",
    FR: "Choisissez comment vous souhaitez présenter et intégrer votre boutique en ligne",
  },
  {
    NL: "Hier is van belang of je al een website hebt of niet, en of je deze wenst te behouden. Kies dan voor het pakket MicroShop. Of je eventueel een volledig nieuwe website wil maken, met de standaard microShop, dan kies je het pakket MicroSite. Wil je volledig gebruik maken van alle mogelijkheden van de headless technologie, dan kies je pakket CompoSite.",
    EN: "It is important here whether you already have a website or not, and whether you wish to keep it. Then choose the MicroShop package. Whether you want to create a completely new website, with the standard microShop, then choose the MicroSite package. If you want to make full use of all the possibilities of the headless technology, choose the CompoSite package.",
    FR: "Il est important ici de savoir si vous avez déjà un site Web ou non, et si vous souhaitez le conserver. Alors choisissez le pack MicroShop. Que vous souhaitiez créer un site Web entièrement nouveau, avec le microShop standard, choisissez alors le package MicroSite. Si vous souhaitez profiter pleinement de toutes les possibilités de la technologie sans tête, choisissez le pack CompoSite.",
  },
  {
    NL: "Stap 2 voltooid:",
    EN: "Step 2 completed:",
    FR: "Étape 2 terminée :",
  },
  {
    NL: "Je hebt gekozen voor volgende integratie",
    EN: "You have chosen the following integration",
    FR: "Vous avez choisi l'intégration suivante",
  },
  {
    NL: "U hebt reeds een website.",
    EN: "You already have a website.",
    FR: "Vous avez déjà un site Web.",
  },
  {
    NL: "Snelle integratie van de webshop.",
    EN: "Quick integration of the webshop",
    FR: "Intégration rapide de la boutique en ligne",
  },
  {
    NL: "Onmiddellijk van start.",
    EN: "Start immediately.",
    FR: "Commencez immédiatement.",
  },
  {
    NL: "U krijgt een nieuwe eenvoudige, maar efficiënte website als landingpage.",
    EN: "You get a new simple but efficient website as a landing page.",
    FR: "Vous obtenez un nouveau site Web simple mais efficace comme page de destination.",
  },
  {
    NL: "Eénmalig",
    EN: "One-off",
    FR: "Une fois",
  },
  {
    NL: "Maandelijks",
    EN: "Monthly",
    FR: "Par mois",
  },
  {
    NL: "mailbox inbegrepen",
    EN: "mailbox included",
    FR: "mailbox inclus",
  },
  {
    NL: "Deze optie bestaat uit een volledige customized oplossing voor website en webshop.",
    EN: "This option consists of a fully customized solution for website and webshop.",
    FR: "Cette option consiste en une solution entièrement personnalisée pour le site Web et la boutique en ligne.",
  },
  {
    NL: "Stap 3 onze aanbieding",
    EN: "Step 3 our offer",
    FR: "Étape 3 notre offre",
  },
  {
    NL: "is als volgt:",
    EN: "is as follows:",
    FR: "est comme suivant:",
  },
  {
    NL: "Onze aanbieding",
    EN: "Our proposal",
    FR: "Notre offre",
  },
  {
    NL: "Dit is een offerte voor een overeenkomst voor een abonnement voor 1 jaar, geldig vanaf vandaag,",
    EN: "This is a quotation for a 1-year subscription agreement, valid from today,",
    FR: "Ceci est un devis pour un contrat d'abonnement d'un an, valable à partir d'aujourd'hui,",
  },
  {
    NL: ", met als inhoud de levering van volgende e-commerce diensten:",
    EN: ", which includes the provision of the following e-commerce services:",
    FR: ", qui comprend la fourniture des services de commerce électronique suivants :",
  },
  {
    NL: "Als product koos je voor",
    EN: "As a product you chose",
    FR: "En tant que produit que vous avez choisi",
  },
  {
    NL: "Als integratie koos je voor",
    EN: "You selected integration",
    FR: "Vous avez choisi l'intégration",
  },
  {
    NL: "Voor een totale prijs van:",
    EN: "For the total price:",
    FR: "Pour le prix total :",
  },
  {
    NL: "éénmalige vaste prijs van ",
    EN: "one-time fixed price ",
    FR: "prix fixe unique ",
  },
  {
    NL: "Een maandelijkse prijs van ",
    EN: "A monthly price of ",
    FR: "Un prix mensuel de ",
  },
  {
    NL: "Bijzonderheden van deze aanbieding:",
    EN: "Specific details of this offer:",
    FR: "Détails specifique de cette offre :",
  },
  {
    NL: "Het betreft een overeenkomst voor een abonnement voor 1 jaar.",
    EN: "This is an agreement for a subscription for 1 year.",
    FR: "Il s'agit d'un accord pour un abonnement d'un an.",
  },
  {
    NL: "U geniet een gratis proefperiode van 14 dagen.",
    EN: "You enjoy a free trial period of 14 days.",
    FR: "Vous bénéficiez d'une période d'essai gratuite de 14 jours.",
  },
  {
    NL: "Het betreft een Saas dienstverlening, alle software en code blijft eigendom van Ubicopia®. Met uitzondering van éénmalige betaalde websites.",
    EN: "It concerns a Saas service, all software and code remains the property of Ubicopia®. With the exception of one-off paid websites.",
    FR: "Il s'agit d'un service Saas, tous les logiciels et codes restent la propriété d'Ubicopia®. À l'exception des sites Web payants ponctuels.",
  },
  {
    NL: "Naar invullen van uw gegevens.",
    EN: "To personal data entry.",
    FR: "Vers données personelles.",
  },
  {
    NL: "Je koos voor een product op maat. Hiervoor heb je een offerte nodig, en hebben we meer info nodig.",
    EN: "You choose a custom product. For this we need to make a proposal and require more information.",
    FR: "Vous avez choisi un produit personnalisé. Pour cela, nous devons faire une proposition et vous demander plus d'informations.",
  },
  {
    NL: "Beschrijf goed je verwachtingen van de website, dan nemen we spoedig contact op zodat we samen aan je toekomst kunnen werken.",
    EN: "Describe your expectations as good as possible, we will contact you shortly so that we can jointly work on your future.",
    FR: "Décrivez au mieux vos attentes, nous vous contacterons dans les plus brefs délais afin que nous puissions ensemble travailler sur votre avenir.",
  },
  {
    NL: "Stap 4:",
    EN: "Step 4:",
    FR: "Étape 4:",
  },
  {
    NL: "Jouw gegevens",
    EN: "Your data",
    FR: "Vos données",
  },
  {
    NL: "Bedankt om te registreren, je ontvangt een email om je account te bevestigen.",
    EN: "Thank you for registering, you will receive an email to confirm your account.",
    FR: "Merci de votre inscription, vous recevrez un email pour confirmer votre compte.",
  },
  {
    NL: "Nadat je account is gecontroleerd door het Ubicopia® team, kan je vervolgens inloggen met je paswoord. Dan kan je eventueel je profiel te vervolledigen met API's (Worldline,PostNL), en kan je al aan de slag om je shop te configureren. Deze maak je na inloggen aan in User/Your Details/Webshop details.",
    EN: "After your account has been checked by Ubicopia®, you can log in with your password. You can then complete your profile for example with your Worldline or PostNl passwords, and you can already start configuring your shop. You create your webshop in User/Your Details/Webshop details.",
    FR: "Après que votre compte à été verifié par Ubicopia®, vous pouvez vous connecter avec votre mot de passe. Si vous voules vous pourriez ensuite complété votre profil si nécessaire (mots de pass Worldline, PostNL), et vous pourrez déjà commencer à configurer votre boutique. Vous créez votre boutique en ligne dans Utilisateur/Vos détails/Détails de la boutique en ligne.",
  },
  {
    NL: "Configureer",
    EN: "Configurate",
    FR: "Configurer",
  },
  {
    NL: "Registreer",
    EN: "Sign up",
    FR: "Enregistrer",
  },
  {
    NL: "Ik teken in op een account",
    EN: "I signup for an account",
    FR: "Je crée un compte",
  },
  {
    NL: "Persoonlijke gegevens",
    EN: "Personal information",
    FR: "Informations personnelles",
  },
  {
    NL: "Telefoon (beginnende met +3..)",
    EN: "Phone (starting with +32..)",
    FR: "Téléphone (commence avec +32 ..)",
  },
  {
    NL: "Titel",
    EN: "Title",
    FR: "Titre",
  },
  {
    NL: "titel",
    EN: "title",
    FR: "titre",
  },
  {
    NL: "Voornaam",
    EN: "First name",
    FR: "Prénom",
  },
  {
    NL: "Achternaam",
    EN: "Name",
    FR: "Nom",
  },
  {
    NL: "Paswoord - min. 8 karakters, hoofd- kleine letter,cijfer en speciaal karakter. ",
    EN: "Password - min. 8 chars, uppercase and lowercase, a figure and special character. ",
    FR: "Mot de passe (au moins 8 car., majuscule + minuscule, chiffre et symbole spécial. ",
  },
  {
    NL: "Paswoord",
    EN: "Password",
    FR: "Mot de passe",
  },
  {
    NL: "Nogmaals je paswoord",
    EN: "Repeat your password",
    FR: "Répétez votre mot de passe",
  },
  {
    NL: "Bedrijfsgegevens",
    EN: "Company data",
    FR: "Les données de votre société",
  },
  {
    NL: "Officiële naam van je organisatie",
    EN: "Official name of your organization",
    FR: "Nom officiel de votre organisation",
  },
  {
    NL: "BTW nummer - BEXXXXXXXXXX (geen spaties)",
    EN: "VAT number - BEXXXXXXXXXX (no spaces)",
    FR: "Numéro TVA - BEXXXXXXXXXX (pas d'espaces)",
  },
  {
    NL: "Kies de handelsnaam voor op je webshop.",
    EN: "Choose the trade name for your webshop.",
    FR: "Choisissez le nom commercial de votre boutique en ligne.",
  },
  {
    NL: "Beschrijf de goederen die je wil verhandelen",
    EN: "Describe the goods you want to trade",
    FR: "Décrivez les marchandises que vous souhaitez échanger",
  },
  {
    NL: "Officiële adres bedrijf",
    EN: "Official address company",
    FR: "Adresse officielle de l'entreprise",
  },
  {
    NL: "Straat",
    EN: "Street",
    FR: "Rue",
  },
  {
    NL: "Huisnummer",
    EN: "Number",
    FR: "Numéro",
  },
  {
    NL: "Stad",
    EN: "City",
    FR: "Ville",
  },
  {
    NL: "Postcode",
    EN: "Zip",
    FR: "Code postal",
  },
  {
    NL: "Land",
    EN: "Country",
    FR: "Pays",
  },
  {
    NL: "kies een land",
    EN: "select a country",
    FR: "choisissez un pays",
  },
  {
    NL: "Door te registreren accepteer je de",
    EN: "By registering you accept",
    FR: "En vous inscrivant, vous acceptez",
  },
  {
    NL: "Algemene voorwaarden",
    EN: "General terms and conditions",
    FR: "Conditions Générales",
  },
  {
    NL: ", alsook de bovenvermelde aanbieding voor de geldigheid van 1 jaar.",
    EN: ", as well as the above-mentioned offer for the validity of 1 year.",
    FR: ", ainsi que l'offre précitée pour une validité de 1 an.",
  },
  {
    NL: "Registreer",
    EN: "Sign Up",
    FR: "S'inscrire",
  },
  {
    NL: "Sluit",
    EN: "Close",
    FR: "Fermer",
  },
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ HomeTechPage ----------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "E-Commerce was nooit zo makkelijk. Hoe kan dit ?",
    EN: "E-commerce has never been this easy. How is this possible ?",
    FR: "E-commerce n'a jamais été aussi facile. Comment est-ce possible ?",
  },
  {
    NL: "Ubicopia is Saas gebaseerd, werkt headless en is composable.",
    EN: "Ubicopia® is Saas based, works headless and is composable.",
    FR: "Ubicopia® est basé sur Saas, fonctionne 'headless' et est composable.",
  },
  {
    NL: "Wat wil dit nou zeggen ?",
    EN: "What does this mean ?",
    FR: "Qu'est-ce que ça veut dire ?",
  },
  {
    NL: "Hoe werkt het Ubicopia® platform, en wat zijn de voordelen ?",
    EN: "How does the Ubicopia® platform work, and what are the benefits?",
    FR: "Comment fonctionne la plateforme Ubicopia® et quels en sont les avantages ?",
  },
  {
    NL: "Ubicopia® architectuur",
    EN: "Ubicopia® architecture",
    FR: "Architecture Ubicopia®",
  },
  {
    NL: "Saas uitgelegd",
    EN: "Saas explained",
    FR: "Saas expliqué",
  },
  {
    NL: "Software as a service, vaak afgekort als SaaS, ook weleens software on demand genoemd, is software die als een online dienst wordt aangeboden. De klant hoeft de software niet aan te schaffen, maar sluit bijvoorbeeld een contract per maand per gebruiker af, eventueel in combinatie met andere parameters. De SaaS-aanbieder zorgt voor installatie, onderhoud en beheer, de gebruiker benadert de software over het internet bij de SaaS-aanbieder.",
    EN: "Software as a service, often abbreviated as SaaS, also sometimes referred to as software on demand, is software that is offered as an online service. The customer does not have to purchase the software, but concludes, for example, a contract per month per user, possibly in combination with other parameters. The SaaS provider takes care of installation, maintenance and management, the user accesses the software over the internet at the SaaS provider.",
    FR: "Le logiciel en tant que service, souvent abrégé en SaaS, également parfois appelé logiciel à la demande, est un logiciel proposé en tant que service en ligne. Le client n'est pas obligé d'acheter le logiciel, mais conclut, par exemple, un contrat par mois et par utilisateur, éventuellement en combinaison avec d'autres paramètres. Le fournisseur SaaS s'occupe de l'installation, de la maintenance et de la gestion, l'utilisateur accède au logiciel via Internet chez le fournisseur SaaS.",
  },
  {
    NL: "Bron",
    EN: "Source",
    FR: "Source",
  },
  {
    NL: "Traditionele websites hebben hun eigen back-end en front-end (grafische gebruikersinterface). Alle stukjes werken met dezelfde codebasis en communiceren rechtstreeks met elkaar, waardoor de website een geheel wordt. Bij een headless installatie is de front-end echter een op zichzelf staand stuk software, dat via API communiceert met een back-end. Beide delen werken los van elkaar, en kunnen zelfs op aparte servers geplaatst worden, waardoor een minimale versie van een multi-server architectuur ontstaat. De brug tussen beide delen is de API-client. De eindpunten van de API zijn met elkaar verbonden. De grootste voordelen van deze technologie zijn te vinden in prestatieoptimalisatie en flexibiliteit van de softwarestack.",
    EN: "Traditional websites have their own back-end and front-end (graphical user interface). All the pieces work with the same code base and communicate directly with each other, making the website as a whole. However in a headless installation the front-end is a stand-alone piece of software, which through API communicates with a back-end. Both parts operate separately from each other, and can even be placed on separate servers, creating a minimum version of a multi-server architecture. The bridge between both parts is the API client. The endpoints of the API are connected to each other. The biggest advantages of this technology can be found in performance optimisation and flexibility of the software stack.",
    FR: "Les sites Web traditionnels ont leur propre back-end et front-end (interface utilisateur graphique). Toutes les pièces fonctionnent avec la même base de code et communiquent directement les unes avec les autres, faisant du site Web un tout. Cependant, dans une installation 'headless', le frontal est un logiciel autonome qui, via l'API, communique avec un back-end. Les deux parties fonctionnent séparément l'une de l'autre et peuvent même être placées sur des serveurs séparés, créant ainsi une version minimale d'une architecture multi-serveurs. Le lien entre les deux parties est le client API. Les points de terminaison de l'API sont connectés les uns aux autres. Les principaux avantages de cette technologie résident dans l'optimisation des performances et la flexibilité de la pile logicielle.",
  },
  {
    NL: "Composable commerce is een strategie waarbij je je IT-architectuur inricht door verschillende microservices en best-practice tools te combineren in één applicatie. In plaats van een monolitische oplossing kies je voor een aanpak waarbij je best-of-breed businesscomponenten combineert (composed). Je knipt je applicatie dus op in losse componenten en microservices die samen de business componenten (Packaged Business Capabilities) verzorgen.",
    EN: "Composable commerce is a strategy in which you set up your IT architecture by combining various microservices and best-practice tools in one application. Instead of a monolithic solution, you opt for an approach in which you combine best-of-breed business components (composed). So you split your application into separate components and microservices that together provide the business components (Packaged Business Capabilities).",
    FR: "Le commerce composable est une stratégie dans laquelle vous configurez votre architecture informatique en combinant divers microservices et outils de meilleures pratiques dans une seule application. Au lieu d'une solution monolithique, vous optez pour une approche dans laquelle vous combinez les meilleurs composants métier (composés). Vous divisez donc votre application en composants et microservices distincts qui fournissent ensemble les composants métier (Capacités métier packagées).",
  },
  {
    NL: "Bron",
    EN: "Source",
    FR: "Source",
  },
  {
    NL: "Welke voordelen biedt het Ubicopia® platform dankzij deze architectuur ?",
    EN: "What advantages does the Ubicopia® platform offer thanks to this architecture?",
    FR: "Quels avantages la plateforme Ubicopia® offre-t-elle grâce à cette architecture ?",
  },
  {
    NL: "Schaalbaarheid",
    EN: "Scalability",
    FR: "Évolutivité",
  },
  {
    NL: "Doordat de backend is losgekoppeld, is deze makkelijk op te schalen.",
    EN: "Because the backend is disconnected, it is easy to scale up.",
    FR: "Étant donné que le backend est déconnecté, il est facile de l'adapter.",
  },
  {
    NL: "Het aantal storefronts is in pricipe onbeperkt.",
    EN: "The number of storefronts is in principle unlimited.",
    FR: "Le nombre de vitrines est en principe illimité.",
  },
  {
    NL: "Dankzij de samenwerking met AWS (Amazon Web Services):",
    EN: "Due to the cooperation with AWS (Amazon Web Services):",
    FR: "Grâce à la coopération avec AWS (Amazon Web Services) :",
  },
  {
    NL: "kan het aantal servers snel worden verhoogd",
    EN: "the number of servers can be quickly increased",
    FR: "le nombre de serveurs peut être rapidement augmenté",
  },
  {
    NL: "kan het type server snel worden aangepast",
    EN: "the type of server can be changed quickly",
    FR: "le type de serveur peut être changé rapidement",
  },
  {
    NL: "is Ubicopia® overal ter wereld inzetbaar, dit door locale serverknooppunten in te stellen.",
    EN: "can Ubicopia® be deployed anywhere in the world by setting up local server nodes.",
    FR: "Ubicopia® peut être déployé partout dans le monde en mettant en place des nœuds de serveurs locaux.",
  },
  {
    NL: "zijn er dagelijkse backups.",
    EN: "are there daily backups.",
    FR: "y a-t-il des sauvegardes quotidiennes.",
  },
  {
    NL: "biedt Ubicopia® een hoge betrouwbaarheid.",
    EN: "Ubicopia® offers high reliability.",
    FR: "Ubicopia® offre une grande fiabilité.",
  },
  {
    NL: "zijn alle upgrades makkelijk uit te voeren zonder de klant iets merkt",
    EN: "all upgrades are easy to perform without the customer noticing",
    FR: "toutes les mises à niveau sont faciles à effectuer sans que le client s'en aperçoive",
  },
  {
    NL: "Snelheid",
    EN: "Speed",
    FR: "Vitesse",
  },
  {
    NL: "Niet enkel de snelheid van de storefront, maar ook de implementatiesnelheid.",
    EN: "Not only the speed of the storefront, but also the implementation speed.",
    FR: "Non seulement la vitesse de la vitrine, mais aussi la vitesse de mise en œuvre.",
  },
  {
    NL: "Onmiddellijk na registratie kan u immers uw verkoopkanaal configureren. Zelfs al hebt u nog geen website.",
    EN: "You can configure your sales channel immediately after registration. Even if you don't have a website yet.",
    FR: "Vous pouvez configurer votre canal de vente immédiatement après l'inscription. Même si vous n'avez pas encore de site Web.",
  },
  {
    NL: "Door locale servers in te zetten, komt dit de verwerkingssnelheid ten goede.",
    EN: "By using local servers, the processing speed improves.",
    FR: "En utilisant des serveurs locaux, la vitesse de traitement s'améliore.",
  },
  {
    NL: "Flexibiliteit",
    EN: "Flexibility",
    FR: "La flexibilité",
  },
  {
    NL: "Backups gebeuren ongemerkt.",
    EN: "Backups happen unnoticed.",
    FR: "Les sauvegardes passent inaperçues.",
  },
  {
    NL: "Upgrades gebeuren ongemerkt, en zonder verstoring van de dienstverlening.",
    EN: "Upgrades happen unnoticed, and without service disruption.",
    FR: "Les mises à niveau se produisent sans être remarquées et sans interruption de service.",
  },
  {
    NL: "Nieuwe technieken kunnen makkelijk worden toegevoegd. Deze kan u dan ook onmiddellijk gebruiken.",
    EN: "New technologies can be easily added. You can also use these immediately.",
    FR: "De nouvelles technologies peuvent être facilement ajoutées. Vous pouvez également les utiliser immédiatement.",
  },
  {
    NL: "Efficiëntie",
    EN: "Efficiency",
    FR: "Efficacité",
  },
  {
    NL: "Doordat je verkoopkanaal veel sneller inzetbaar is, kan je hier sneller gebruik van maken.",
    EN: "Because your digital sales channel can be deployed much faster, you can use it faster.",
    FR: "Parce que votre canal de vente digital peut être déployé beaucoup plus rapidement, vous pouvez l'utiliser plus rapidement.",
  },
  {
    NL: "Het opzetten van een verkoopkanaal verloopt dankzij de Ubicopia® microShop vrijwel onmiddellijk. Dit is namelijk een template gebaseerd op de composable architectuur.",
    EN: "Setting up the digital sales channel is almost instant thanks to the Ubicopia® microShop. This is a template based on the composable architecture.",
    FR: "La mise en place du canal de vente numérique est quasi instantanée grâce au microShop Ubicopia®. Il s'agit d'un modèle basé sur l'architecture composable.",
  },
  {
    NL: "Dit biedt dan ook een onmiddellijk prijsvoordeel, doordat de volledige webshop reeds is uitgedacht.",
    EN: "This therefore offers an immediate price advantage, because the entire webshop has already been thought out.",
    FR: "Cela offre donc un avantage de prix immédiat, car toute la boutique en ligne a déjà été pensée.",
  },
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ HomeContactPage -------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Hoe kan u ons contacteren ?",
    EN: "How can you contact us ?",
    FR: "Comment nous contacter ?",
  },
  {
    NL: "Hebt u een specifieke vraag, dan kan u ons die gerust stellen via bijgaand formulier, email of telefoon.",
    EN: "If you have a specific question, feel free to ask us via the enclosed form, email or telephone.",
    FR: "Si vous avez une question spécifique, n'hésitez pas à nous la poser via le formulaire ci-joint, par e-mail ou par téléphone.",
  },
  {
    NL: "Vragen over Ubicopia®",
    EN: "Questions about Ubicopia®",
    FR: "Questions sur Ubicopia®",
  },
  {
    NL: "Maakt u gebruik van het formulier, omschrijf dan goed uw zaak, uw product en/of uw specifieke vraag.",
    EN: "If you use the form, please describe your business, your product and/or your specific question as good as possible.",
    FR: "Si vous utilisez le formulaire, veuillez décrire votre entreprise, votre produit et/ou votre question spécifique aussi bien que possible.",
  },
  {
    NL: "We bekijken uw vraag en nemen zo snel als mogelijk contact op.",
    EN: "We will then review your request and contact you as soon as possible.",
    FR: "Nous étudierons alors votre demande et vous contacterons dans les plus brefs délais.",
  },
  {
    NL: "Alles is vrijblijvend.",
    EN: "Everything is without obligation.",
    FR: "Tout est sans engagement.",
  },
  {
    NL: "Voor vragen met een eerder bedrijfstechnisch karakter.",
    EN: "For questions with a rather business character.",
    FR: "Pour les questions à caractère plutôt business.",
  },
  {
    NL: "Ubicopia® is een produkt van DRO Projects BV",
    EN: "Ubicopia® is a product of DRO Projects BV",
    FR: "Ubicopia® est un produit de DRO Projects BV",
  },
  {
    NL: "Consultancies",
    EN: "Consultancy",
    FR: "Conseils",
  },
  {
    NL: "Ook voor consultancies en vragen over digitalisatie, Saas, Headless, ... staan we open.",
    EN: "We are also open to consultations and questions about digitization, Saas, Headless, ....",
    FR: "Nous sommes également ouverts aux consultations et questions sur la digitalisation, le Saas, le Headless, ....",
  },
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------ HomeAboutPage ---------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------
  {
    NL: "Over ons",
    EN: "About us",
    FR: "À propos de nous",
  },
  {
    NL: "Ubicopia® is een initiatief van DRO Projects BV, gevestigd te Tienen, België.",
    EN: "Ubicopia® is an initiative of DRO Projects BV, located in Tienen, Belgium.",
    FR: "Ubicopia® est une initiative de DRO Projects BV, situé à Tirlemont, Belgique.",
  },
  {
    NL: "Het verhaal van Ubicopia®",
    EN: "The story of Ubicopia®",
    FR: "L'histoire d'Ubicopia®",
  },
  {
    NL: "Het idee voor Ubicopia® is langzaam gegroeid, en is ontstaan tijdens een Vlerick MBA-studie. Voor één van de vele papers werd het onderwerp over 2-zijdige platformen en e-commerce aangetikt.",
    EN: "The idea for Ubicopia® grew slowly, and originated during a Vlerick MBA study. For one of the many papers, the subject of 2-sided platforms and e-commerce was touched.",
    FR: "L'idée d'Ubicopia® s'est développée lentement et est née lors d'une étude Vlerick MBA. Pour l'un des nombreux articles, le sujet des plateformes bifaces et du commerce électronique a été abordé.",
  },
  {
    NL: "Onder de studiegenoten uiteraard ook enkele informatici, die als snel begonnen over de nieuwe trend, cloud, headless en API.",
    EN: "Among the fellow students, of course, also some computer scientists, who soon started talking about the new trend, cloud, headless and API.",
    FR: "Parmi les autres étudiants, bien sûr, aussi des informaticiens, qui ont rapidement commencé à parler de la nouvelle tendance, cloud, headless et API.",
  },
  {
    NL: "Als afstudeerwerk, destijds aan KIH Groep T Leuven, had ik een programma opgeleverd in Turbo Pascal. Het begintijdperk van Object Oriënted Programming. Vervolgens ben ik het coding-wereldje en de allerhande programmeertalen door de jaren heen vanop afstand blijven volgen.",
    EN: "As a graduation project, at the time at KIH Groep T Leuven, I had delivered a program in Turbo Pascal. The early days of Object Oriented Programming. I then continued to follow the coding world and all kinds of programming languages from a distance over the years.",
    FR: "En tant que projet de fin d'études, à l'époque au KIH Groep T Leuven, j'avais produit un programme en Turbo Pascal. Les débuts de la programmation orientée objet. J'ai ensuite continué à suivre le monde du codage et toutes sortes de langages de programmation à distance au fil des années.",
  },
  {
    NL: "Tot mijn verbazing kon ik het informatica-collega's nog vrij goed volgen in hun redenering.",
    EN: "To my surprise, I could still quite follow my computer science colleagues in their reasoning.",
    FR: "A ma grande surprise, je pouvais encore tout à fait suivre mes collègues informaticiens dans leur raisonnement.",
  },
  {
    NL: "Tijdens een sabbatical in 2014, had ik voor mijn activiteit al een website met webshop voor auto-onderdelen opgezet. Dit was destijds een helse klus, daar alles van nul moest worden opgebouwd, met allerhande plugins en componenten. Veelal hard gecodeerd.",
    EN: "During a sabbatical in 2014, I had already set up a website with a webshop for car parts for my activity. This was a hell of a job at the time, because everything had to be built from scratch, with all kinds of plugins and components. Usually hard coded.",
    FR: "Lors d'un congé sabbatique en 2014, j'avais déjà mis en place un site internet avec une boutique en ligne de pièces automobiles pour mon activité. C'était un sacré travail à l'époque, car tout devait être construit à partir de zéro, avec toutes sortes de plugins et de composants. Généralement codé en dur.",
  },
  {
    NL: "Door de nieuwere technieken, werd het al een stuk eenvoudiger. Het opzetten van een backend is natuurlijk ook nog een hele klus, maar dient slecht éénmalig te gebeuren. Door de eenvoud van het gebruik achteraf en de zéér grote mogelijkheden om de processen te automatiseren, dacht ik - bestond dit destijds maar toen ik mijn eigen webshop had opgezet.",
    EN: "The newer techniques have made it a lot easier. Setting up a backend is of course also quite a job, but should only be done once. Due to the simplicity of the use afterwards and the very great possibilities to automate the processes, I thought - this only existed at the time when I had set up my own webshop.",
    FR: "Les nouvelles techniques ont rendu cela beaucoup plus facile. La mise en place d'un backend est bien sûr aussi tout un travail, mais ne doit être fait qu'une seule fois. En raison de la simplicité d'utilisation par la suite et des très grandes possibilités d'automatisation des processus, j'ai pensé que cela n'existait qu'à l'époque où j'avais créé ma propre boutique en ligne.",
  },
  {
    NL: "En het idee voor UbiCopia® was geboren.",
    EN: "And the idea for UbiCopia® was born.",
    FR: "Et l'idée d'UbiCopia® est née.",
  },
  {
    NL: "Elke zelfstandige met een kleinhandel kan nu een eigen webshop opzetten.",
    EN: "Every self-employed person with a retail business can now set up their own webshop.",
    FR: "Chaque indépendant ayant un commerce de détail peut désormais créer sa propre boutique en ligne.",
  },
  {
    NL: "Ubi Copia = Latijn voor 'Waar is de winkel ?'",
    EN: "Ubi Copia = Latin for 'Where is the shop?'",
    FR: "Ubi Copia = latin pour 'Où est le magasin ?'",
  },
  {
    NL: "Het bedrijf",
    EN: "The company",
    FR: "L'entreprise",
  },
  {
    NL: "Ubicopia® is een initiatief van DRO Projects BV, gevestigd te Tienen, België",
    EN: "Ubicopia® is an initiative of DRO Projects BV, established in Tienen, Belgium",
    FR: "Ubicopia® est une initiative de DRO Projects BV, établie à Tirlemont, Belgique",
  },
  {
    NL: "DRO Projects werd in 2014 opgericht tijdens een uit de hand gelopen sabbatical.",
    EN: "DRO Projects was founded in 2014 during a sabbatical that got out of control.",
    FR: "DRO Projects a été fondé en 2014 lors d'un congé sabbatique qui a dérapé.",
  },
  {
    NL: "Het eerste project van DRO Projects, en dan ook de reden van de oprichting was een workshop voor klassieke wagens. Oldtimerfabriek. Indien u eigenaar bent van een vintage Porsche of Austin Healey, zou het wel eens kunnen dat we elkaar kennen.",
    EN: "The first project of DRO Projects, and the reason for its establishment, was a workshop for classic cars. 'Oldtimerfabriek'. If you own a vintage Porsche or Austin Healey, we may know each other.",
    FR: "Le premier projet de DRO Projects, et la raison de sa création, était un atelier pour voitures classiques. 'Oldtimerfabriek'. Si vous possédez une Porsche ou une Austin Healey vintage, nous nous connaissons peut-être.",
  },
  {
    NL: "Oorspronkelijk was het de bedoeling deze activiteit een jaartje uit te oefenen. Echter door het onverwachte succes, en de bijbehorende wat grotere investeringen, werd het een project van 3 jaar, met een huurcontract voor een ruimte van 600 m2.",
    EN: "Originally, the intention was to practice this activity for a year. However, due to the unexpected success, and the associated somewhat larger investments, it became a 3-year project, with a lease for a space of 600 m2.",
    FR: "A l'origine, l'intention était de pratiquer cette activité pendant un an. Cependant, en raison du succès inattendu et des investissements un peu plus importants associés, il est devenu un projet de 3 ans, avec un bail pour un espace de 600 m2.",
  },
  {
    NL: "Vele klanten waren vol lof over het werk, maar niet zo over de lange wachttijden.",
    EN: "Many customers were full of praise for the work, but not so much for the long waiting times.",
    FR: "De nombreux clients n'ont pas tari d'éloges pour le travail, mais pas tant pour les longs délais d'attente.",
  },
  {
    NL: "Door het opzetten van een leuke website en een zéér welgerichte marketingcampagne, en veel mond aan mond reclame, hadden we al snel een lange wachttijd. Op sommige momenten bedroeg deze wachttijd zelfs 3 maanden.",
    EN: "By setting up a nice website and a very well-targeted marketing campaign, and a lot of word of mouth advertising, we soon had a long waiting time. At times this waiting time was even 3 months.",
    FR: "En mettant en place un joli site internet et une campagne marketing très bien ciblée, et beaucoup de bouche à oreille, nous avons vite eu une longue attente. Parfois, ce temps d'attente était même de 3 mois.",
  },
  {
    NL: "Na de 3 jaren, werd het bedrijf omgevormd tot een management consulting, en werden er enkele consultancy opdrachten uitgevoerd in de wereld van de emissietechnieken, met bedrijven zoals Bosal en Dr. Pley SCR Technology.",
    EN: "After the 3 years, the company was transformed into a management consulting, and some consultancy assignments were carried out in the world of emission engineering, with companies such as Bosal and Dr. Pley SCR Technology.",
    FR: "Au bout de 3 ans, l'entreprise s'est transformée en conseil en management, et quelques missions de conseil ont été réalisées dans le monde de l'ingénierie des émissions, avec des sociétés telles que Bosal et Dr. Pley SCR Technology.",
  },
  {
    NL: "Tijdens deze periode werd ook een MBA behaald aan de VlericK Business School te Gent, België.",
    EN: "During this period an MBA was also obtained at the VlericK Business School in Ghent, Belgium.",
    FR: "Au cours de cette période, un MBA a également été obtenu à la VlericK Business School de Gand, en Belgique.",
  },
  {
    NL: "Momenteel leggen we ons vooral toe op Saas projecten, doen we tussendoor nog wat consultancies en bekijken we de meeste dingen toch wat vanuit een andere, meer organisatorische, en nogal vanuit een out-of-the-box hoek.",
    EN: "At the moment we mainly focus on Saas projects, we do some consultancy in between and we look at most things from a different, more organizational, and rather out-of-the-box angle.",
    FR: "Pour le moment, nous nous concentrons principalement sur les projets Saas, nous faisons du conseil entre les deux et nous regardons la plupart des choses sous un angle différent, plus organisationnel et plutôt original.",
  },
  {
    NL: "De directe, no-nonsense approach van de oprichter is terug te vinden in de onverbloemde aanpak van de e-commerce, en is weerspiegelt in de to-the-point processen van de gebruikersinterface.",
    EN: "The founder's direct, no-nonsense approach is reflected in the straightforward approach to e-commerce, and is reflected in the to-the-point processes of the user interface.",
    FR: "L'approche directe et pragmatique du fondateur se reflète dans l'approche simple du commerce électronique et se reflète dans les processus directs de l'interface utilisateur.",
  },
  {
    NL: "Het team",
    EN: "The team",
    FR: "L'équipe",
  },
  {
    NL: "klanten",
    EN: "customers",
    FR: "clients",
  },
  {
    NL: "alle",
    EN: "all",
    FR: "tous",
  },
  {
    NL: "aandeelhouders",
    EN: "shareholders",
    FR: "actionnaires",
  },
  {
    NL: "hersencellen",
    EN: "brain cells",
    FR: "les cellules du cerveau",
  },
  {
    NL: "katachtige vrienden",
    EN: "feline friends",
    FR: "amis félins",
  },
  {
    NL: "Bij Ubicopia® levert elk team member zijn bijdrage.",
    EN: "At Ubicopia®, every team member makes a contribution.",
    FR: "Chez Ubicopia®, chaque membre de l'équipe apporte sa contribution.",
  },
  {
    NL: "Hetzij een financiële bijdrage, intelligentie, sweat-equity of 'fur'ious.",
    EN: "Either a financial contribution, intelligence, sweat equity or 'fur'ious.",
    FR: "Soit un apport financier, par l'intelligence, de la sueur équité et 'fur'ieux'.",
  },
  {
    NL: "Ook onze klanten maken integraal deel uit van het team, door een tekort aan hersencellen steunen we immers voor een groot deel op de feedback die we van onze klanten ontvangen !",
    EN: "Our customers are also part of the team, because due to a shortage of brain cells, we largely rely on the feedback we receive from our customers!",
    FR: "Nos clients font également partie de l'équipe, car en raison d'une pénurie de cellules cérébrales, nous nous fions largement aux retours que nous recevons de nos clients !",
  },
  {
    NL: "Onderdeel van de algemene voorwaarden is het herroepingsrecht, art.20.",
    EN: "Part of the general terms and conditions is the right of withdrawal, art.20.",
    FR: "Une partie des conditions générales est le droit de rétractation, art.20.",
  },
  {
    NL: "Je kan binnen een termijn van 14 dagen na activatie, kosteloos afzien van je aankoop, mits het een standaardproduct betreft zonder personalisatie.",
    EN: "You can cancel your purchase free of charge within 14 days after activation, provided it concerns a standard product without personalization.",
    FR: "Vous pouvez annuler gratuitement votre achat dans les 14 jours suivant l'activation, à condition qu'il s'agisse d'un produit standard sans personnalisation.",
  },
  {
    NL: "Je ontvangt een bevestiging van je bestelling per email, op het opgegeven email adres.",
    EN: "You will receive a confirmation of your order by email at the specified email address.",
    FR: "Vous recevrez une confirmation de votre commande par email à l'adresse email indiquée.",
  },
  {
    NL: "",
    EN: "",
    FR: "",
  },
];
