import React from "react";

import HomeCarousel from "./HomeCarousel";
import TL from "../TL";

const HomeTitle = () => {
  return (
    <div className="row mb-3">
      <div className="col-lg-6 z-1">
        <div className="titleleft boxolive text-center z-1 p-3">
          <div>
            <div className="text-black w-100 text-center pt-2 pb-2 display-5">
              <h1 className="display-5 fw-bold text-white z-5">
                <TL text="De online partner voor de kleinhandel" />
              </h1>
            </div>
            <p className="">
              <TL text="Ubicopia® is de alles-in-één tool voor de zelfstandige die een eigen webwinkel wil opstarten." />
            </p>
            <p>
              <TL text="UbiCopia® is gericht op de kleinhandel, die op een eenvoudige en efficiënte wijze online activiteiten met e-commerce wil opstarten." />
            </p>
            <p>
              <TL text="UbiCopia® is een digitaal initiatief om de mensen ertoe aan te zetten lokaal te kopen." />
            </p>
          </div>
        </div>
        <p className="text-center text-black my-2 display-5 w-75 fw-bold ms-5">
          <TL text="Simpel, Snel, en efficient." />
        </p>
      </div>
      <div className="col-lg-6 titleright mx-auto align-items-center">
        <HomeCarousel />
      </div>
    </div>
  );
};

export default HomeTitle;
