import React from "react";
import { Helmet } from "react-helmet";
import TL from "../Components/TL";
import { keywordsabout } from "../Data/keywordsabout";

import idea from "../Media/idea.webp";
import passion from "../Media/passion.webp";

const HomeAboutPage = () => {
  return (
    <div className="aboutpage">
      <Helmet>
        <title>Ubicopia® - Over ons</title>
        <meta
          name="description"
          content="Lees meer over het ontstaan van Ubicopia®, en het bedrijf en de personen achter de schermen. Ubicopia® is een product van DRO Projects bv."
        />
        <meta name="twitter:title" content="Ubicopia® - Over ons" />
        <meta
          name="twitter:description"
          content="Lees meer over het ontstaan van Ubicopia®, en het bedrijf en de personen achter de schermen. Ubicopia® is een product van DRO Projects bv."
        />
        <meta name="keywords" content={keywordsabout} />
        <link rel="canonical" href="https://ubicopia.be/about/" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <section>
        <div className="container bg-secondary mb-5">
          <div className="row px-4 py-5 mt-5 text-center boxtransp text-white">
            <h1 className="text-white display-4 fw-bold">
              <TL text="Over ons" />
            </h1>
            <div className="col-lg-6 mx-auto">
              <p className="lead mb-4">
                <TL text="Ubicopia® is een initiatief van DRO Projects BV, gevestigd te Tienen, België." />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container bg-secondary">
          <div className="px-4 py-5 text-center">
            <h2 className="display-6 fw-bold text-white">
              <TL text="Het verhaal van Ubicopia®" />
            </h2>
            <div className="row mt-5">
              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <img
                  src={idea}
                  width="490"
                  height="734"
                  style={{ width: "80%", height: "auto" }}
                  alt="Ubicopia - the story"
                />
              </div>

              <div className="col-lg-6 mx-auto text-white lead text-center p-4">
                <div className="fst-italic ms-3">
                  <p>
                    <span className="fs-2">"</span>
                    <TL text="Het idee voor Ubicopia® is langzaam gegroeid, en is ontstaan tijdens een Vlerick MBA-studie. Voor één van de vele papers werd het onderwerp over 2-zijdige platformen en e-commerce aangetikt." />
                  </p>
                  <p>
                    <TL text="Onder de studiegenoten uiteraard ook enkele informatici, die als snel begonnen over de nieuwe trend, cloud, headless en API." />
                  </p>
                  <p className="ms-5">
                    <TL text="Als afstudeerwerk, destijds aan KIH Groep T Leuven, had ik een programma opgeleverd in Turbo Pascal. Het begintijdperk van Object Oriënted Programming. Vervolgens ben ik het coding-wereldje en de allerhande programmeertalen door de jaren heen vanop afstand blijven volgen." />
                  </p>
                  <p>
                    <TL text="Tot mijn verbazing kon ik het informatica-collega's nog vrij goed volgen in hun redenering." />
                  </p>
                  <p>
                    <TL text="Tijdens een sabbatical in 2014, had ik voor mijn activiteit al een website met webshop voor auto-onderdelen opgezet. Dit was destijds een helse klus, daar alles van nul moest worden opgebouwd, met allerhande plugins en componenten. Veelal hard gecodeerd." />
                  </p>
                  <p>
                    <TL text="Door de nieuwere technieken, werd het al een stuk eenvoudiger. Het opzetten van een backend is natuurlijk ook nog een hele klus, maar dient slecht éénmalig te gebeuren. Door de eenvoud van het gebruik achteraf en de zéér grote mogelijkheden om de processen te automatiseren, dacht ik - bestond dit destijds maar toen ik mijn eigen webshop had opgezet." />
                  </p>
                  <p>
                    <TL text="En het idee voor UbiCopia® was geboren." />
                  </p>
                  <p className="fw-bold">
                    <TL text="Elke zelfstandige met een kleinhandel kan nu een eigen webshop opzetten." />
                    <span className="fs-2">"</span>
                  </p>
                </div>
                <div className="text-end">
                  <p>----------------- Dominique Rongé</p>
                </div>
                <p>
                  <TL text="Ubi Copia = Latijn voor 'Waar is de winkel ?'" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5">
        <div className="container bg-secondary">
          <div className="px-4 py-5 text-center">
            <h2 className="display-6 fw-bold text-white mb-4">
              <TL text="Het bedrijf" />
            </h2>
            <div className="row d-flex flex-row-reverse">
              <div className="col-lg-6 d-flex align-items-center justify-content-center">
                <img
                  src={passion}
                  width="490"
                  height="327"
                  style={{ width: "80%", height: "auto" }}
                  alt="the company - DRO Projects"
                />
              </div>
              <div className="col-lg-6 p-3">
                <div className="mx-auto text-white lead">
                  <p>
                    <TL text="Ubicopia® is een initiatief van DRO Projects BV, gevestigd te Tienen, België" />
                  </p>
                  <p>
                    <TL text="DRO Projects werd in 2014 opgericht tijdens een uit de hand gelopen sabbatical." />
                  </p>
                  <p>
                    <TL text="Het eerste project van DRO Projects, en dan ook de reden van de oprichting was een workshop voor klassieke wagens. Oldtimerfabriek. Indien u eigenaar bent van een vintage Porsche of Austin Healey, zou het wel eens kunnen dat we elkaar kennen." />
                  </p>
                  <p>
                    <TL text="Oorspronkelijk was het de bedoeling deze activiteit een jaartje uit te oefenen. Echter door het onverwachte succes, en de bijbehorende wat grotere investeringen, werd het een project van 3 jaar, met een huurcontract voor een ruimte van 600 m2." />
                  </p>
                  <p>
                    <TL text="Vele klanten waren vol lof over het werk, maar niet zo over de lange wachttijden." />
                  </p>
                  <p>
                    <TL text="Door het opzetten van een leuke website en een zéér welgerichte marketingcampagne, en veel mond aan mond reclame, hadden we al snel een lange wachttijd. Op sommige momenten bedroeg deze wachttijd zelfs 3 maanden." />
                  </p>
                </div>
                <div className="mx-auto text-white lead">
                  <p>
                    <TL text="Na de 3 jaren, werd het bedrijf omgevormd tot een management consulting, en werden er enkele consultancy opdrachten uitgevoerd in de wereld van de emissietechnieken, met bedrijven zoals Bosal en Dr. Pley SCR Technology." />
                  </p>
                  <p>
                    <TL text="Tijdens deze periode werd ook een MBA behaald aan de VlericK Business School te Gent, België." />
                  </p>
                </div>
                <div className="mx-auto text-white lead">
                  <p>
                    <TL text="Momenteel leggen we ons vooral toe op Saas projecten, doen we tussendoor nog wat consultancies en bekijken we de meeste dingen toch wat vanuit een andere, meer organisatorische, en nogal vanuit een out-of-the-box hoek." />
                  </p>
                  <p>
                    <TL text="De directe, no-nonsense approach van de oprichter is terug te vinden in de onverbloemde aanpak van de e-commerce, en is weerspiegelt in de to-the-point processen van de gebruikersinterface." />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="my-5">
        <div className="container bg-secondary">
          <div className="px-4 py-5 text-center">
            <h2 className="display-6 fw-bold text-white">
              <TL text="Het team" />
            </h2>
            <div className="my-5 mx-auto text-white fs-3">
              <div className="row">
                <div className="col-md-3 bg-dark d-flex-col justify-content-center align-items-center py-4">
                  <div className="text-center">
                    <TL text="klanten" />
                  </div>
                  <div className="text-center">
                    <span className="fs-3 fw-bold">
                      <TL text="alle" />
                    </span>
                  </div>
                </div>
                <div className="col-md-3 bg-dark d-flex-col justify-content-center align-items-center py-4">
                  <div className="text-center">
                    <TL text="aandeelhouders" />
                  </div>
                  <div className="text-center fs-3 fw-bold">
                    <span className="fs-3 fw-bold">2</span>
                  </div>
                </div>
                <div className="col-md-3 bg-dark d-flex-col justify-content-center align-items-center py-4">
                  <div className="text-center">
                    <TL text="hersencellen" />
                  </div>
                  <div className="text-center fs-3 fw-bold">
                    <span className="fs-3 fw-bold">?</span>
                  </div>
                </div>
                <div className="col-md-3 bg-dark d-flex-center justify-content-center align-items-center py-4">
                  <div className="text-center">
                    <TL text="katachtige vrienden" />
                  </div>
                  <div className="text-center fs-3 fw-bold">
                    <span className="fs-3 fw-bold">2</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-5 mx-auto text-white lead">
              <div className="my-1">
                <TL text="Bij Ubicopia® levert elk team member zijn bijdrage." />
              </div>
              <div>
                <TL text="Hetzij een financiële bijdrage, intelligentie, sweat-equity of 'fur'ious." />
              </div>
              <div className="my-1">
                <TL text="Ook onze klanten maken integraal deel uit van het team, door een tekort aan hersencellen steunen we immers voor een groot deel op de feedback die we van onze klanten ontvangen !" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeAboutPage;
