import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import TL from "../Components/TL";
import BackToCases from "../Components/BackToCases";
import HomeInteraction from "../Components/Home/HomeInteraction";

import online from "../Media/online-background.webp";
import ownshop from "../Media/ownshop2.webp";

const CaseWebShop = () => {
  useEffect(() => {
    document.getElementById("start").scrollIntoView({ behavior: "smooth" });
  }, []);
  const webshopstyle = {
    backgroundImage: `url(${online})`,
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    filterBrightness: "70%",
  };

  return (
    <div className="casepage" id="start">
      <Helmet>
        <title>Uw webshop - simpel, snel en efficiënt</title>
        <meta
          name="description"
          content="Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta
          name="twitter:title"
          content="Webwinkel - Simpel, snel en efficiënt"
        />
        <meta
          name="twitter:description"
          content="Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta
          name="keywords"
          content="webwinkel starten, webshop starten, webwinkel software, webshop software, webwinkel systeem, webshop systeem, online winkel starten, e-commerce starten"
        />
        <link rel="canonical" href="https://ubicopia.be/cases/webshop/" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <section style={webshopstyle}>
        <div className="container mb-3">
          <BackToCases />
        </div>
        <div className="container boxcornsilk" id="title">
          <div className="row d-flex p-3">
            <div className="col-lg-6 d-flex justify-content-center align-items-center p-3 my-3 bg-dark">
              <div className="text-white p-3">
                <h1>
                  <TL text="Ubicopia® als een normale webshop" />
                </h1>
                <p>
                  <TL text="Ubicopia® levert alle mogelijkheden zoals een normale webshop. Maar gaat natuurlijk veel verder." />
                </p>
                <p>
                  <TL text="Ubicopia® biedt tal van voordelen voor het verhogen van de rendabiliteit van je zaak." />
                </p>
                <p>
                  <TL text="Online betalingen zijn mogelijk via de partnering met Worldline." />
                </p>
                <p>
                  <TL text="Via PostNL verzend je je pakketten naar je klanten." />
                </p>
                <div className="fs-1">
                  <TL text="Simpel, snel en efficiënt" />
                </div>
                <p>
                  <TL text="Hoe minder klikken, hoe beter." />
                </p>
                <p>
                  <TL text="Hoe sneller, hoe beter." />
                </p>
                <div className="fs-1">
                  <TL text="De Ubicopia® webwinkel is SEO klaar" />
                </div>
                <p>
                  <TL text="Gemeten met de Google Lighthouse tool ligt de SEO score steeds hoger dan 95%." />
                </p>
              </div>
            </div>
            <div className="col-lg-6 my-3 p-3 d-flex align-items-center">
              <img
                className="w-100 h-auto"
                src={online}
                alt="webwinkel voor slager"
                width="522"
                height="788"
              />
            </div>
          </div>
        </div>

        <div className="container boxtransp70 text-white p-5 my-5">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="text-start">
                <TL text="Net als een normale webshop" />
              </h2>
              <h3 className="fs-5">
                <TL text="Categoriën en subcategoriën" />
              </h3>
              <p className="ms-4">
                <TL text="Zoals het moet om je klanten wegwijs te maken,werk je met categoriën en subcategoriën." />
              </p>
              <p className="ms-4">
                <TL text="Alhoewel Ubicopia® specifiek voor de kleinhandel is ontwikkeld, is deze perfect inzetbaar als normale webshop. Dit biedt trouwens enkele belangrijke voordelen." />
              </p>
              <p className="ms-4">
                <TL text="Je definieert je producten in productgroepen, en subcategoriën. De klant ziet steeds waar hij is door de breadcrumb." />
              </p>
              <p className="ms-4">
                <TL text="Doordat deze op grote hoeveelheden is ingesteld, is deze storefront supersnel. Hiervoor wordt gebruik gemaakt van de buffer-technologie via AWS." />
              </p>
              <h3 className="fs-5">
                <TL text="Online betalen en verzenden" />
              </h3>
              <p className="ms-4">
                <TL text="Verzendingen kun je laten gebeurten via PostNL. De API-interface met PostNL is standaard voorzien." />
              </p>
              <p className="ms-4">
                <TL text="Online betalen kan via de interface met de Wordline Saferpay app." />
              </p>
              <h3 className="fs-5">
                <TL text="Online connecteren" />
              </h3>
              <p className="ms-4">
                <TL text="Samen met de orderflow wordt de klant naar wens geïnformeerd over de vooruitgang van zijn pakket." />
              </p>
              <h2 className="text-start">
                <TL text="Een efficiënte oplossing" />
              </h2>
              <h3 className="fs-5">
                <TL text="Het systeem werkt voor jou en organiseert je orderflow." />
              </h3>
              <p className="ms-4">
                <TL text="Doordat alles automatische en ordelijk gebeurt kan je op beide oren slapen terwijl je klant goed en duidelijk wordt geïnformeerd." />
              </p>
              <p className="ms-4">
                <TL text="Je definieert productgroepen die gebonden zijn aan levertijden, indien van toepassing. Het verzendmoment dat door de klant wordt gekozen houdt hiermee rekening." />
              </p>
              <p className="ms-4">
                <TL text="Het systeem bundelt de bestellingen per dag of verzendmoment, zo verhoog je je efficiëntie bij het verpakken." />
              </p>
              <p className="ms-4">
                <TL text="Je kan je klant ook laten ophalen op verschillende locaties met vrij te kiezen ophaalmomenten." />
              </p>
              <h3 className="fs-5">
                <TL text="SEO" />
              </h3>
              <p className="ms-4">
                <TL text="Belangrijk is dat je wordt gevonden. De webwinkels van Ubicopia® scoren hoog in SEO. We garanderen een SEO score >95%. Gemeten met de Lighthouse tool van Google." />
              </p>
            </div>
            <div className="col-lg-4 d-flex align-items-center justify-content-center">
              <div>
                <img
                  className="w-100 h-auto bg-white"
                  src={ownshop}
                  alt="Ubicopia® als webshop, simpel, snel en efficiënt"
                  width="332"
                  height="332"
                />
                <div>
                  <TL text="Start je eigen webshop met Ubicopia®" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <HomeInteraction />
        </div>

        <div className="container mt-5 boxtransp70 p-5 text-white">
          <div className="row">
            <h2>
              <TL text="Ubicopia® is vrij configureerbaar en biedt hiervoor een kant-en klare oplossing." />
            </h2>
            <ul className="list-group bg-dark">
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Je klanten kunnen online shoppen, hun bestelling afhandelen, hun lever- en facturatie adressen ingeven." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Online, per overschrijving of bij ophalen betalen." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="De verzending of afhaalmoment en locatie kiezen." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="De bestelling online opvolgen en de verzending traceren tot de levering." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="De online betalingen worden verzorgd door de partnering met Worldline. PostNl verzorgt de leveringen." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Hiervoor zijn geen plugins nodig, dit is ingebouwd in het Ubicopia® platform." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Het verzenden van de gepersonaliseerde emails om de klanten op de hoogte te houden van bvb betaling, verzending, orderverwerking, is standaard voorzien, en kan desgewenst worden in of uitgeschakeld." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Het versturen van HTML emails is gepersonaliseerd met jouw logo en persoonlijke aanspreking van jouw klant." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Orderbevestigingen en facturen worden automatisch aangemaakt, en volgens je ingestelde voorkeuren, al dan niet verstuurd naar de klant. Deze orderbevestigingen, en facturen gebruik je voor je boekhouding." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="De status van zijn bestelling is online door de klant te volgen." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Nieuwe producten kan je promoten, deze verschijnen automatisch op de homepage." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Je kan je klantenbestand downloaden, en hen mailings sturen (ingebouwd in Ubicopia, geen extra kosten !)" />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="De orderverwerking verloopt overzichtelijk, ook al zijn het er veel tegelijk." />
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="container mb-3">
          <BackToCases />
        </div>
      </section>
    </div>
  );
};

export default CaseWebShop;
