import React, { useState } from "react";

import TL from "./TL";

import ConfigProduct from "./Configurator/ConfigProduct";
import ConfigPakket from "./Configurator/ConfigPakket";
import ConfigOffer from "./Configurator/ConfigOffer";
import ConfigSignup from "./Configurator/ConfigSignup";

import "../configurator.css";

const Configurator = () => {
  const [prod, setProd] = useState("");
  const [pakket, setPakket] = useState("");
  const [accepted, setAccepted] = useState(false);

  const pricing = [
    {
      name: "pr1",
      sku: "pr1",
      description: "microShop-200",
      fixed: 0,
      monthly: 55,
    },
    {
      name: "pr2",
      sku: "pr2",
      description: "microShop-400",
      fixed: 0,
      monthly: 95,
    },
    {
      name: "pr3",
      sku: "pr3",
      description: "microShop-800",
      fixed: 0,
      monthly: 165,
    },
    {
      name: "pr4",
      sku: "pr4",
      description: "microShop-1000+",
      fixed: 0,
      monthly: 0,
    },
    {
      name: "pak1",
      sku: "pak1",
      description: "microShop",
      fixed: 0,
      monthly: 0,
    },
    {
      name: "pak2",
      sku: "pak2",
      description: "microSite",
      fixed: 750,
      monthly: 10.5,
    },
    {
      name: "pak3",
      sku: "pak3",
      description: "compoSite",
      fixed: 0,
      monthly: 0,
    },
  ];

  return (
    <>
      <div className="container configsection p-5" id="startsignup">
        {!accepted && (
          <div>
            <div className="">
              <div className="row">
                <h2>
                  <TL text="Ubicopia® product Configurator" />
                </h2>
                {prod === "" && (
                  <div className="mt-4">
                    <h3 className="fs-4">
                      <strong>
                        <TL text="Stap 1:" />
                      </strong>{" "}
                      <TL text="Kies hier de grootte van je webshop" />
                    </h3>
                    <p>
                      <TL text="Dit heeft te maken met het aantal server-interacties die nodig zijn, en de hoeveelheid data die wordt gebruikt. Hierop zijn immers onze kosten gebaseerd." />
                    </p>
                    <p>
                      <TL text="Hoe meer bestellingen, hoe lager de kost van de bestelling." />
                    </p>
                  </div>
                )}
                {prod !== "" && (
                  <p className="fs-4">
                    <strong>
                      <TL text="Stap 1 voltooid:" />
                    </strong>{" "}
                    <TL text="Je hebt gekozen voor volgend product" />
                  </p>
                )}
              </div>
              {/* <div className="d-flex flex-row flex-wrap"> */}
              <div className="row d-flex gx-5">
                {(prod === "pr1" || prod === "") && (
                  <div className="col-lg-3 border border-2 border-success">
                    <div className="text-center text-white">
                      <TL text="Meest gekozen" />
                    </div>
                    <ConfigProduct
                      title="Microshop-200"
                      id="pr1"
                      content={
                        <div className="">
                          <div className="d-flex align-items-center">
                            <div>
                              <div>
                                Dit is het basispakket, waar de meeste klanten
                                mee starten.
                              </div>
                              <p>200 producten en 200 bestellingen/m</p>
                            </div>
                          </div>

                          <p className="fw-bold">
                            €
                            {
                              pricing.filter((pr) => pr.name === "pr1")[0]
                                .monthly
                            }
                            /m
                          </p>
                        </div>
                      }
                      prod={prod}
                      setProd={setProd}
                    />
                  </div>
                )}

                <div className="col-lg-3 mt-4">
                  <ConfigProduct
                    title="Microshop-400"
                    id="pr2"
                    content={
                      <>
                        <p>
                          max 400 <TL text="producten" />
                        </p>
                        <p>
                          max 400 <TL text="bestellingen/m" />
                        </p>
                        <p className="fw-bold">
                          €
                          {pricing.filter((pr) => pr.name === "pr2")[0].monthly}
                          /m
                        </p>
                      </>
                    }
                    prod={prod}
                    setProd={setProd}
                  />
                </div>
                <div className="col-lg-3 mt-4">
                  <ConfigProduct
                    title="Microshop-800"
                    id="pr3"
                    content={
                      <>
                        <p>
                          max 800 <TL text="producten" />
                        </p>
                        <p>
                          max 800 <TL text="bestellingen/m" />
                        </p>
                        <p className="fw-bold">
                          €
                          {pricing.filter((pr) => pr.name === "pr3")[0].monthly}
                          /m
                        </p>
                      </>
                    }
                    prod={prod}
                    setProd={setProd}
                  />
                </div>
                <div className="col-lg-3 mt-4">
                  <ConfigProduct
                    title="Microshop-1000+"
                    id="pr4"
                    content={
                      <>
                        <p>
                          <TL text="meer dan 800 producten" />
                        </p>
                        <p>
                          <TL text="meer dan 800 bestellingen/m" />
                        </p>
                        <p className="fw-bold">
                          <TL text="prijs op aanvraag" />
                        </p>
                      </>
                    }
                    prod={prod}
                    setProd={setProd}
                  />
                </div>
              </div>
            </div>
            <div className="row d-flex mt-3">
              {pakket === "" && (
                <>
                  <h3 className="fs-4">
                    <strong>
                      <TL text="Stap 2:" />
                    </strong>{" "}
                    <TL text="Kies vervolgens hoe je de webshop wil presenteren en integreren" />
                  </h3>
                  <p>
                    <TL text="Hier is van belang of je al een website hebt of niet, en of je deze wenst te behouden. Kies dan voor het pakket MicroShop. Of je eventueel een volledig nieuwe website wil maken, met de standaard microShop, dan kies je het pakket MicroSite. Wil je volledig gebruik maken van alle mogelijkheden van de headless technologie, dan kies je pakket CompoSite." />
                  </p>
                </>
              )}
              {pakket !== "" && (
                <p className="fs-4">
                  <strong>
                    <TL text="Stap 2 voltooid:" />{" "}
                  </strong>
                  <TL text="Je hebt gekozen voor volgende integratie" />
                </p>
              )}
            </div>
            <div className="row d-flex">
              <ConfigPakket
                title="Ubicopia® microShop"
                id="pak1"
                content={
                  <>
                    <p>
                      <TL text="U hebt reeds een website." />
                    </p>
                    <p>
                      <TL text="Snelle integratie van de webshop." />
                    </p>
                    <p>
                      <TL text="Onmiddellijk van start." />
                    </p>
                    <p className="fw-bold">
                      <TL text="Geen meerkost." />
                    </p>
                  </>
                }
                pakket={pakket}
                setPakket={setPakket}
              />
              <ConfigPakket
                title="Ubicopia® microSite"
                id="pak2"
                content={
                  <>
                    <p>
                      <TL text="U krijgt een nieuwe eenvoudige, maar efficiënte website als landingpage." />
                    </p>
                    <div className="fw-bold">
                      <TL text="Eénmalig" /> : €
                      {pricing.filter((pr) => pr.name === "pak2")[0].fixed},-
                    </div>
                    <div className="fw-bold">
                      <TL text="Maandelijks" /> : €
                      {pricing.filter((pr) => pr.name === "pak2")[0].monthly}/m
                    </div>
                    <div>
                      <TL text="mailbox inbegrepen" />
                    </div>
                    <p>
                      <a
                        href="https://slagerij-traiteur-paco.be"
                        className="text-black"
                      >
                        naar voorbeeldsite klant
                      </a>
                    </p>
                  </>
                }
                pakket={pakket}
                setPakket={setPakket}
              />
              <ConfigPakket
                title="Ubicopia® compoSite"
                id="pak3"
                content={
                  <>
                    <p>
                      <TL text="Deze optie bestaat uit een volledige customized oplossing voor website en webshop." />
                    </p>
                    <p className="fw-bold">
                      <TL text="prijs op aanvraag" />
                    </p>
                  </>
                }
                pakket={pakket}
                setPakket={setPakket}
              />
            </div>

            <div className="w-100">
              {prod !== "" && pakket !== "" && (
                <>
                  <ConfigOffer
                    prod={prod}
                    pakket={pakket}
                    pricing={pricing}
                    setAccepted={setAccepted}
                  />
                </>
              )}
            </div>
          </div>
        )}
        {accepted && (
          <div className="w-100">
            <ConfigSignup
              prod={pricing.filter((pr) => pr.name === prod)[0]}
              pakk={pricing.filter((pr) => pr.name === pakket)[0]}
              setAccepted={setAccepted}
              setProd={setProd}
              setPakket={setPakket}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Configurator;
