import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import TL from "../Components/TL";
import BackToCases from "../Components/BackToCases";
import HomeInteraction from "../Components/Home/HomeInteraction";

import slager from "../Media/slager-background.webp";
import etalageslager from "../Media/etalage-slager.webp";
import newgeneration from "../Media/new-generation.webp";

const CaseSlager = () => {
  const slagerstyle = {
    backgroundImage: `url(${slager})`,

    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    filterBrightness: "70%",
  };

  useEffect(() => {
    document.getElementById("start").scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <div className="casepage" id="start">
      <Helmet>
        <title>Webwinkel voor slager</title>
        <meta
          name="description"
          content="Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta name="twitter:title" content="Webwinkel voor foodtrucks" />
        <meta
          name="twitter:description"
          content="Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta
          name="keywords"
          content="webwinkel voor slager, webshop voor slager, webwinkel software voor slager, webshop software voor slager, webwinkel systeem voor slager, webshop systeem voor slager, slager online, online slager"
        />
        <link rel="canonical" href="https://ubicopia.be/cases/slager/" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <section style={slagerstyle}>
        <div className="container">
          <BackToCases />
        </div>

        <div className="container boxcornsilk" id="title">
          <div className="row d-flex p-3">
            <div className="col-lg-5 d-flex justify-content-center align-items-center p-3 my-3 bg-dark">
              <div className="text-white p-3">
                <h1>
                  <TL text="Webwinkel voor de slager" />
                </h1>
                <p>
                  <TL
                    text="Ubicopia® biedt tal van voordelen voor het verhogen van de
                  rendabiliteit van je zaak."
                  />
                </p>
                <div className="fs-1">
                  <TL text="Verhoging Klantenbinding." />
                </div>
                <p>
                  <TL text="Je communiceert beter met je klantenbestand." />
                </p>
                <div className="fs-1">
                  <TL text="Efficiëntie" />
                </div>
                <p>
                  <TL text="Het systeem werkt voor jou en automatiseert vele van je taken." />
                </p>
                <p>
                  <TL text="Je verbetert je orderverwerking." />
                </p>
                <p>
                  <TL text="Het bestellen door de klant wordt voor een groot deel geautomatiseerd." />
                </p>
                <div className="fs-1">
                  <TL text="Omzetstijging" />
                </div>
                <p>
                  <TL text="Je zet in op een extra verkoopkanaal." />
                </p>
                <p>
                  <TL text="Je bereikt een nieuw type klant." />
                </p>
                <a href="https://slagerij-traiteur-paco.be">
                  Voorbeeld site klant Slagerij-Traiteur Paco
                </a>
              </div>
            </div>
            <div className="col-lg-6 my-3 p-3 d-flex align-items-center">
              <img
                className="w-100 h-auto"
                src={etalageslager}
                alt="webwinkel voor slager"
                width="538"
                height="358"
              />
            </div>
          </div>
        </div>

        <div className="container boxtransp70 text-white p-5 my-5">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="text-start">
                <TL text="De webwinkel verhoogt je klantenbinding" />
              </h2>
              <h3 className="fs-5">
                <TL text="Online marketing" />
              </h3>
              <p className="ms-4">
                <TL text="Vele zelfstandigen zijn reeds vertrouwd met het gebruik van facebook en hebben dikwijls 1000+ volgers." />
              </p>
              <p className="ms-4">
                <TL text="Nieuwe producten, uitzonderlijke sluitingen, speciale acties worden vie Facebook aan de klandizie meegedeeld. Dit is echter passieve communicatie." />
              </p>
              <p className="ms-4">
                <TL text="Met het Ubicopia® marketing-mail pakket, standaard inclusief, kan je mailen naar je klantenbestand en zo actief je klandizie informeren over je initiatief of nieuws." />
              </p>
              <h3 className="fs-5">
                <TL text="Online connecteren" />
              </h3>
              <p className="ms-4">
                <TL text="Ubicopia® geeft je ook de kans eventuele dringende mededelingen, bvb. wegenwerken, actief door te geven aan je klantenbestand om zo je bereikbaarheid te garanderen." />
              </p>
              <h2 className="text-start">
                <TL text="Je bekomt een verhoging van je efficiëntie" />
              </h2>
              <h3 className="fs-5">
                <TL text="Het systeem werkt voor jou en organiseert je orderflow." />
              </h3>
              <p className="ms-4">
                <TL text="Je kan zelf de hoeveelheid en duur van de tijdslots aangeven per dag van de week." />
              </p>
              <p className="ms-4">
                <TL text="Je definieert productgroepen die gebonden zijn aan levertijden." />
              </p>
              <p className="ms-4">
                <TL text="Indien je bijvoorbeeld 2 levermomenten hebt voor wild, en dit dient 2 dagen op voorhand besteld te worden. Met Ubicopia® kan je dit instellen en wordt dit mee aangegeven bij het shoppen en kiezen van het levermoment." />
              </p>
              <p className="ms-4">
                <TL text="Het systeem bundelt de bereidingen per leverdag of levermoment, zo verhoog je je efficiëntie bij de bereidingen." />
              </p>
              <h3 className="fs-5">
                <TL text="De webwinkel neemt een aantal taken over." />
              </h3>
              <p className="ms-4">
                <TL text="De winkelbediende wordt deels ontlast doordat er meer bestellingen online worden doorgegeven." />
              </p>
              <h2 className="text-start">
                <TL text="Verhoging van je omzet" />
              </h2>
              <h3 className="fs-5">
                <TL text="De online slager" />
              </h3>
              <p className="ms-4">
                <TL text="Je opent een nieuwe verkoopkanaal. Het is geweten dat de moderne consument meer en meer online gaat shoppen. Met een eigen webwinkel trek je de moderne consument aan, en verhoog je je omzet." />
              </p>
              <h3 className="fs-5">
                <TL text="Een ruimer aanbod" />
              </h3>
              <p className="ms-4">
                <TL text="In de winkel koopt de klant enkel wat er in de toonbank ligt. Op de website staan echter al je beschikbare producten en krijgt de klant je volledige aanbod te zien." />
              </p>
              <p className="ms-4">
                <TL text="De webwinkel geeft je ook de kans producten te verkopen vooraleer je deze aankoopt. Zo kan je je aanbod uitbreiden met een assortiment dat bvb. enkel op bestelling te verkrijgen is." />
              </p>
            </div>
            <div className="col-lg-4">
              <img
                className="w-100 h-auto"
                src={newgeneration}
                alt="webwinkel voor slager, connecteer met de moderne consument"
                width="332"
                height="375"
              />
              <p className="ms-1">
                <TL text="Connecteren met de moderne consument" />
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <HomeInteraction />
        </div>

        <div className="container mt-5 boxtransp70 p-5 text-white">
          <div className="row">
            <h2>
              <TL text="Dankzij de configureerbare interface biedt Ubicopia® een kant-en-klare oplossing voor de kleinhandel." />
            </h2>
            <p>
              <strong>
                <TL text="Bovendien wordt via het pakket Ubicopia® microSite het verkoopkanaal en een eenvoudige landingpage samen aangeboden." />
              </strong>
            </p>
            <p>
              <a
                className="link-primary"
                href="https://demosite.ubicopia.be"
                aria-label="go to demosite of Ubicopia"
              >
                <TL text="Ga naar voorbeeld" />
                <i className="bi bi-arrow-right ms-3"></i>
              </a>
            </p>
            <ul className="list-group bg-dark">
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Door de partnering met Worldline kan de klant op voorhand betalen, zo verliezen we geen tijd meer bij het afhaalmoment." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="De afhaalmomenten kunnen vrij worden bepaald, en kan je verhogen naar het weekend toe, wanneer het drukker wordt. Zo kan je je personeel ontlasten en piekmomenten vermijden. Het werk kan gelijkmatiger worden verdeeld." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Bovendien kan je het maximum aantal bestellingen per timeslot ingeven, en een levertijd, in uren of dagen, hanteren, zodat je ruim de tijd hebt om voor voldoende ingrediënten te zorgen." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Via gepersonaliseerde emails wordt de klant op de hoogte gehouden van de voortgang van zijn bestelling." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Wat met het persoonlijke contact - bij het verwerken zie je bij elke bestelling de naam en contactgegevens, door hierop te klikken bel je automatisch (Google Android), of start je een email op. Zo blijf je steeds in nauw contact met de klant, en kan je hem/haar snel contacteren wanneer er iets niet duidelijk is." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Ubicopia® voorziet de mogelijkheid HTML mails te versturen naar je klanten. Zo informeer je je volledige klantenbestand in één klap !" />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Door de makkelijke overzichten voor de boekhouding en de orderverwerking win je al snel veel tijd en kan je je focussen op je zaak en je leven, ipv administratie." />
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="container">
          <BackToCases />
        </div>
      </section>
    </div>
  );
};

export default CaseSlager;
