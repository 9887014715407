import React from "react";
import TL from "../TL";

import car_bakery from "../../Media/Carousel/car-bakery.webp";
import car_broodjes from "../../Media/Carousel/car-broodjes.webp";
import car_etalage_bakker from "../../Media/Carousel/car-etalage-bakker.webp";
import car_etalage_slager from "../../Media/Carousel/car-etalage-slager.webp";
import car_foodtruck from "../../Media/Carousel/car-foodtruck.webp";
import car_frituur from "../../Media/Carousel/car-frituur.webp";

const HomeCarousel = () => {
  return (
    <div
      id="carouselExampleAutoplaying"
      className="carousel slide z-0 carouselbox"
      data-bs-ride="carousel"
    >
      <div className="carousel-inner z-0">
        <div className="carousel-item active z-0">
          <img
            src={car_bakery}
            className="d-block carimage"
            alt="webwinkel voor bakker"
            width="632"
            height="421"
          />
          <div className="carousel-caption d-block boxtransp70 p-2">
            <h3>
              <TL text="Webwinkel voor de bakker" />
            </h3>
            <p className="d-none d-sm-block">
              <TL text="Ideaal als webshop tool voor bakkers." />
            </p>
            <p className="d-none d-sm-block">
              <TL text="Buitengewoon handig voor meerdere winkels of ophaalpunten." />
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={car_broodjes}
            className="d-block carimage"
            alt="broodjeszaak"
            width="632"
            height="421"
          />
          <div className="carousel-caption d-block boxtransp70 p-2">
            <h3>
              <TL text="Besteltool en webwinkel voor de broodjeszaak" />
            </h3>
            <p className="d-none d-sm-block">
              <TL text="Jouw (zakelijke) klanten bestellen op voorhand, betalen en factureren verloopt volledig automatisch." />
            </p>
            <p className="d-none d-sm-block">
              <TL text="Een overzichtelijke orderverwerking met afhaaltimer laat alles ordelijk verlopen." />
            </p>
          </div>
        </div>
        <div className="carousel-item z-0">
          <img
            src={car_etalage_bakker}
            className="d-block carimage"
            alt="bakker"
            width="632"
            height="421"
          />
          <div className="carousel-caption d-block boxtransp70 p-2">
            <h3>
              <TL text="Besteltool en webwinkel voor de detailhandel - traiteur" />
            </h3>
            <p className="d-none d-sm-block">
              <TL text="Alles-in-één tool voor bij feestdagen - overzichtelijke orderverwerking voor de vele bestellingen." />
            </p>
            <p className="d-none d-sm-block">
              <TL text="Instelbare hoeveelheid bestellingen per afhaalslot om de werklast te verdelen." />
            </p>
          </div>
        </div>
        <div className="carousel-item z-0">
          <img
            src={car_etalage_slager}
            className="d-block carimage"
            alt="slager"
            width="632"
            height="421"
          />
          <div className="carousel-caption d-block boxtransp70 p-2">
            <h3>
              <TL text="Besteltool en webwinkel voor de slager - traiteur" />
            </h3>
            <p className="d-none d-sm-block">
              <TL text="Bestellen per gewicht, opties voor aantal personen per schotel, samenstellen gerechten, ..." />
            </p>
            <p className="d-none d-sm-block">
              <TL text="Overzichtelijke orderverwerking - verdeling van afhaalmomenten om de vele bestellingen bij piekmomenten aan te kunnen." />
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={car_foodtruck}
            className="d-block carimage"
            alt="foodtruck"
            width="632"
            height="421"
          />
          <div className="carousel-caption d-block boxtransp70 p-2">
            <h3>
              <TL text="Besteltool en afhaaltool voor de foodtruck" />
            </h3>
            <p className="d-none d-sm-block">
              <TL text="Voor warme en verse bereidingen wordt de afhaaltimer met email of sms gebruikt." />
            </p>
            <p className="d-none d-sm-block">
              <TL text="Zelf te definiëren afhaalslots en aantal bestellingen per slot." />
            </p>
          </div>
        </div>
        <div className="carousel-item">
          <img
            src={car_frituur}
            className="d-block carimage"
            alt="frituur"
            width="632"
            height="421"
          />
          <div className="carousel-caption d-block boxtransp70 p-2">
            <h3>
              <TL text="Besteltool en afhaaltool voor de frituur" />
            </h3>
            <p className="d-none d-sm-block">
              <TL text="Voor warme en verse bereidingen wordt de timer met email of sms gebruikt." />
            </p>
            <p className="d-none d-sm-block">
              <TL text="Zelf te definiëren afhaalslots. Weg met wachttijd." />
            </p>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">
          <TL text="Previous" />
        </span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleAutoplaying"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">
          <TL text="Next" />
        </span>
      </button>
    </div>
  );
};

export default HomeCarousel;
