import React from "react";
import TL from "../TL";

const ConfigPakket = ({ title, id, content, pakket, setPakket }) => {
  return (
    <>
      {(pakket === id || pakket === "") && (
        <div className="col-lg-4 text-center text-white pakketbox mx-auto">
          <h4 className="prodpaktitle">{title}</h4>
          <div className="pakketmidfield">{content}</div>
          {pakket === "" && (
            <button
              className="btn btn-success btn-sm border"
              aria-label={title}
              onClick={() => setPakket(id)}
            >
              <TL text="Selecteer" />
            </button>
          )}

          {pakket !== "" && (
            <button
              className="btn btn-secondary btn-sm border"
              aria-label="maak ongedaan"
              onClick={() => setPakket("")}
            >
              <TL text="Maak ongedaan" />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default ConfigPakket;
