import React from "react";
import { Helmet } from "react-helmet";
import TL from "../Components/TL";
import { keywordstech } from "../Data/keywordstech";

import server from "../Media/server.webp";
import Ubicopia_architecture from "../Media/Ubicopia_architecture.webp";
import "../tech.css";

const HomeTechPage = () => {
  const styleTech = {
    backgroundImage: `url(${server})`,
    height: "600px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    position: "bottom",
  };
  return (
    <div className="techpage">
      <Helmet>
        <title>Ubicopia® - Technology</title>
        <meta
          name="description"
          content="Hier ontdekt u hoe Ubicopia® werkt en hoe het komt dat het zo snel, efficiënt en economisch voordelig is voor de gebruiker."
        />
        <meta name="twitter:title" content="Ubicopia® - Technology" />
        <meta
          name="twitter:description"
          content="Hier ontdekt u hoe Ubicopia® werkt en hoe het komt dat het zo snel, efficiënt en economisch voordelig is voor de gebruiker."
        />
        <meta name="keywords" content={keywordstech} />
        <link rel="canonical" href="https://ubicopia.be/technology/" />
        <meta name="robots" content="index,follow" />
      </Helmet>

      <section className="darksection py-5 techtopsection" style={styleTech}>
        <div className="container">
          <div className="row boxtransp70 p-4">
            <div className="col-md-4 coverbox boxtransp d-flex align-items-center text-center">
              <div>
                <div className="text-white display-5 fw-bold">
                  <TL text="E-Commerce was nooit zo makkelijk. Hoe kan dit ?" />
                </div>
              </div>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-6 coverbox boxhigh boxburly text-center">
              <div>
                <h1>
                  <TL text="Ubicopia is Saas gebaseerd, werkt headless en is composable." />
                </h1>
                <p className="fs-3">
                  <TL text="Wat wil dit nou zeggen ?" />
                </p>
                <p className="fs-3">
                  <TL text="Hoe werkt het Ubicopia® platform, en wat zijn de voordelen ?" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="section darksection text-white py-5">
        <div className="container text-center">
          <h2 className="fs-1 text-white">
            <TL text="Ubicopia® architectuur" />
          </h2>
          <div className="px-4 py-5 my-5 text-center">
            <div className="row d-flex ">
              <div className="col-12 bg-light p-5 justify-content-center">
                <img
                  className="d-block mx-auto mb-4 architecture"
                  src={Ubicopia_architecture}
                  alt="Architectuur Ubicopia platform"
                  width="652"
                  height="462"
                />
              </div>
            </div>
          </div>
          <div className="mt-5 mb-5 d-flex flex-row row">
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <h3>
                <TL text="Saas uitgelegd" />
              </h3>
            </div>
            <div className="col-md-8 ">
              <p className="my-4">
                <TL text="Software as a service, vaak afgekort als SaaS, ook weleens software on demand genoemd, is software die als een online dienst wordt aangeboden. De klant hoeft de software niet aan te schaffen, maar sluit bijvoorbeeld een contract per maand per gebruiker af, eventueel in combinatie met andere parameters. De SaaS-aanbieder zorgt voor installatie, onderhoud en beheer, de gebruiker benadert de software over het internet bij de SaaS-aanbieder." />
              </p>
              <a
                className="link-info"
                href="https://en.wikipedia.org/wiki/Software_as_a_service"
                aria-label="go to wikipedia reference page"
              >
                <TL text="Bron" />: Wikipedia
              </a>
            </div>
          </div>
          <hr></hr>
          <div className="row mt-5 mb-5 d-flex flex-row-reverse">
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <h3>Headless</h3>
            </div>
            <div className="col-md-8 ">
              <p className="my-4">
                <TL text="Traditionele websites hebben hun eigen back-end en front-end (grafische gebruikersinterface). Alle stukjes werken met dezelfde codebasis en communiceren rechtstreeks met elkaar, waardoor de website een geheel wordt. Bij een headless installatie is de front-end echter een op zichzelf staand stuk software, dat via API communiceert met een back-end. Beide delen werken los van elkaar, en kunnen zelfs op aparte servers geplaatst worden, waardoor een minimale versie van een multi-server architectuur ontstaat. De brug tussen beide delen is de API-client. De eindpunten van de API zijn met elkaar verbonden. De grootste voordelen van deze technologie zijn te vinden in prestatieoptimalisatie en flexibiliteit van de softwarestack." />
              </p>
              <a
                className="link-info"
                href="https://en.wikipedia.org/wiki/Headless_software"
                aria-label="go to wikipedia reference page"
              >
                <TL text="Bron" />: Wikipedia
              </a>
            </div>
          </div>
          <hr></hr>
          <div className="row mt-5 mb-5">
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <h3>Composable</h3>
            </div>
            <div className="col-md-8 ">
              <p className="my-4">
                <TL text="Composable commerce is een strategie waarbij je je IT-architectuur inricht door verschillende microservices en best-practice tools te combineren in één applicatie. In plaats van een monolitische oplossing kies je voor een aanpak waarbij je best-of-breed businesscomponenten combineert (composed). Je knipt je applicatie dus op in losse componenten en microservices die samen de business componenten (Packaged Business Capabilities) verzorgen." />
              </p>
              <a
                className="link-info"
                href="https://www.frankwatching.com/archive/2021/11/17/composable-commerce-de-nieuwe-strategie-voor-online-innovatie/"
                aria-label="go to frankwatching reference page"
              >
                <TL text="Bron" />: Frankwatching: online trends, tips & tricks
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className="section lightsection">
        <div className="container bg-white p-4">
          <h2 className="text-center boxolive p-3">
            <TL text="Welke voordelen biedt het Ubicopia platform dankzij deze architectuur ?" />
          </h2>
          <div className="row mt-5 mb-5">
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <h3>
                <TL text="Schaalbaarheid" />
              </h3>
            </div>
            <div className="col-md-8 d-flex-col">
              <p>
                <strong>
                  <TL text="Doordat de backend is losgekoppeld, is deze makkelijk op te schalen." />
                </strong>
              </p>
              <p>
                <TL text="Het aantal storefronts is in pricipe onbeperkt." />
              </p>
              <div>
                <TL text="Dankzij de samenwerking met AWS (Amazon Web Services):" />
                <ul>
                  <li>
                    <TL text="kan het aantal servers snel worden verhoogd" />
                  </li>
                  <li>
                    <TL text="kan het type server snel worden aangepast" />
                  </li>
                  <li>
                    <TL text="is Ubicopia® overal ter wereld inzetbaar, dit door locale serverknooppunten in te stellen." />
                  </li>
                  <li>
                    <TL text="zijn er dagelijkse backups." />
                  </li>
                  <li>
                    <TL text="biedt Ubicopia® een hoge betrouwbaarheid." />
                  </li>
                  <li>
                    <TL text="zijn alle upgrades makkelijk uit te voeren zonder de klant iets merkt" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="row d-flex flex-row-reverse mt-5 mb-5">
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <h3>
                <TL text="Snelheid" />
              </h3>
            </div>
            <div className="col-md-8 d-flex-col">
              <ul>
                <li>
                  <p>
                    <TL text="Niet enkel de snelheid van de storefront, maar ook de implementatiesnelheid." />
                  </p>
                </li>
                <li>
                  <p>
                    <TL text="Onmiddellijk na registratie kan u immers uw verkoopkanaal configureren. Zelfs al hebt u nog geen website." />
                  </p>
                </li>
                <li>
                  <p>
                    <TL text="Door locale servers in te zetten, komt dit de verwerkingssnelheid ten goede." />
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <hr></hr>
          <div className="row mt-5 mb-5">
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <h3>
                <TL text="Flexibiliteit" />
              </h3>
            </div>
            <div className="col-md-8 d-flex-col">
              <ul>
                <li>
                  <p>
                    <TL text="Backups gebeuren ongemerkt." />
                  </p>
                </li>
                <li>
                  <p>
                    <TL text="Upgrades gebeuren ongemerkt, en zonder verstoring van de dienstverlening." />
                  </p>
                </li>
                <li>
                  <p>
                    <TL text="Nieuwe technieken kunnen makkelijk worden toegevoegd. Deze kan u dan ook onmiddellijk gebruiken." />
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <hr></hr>
          <div className="row d-flex flex-row-reverse mt-5 mb-5">
            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <h3>
                <TL text="Efficiëntie" />
              </h3>
            </div>
            <div className="col-md-8 d-flex-col">
              <ul>
                <li>
                  <p>
                    <TL text="Doordat je verkoopkanaal veel sneller inzetbaar is, kan je hier sneller gebruik van maken." />
                  </p>
                </li>
                <li>
                  <p>
                    <TL text="Het opzetten van een verkoopkanaal verloopt dankzij de Ubicopia® microShop vrijwel onmiddellijk. Dit is namelijk een template gebaseerd op de composable architectuur." />
                  </p>
                </li>
                <li>
                  <p>
                    <TL text="Dit biedt dan ook een onmiddellijk prijsvoordeel, doordat de volledige webshop reeds is uitgedacht." />
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeTechPage;
