import React from "react";
import TL from "./TL";

const LoginRegister = () => {
  var loginURL = process.env.REACT_APP_FRONTEND_URL + "/login";
  var signupURL = process.env.REACT_APP_FRONTEND_URL + "/signup";
  var configURL = "/products";
  return (
    <div className="loginregisterbuttons justify-content-end">
      <button className="btn btn-secondary me-3">
        <a
          className="text-decoration-none text-white"
          href={configURL}
          arial-label="link to configurator page"
        >
          <TL text="Configureer" />
        </a>
      </button>
      <button className="btn btn-primary me-3">
        <a
          className="text-decoration-none text-white"
          href={signupURL}
          arial-label="link to signup page"
        >
          <TL text="Registreer" />
        </a>
      </button>
      <button className="btn btn-success btn-sm" aria-label="login">
        <a
          className="text-decoration-none text-white"
          href={loginURL}
          arial-label="link to login page"
        >
          <TL text="Login" />
        </a>
      </button>
    </div>
  );
};

export default LoginRegister;
