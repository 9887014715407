import React, { useEffect, useState } from "react";
import ContactBlock from "../ContactBlock";
import TL from "../TL";

const ConfigOffer = ({ prod, pakket, pricing, setAccepted }) => {
  var fixed = 0;
  var monthly = 0;
  var today = new Date();
  const [totMonth, setTotMonth] = useState(null);
  const [totFixed, setTotFixed] = useState(null);

  useEffect(() => {
    fixed = 0;
    monthly = 0;
    if (prod !== "" && pakket !== "") {
      fixed =
        fixed +
        pricing.filter((pr) => pr.name === prod)[0].fixed +
        pricing.filter((pr) => pr.name === pakket)[0].fixed;
      monthly =
        monthly +
        pricing.filter((pr) => pr.name === prod)[0].monthly +
        pricing.filter((pr) => pr.name === pakket)[0].monthly;
      setTotFixed(fixed);
      setTotMonth(monthly);
    } else {
      fixed = 0;
      monthly = 0;
      setTotFixed(null);
      setTotMonth(null);
    }
  }, [prod, pakket]);

  return (
    <div className="">
      {prod !== "" && (
        <p className="fs-4">
          <strong>
            <TL text="Stap 3 onze aanbieding" />
          </strong>{" "}
          <TL text="is als volgt:" />
        </p>
      )}
      <div className="mx-auto">
        <div className="row d-flex offerbox align-items-center py-5 px-2">
          <div className="col-lg-1"></div>
          <div className="col-lg-3 offerboxleft">
            <div className="fs-3">
              <TL text="Onze aanbieding" />
            </div>
          </div>

          <div className="col-lg-8 offerbox offerboxright p-3">
            <div>
              <TL text="Dit is een offerte voor een overeenkomst voor een abonnement voor 1 jaar, geldig vanaf vandaag," />{" "}
              {today.getDate() +
                "/" +
                today.getMonth() +
                1 +
                "/" +
                today.getFullYear()}
              <TL text=", met als inhoud de levering van volgende e-commerce diensten:" />
            </div>
            {prod !== "pr4" && pakket !== "pak3" && (
              <>
                <div className="ms-3">
                  1) <TL text="Als product koos je voor" />{" "}
                  <strong>
                    {pricing.filter((pr) => pr.name === prod)[0].description}
                  </strong>
                </div>
                <div className="ms-3">
                  2) <TL text="Als integratie koos je voor" />{" "}
                  <strong>
                    {pricing.filter((pr) => pr.name === pakket)[0].description}
                  </strong>
                </div>
                <div className="mt-3">
                  <TL text="Voor een totale prijs van:" />
                </div>
                <div className="ms-3">
                  <TL text="éénmalige vaste prijs van " />€{totFixed}
                  <TL text="" /> + BTW 21% = €{(totFixed * 1.21).toFixed(2)}.
                </div>
                <div className="ms-3">
                  <TL text="Een maandelijkse prijs van " />€{totMonth}
                  <TL text="" />+ BTW 21% = €{(totMonth * 1.21).toFixed(2)} /m.
                </div>
                <div className="mt-3">
                  <TL text="Bijzonderheden van deze aanbieding:" />
                </div>
                <ul>
                  <li>
                    <TL text="Het betreft een overeenkomst voor een abonnement voor 1 jaar." />
                  </li>
                  <li>
                    <TL text="U geniet een gratis proefperiode van 14 dagen." />
                  </li>
                  <li>
                    <TL text="Het betreft een Saas dienstverlening, alle software en code blijft eigendom van Ubicopia. Met uitzondering van éénmalige betaalde websites." />
                  </li>
                </ul>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    aria-label="accept offer"
                    onClick={() => {
                      setAccepted(true);
                      document
                        .getElementById("startsignup")
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    <TL text="Naar invullen van uw gegevens." />
                  </button>
                </div>
              </>
            )}
            {(prod === "pr4" || pakket === "pak3") && (
              <>
                <div className="ms-3 fw-bold mt-3">
                  <TL text="Je koos voor een product op maat. Hiervoor heb je een offerte nodig, en hebben we meer info nodig." />
                </div>
                <TL text="Beschrijf goed je verwachtingen van de website, dan nemen we spoedig contact op zodat we samen aan je toekomst kunnen werken." />
                <ContactBlock />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigOffer;
