import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import TL from "../Components/TL";
import BackToCases from "../Components/BackToCases";
import HomeInteraction from "../Components/Home/HomeInteraction";

import traiteur from "../Media/traiteur.webp";
import traiteurbackground from "../Media/traiteur-background.webp";

import time from "../Media/time.webp";

const CaseTraiteur = () => {
  useEffect(() => {
    document.getElementById("start").scrollIntoView({ behavior: "smooth" });
  }, []);
  const traiteurstyle = {
    backgroundImage: `url(${traiteurbackground})`,
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    filterBrightness: "70%",
  };
  return (
    <div className="casepage" id="start">
      <Helmet>
        <title>Webwinkel voor traiteur</title>
        <meta
          name="description"
          content="Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta name="twitter:title" content="Webwinkel voor traiteur" />
        <meta
          name="twitter:description"
          content="Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta
          name="keywords"
          content="webwinkel voor traiteur, webshop voor traiteur, webwinkel software voor traiteur, webshop software voor traiteur, webwinkel systeem voor traiteur, webshop systeem voor traiteur, traiteur online, online traiteur, ecommerce voor traiteur, e-commerce voor traiteur"
        />
        <link rel="canonical" href="https://ubicopia.be/cases/traiteur/" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <section style={traiteurstyle}>
        <div className="container mb-3">
          <BackToCases />
        </div>

        <div className="container boxcornsilk" id="title">
          <div className="row d-flex p-2">
            <div className="boxdark col-lg-7 d-flex justify-content-center align-items-center p-3 my-3">
              <div className="text-white p-3">
                <h1>
                  <TL text="Webwinkel voor de traiteur" />
                </h1>
                <p>
                  <TL text="Ubicopia® biedt tal van voordelen voor het verhogen van de rendabiliteit van je zaak." />
                </p>
                <div className="fs-1">
                  <TL text="Verhoging Klantenbinding." />
                </div>
                <p>
                  <TL text="Je communiceert rechtstreeks met je klantenbestand." />
                </p>
                <div className="fs-1">
                  <TL text="Efficiëntie" />
                </div>
                <p>
                  <TL text="Het systeem werkt voor jou en automatiseert vele van je taken." />
                </p>
                <p>
                  <TL text="Het biedt de ideale hulp die je nodig hebt tijdens drukke piekmomenten." />
                </p>
                <div className="fs-1">
                  <TL text="Omzetstijging" />
                </div>
                <p>
                  <TL text="Je zet in op een extra verkoopkanaal." />
                </p>
                <p>
                  <TL text="Je bereikt een nieuw type klant." />
                </p>
              </div>
            </div>
            <div className="col-lg-5 my-3 p-3 d-flex align-items-center">
              <img
                className="w-100 h-auto"
                src={traiteur}
                alt="webwinkel voor traiteur"
                width="511"
                height="345"
              />
            </div>
          </div>
        </div>

        <div className="container boxtransp70 text-white p-5 my-5">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="text-start">
                <TL text="Een webwinkel voor traiteur verhoogt je klantenbinding" />
              </h2>
              <h3 className="fs-5">
                <TL text="Online marketing" />
              </h3>
              <p className="ms-4">
                <TL text="Met het Ubicopia® marketing-mail pakket, standaard inclusief, kan je mailen naar je klantenbestand en zo actief je klandizie informeren over je laatste initiatief." />
              </p>
              <p className="ms-4">
                <TL text="Indien gewenst kan je je klant actief op de hoogte houden ivm drukke momenten, en zo bvb adviseren om tijdig te bestellen." />
              </p>
              <h2 className="text-start">
                <TL text="Met de webwinkel bekom je een stijging in je efficiëntie" />
              </h2>
              <h3 className="fs-5">
                <TL text="Het systeem werkt voor jou en organiseert je orderflow." />
              </h3>
              <p className="ms-4">
                <TL text="Het systeem bundelt de bereidingen per leverdag of levermoment, en locatie, zo verhoog je je efficiëntie bij de bereidingen." />
              </p>
              <h3 className="fs-5">
                <TL text="De webwinkel neemt een aantal taken over." />
              </h3>
              <p className="ms-4">
                <TL text="Ubicopia® maakt actief komaf met archaïsche orderverwerking en bezorgt je mooie overzichten van de bestellingen." />
              </p>
              <p className="ms-4">
                <TL
                  text="Zo kan je zien welke bestellingen al dan niet reeds online
                betaald zijn. Welke bestellingen reeds zijn afgehaald, en welke
                bvb nog moeten worder verwerkt."
                />
              </p>
              <h2 className="text-start">
                <TL text="De webwinkel verhoogt je omzet" />
              </h2>
              <h3 className="fs-5">
                <TL text="De online traiteur" />
              </h3>
              <p className="ms-4">
                <TL text="Je opent een nieuwe verkoopkanaal. Het is geweten dat de moderne consument meer en meer online gaat shoppen. Met een eigen webwinkel trek je de moderne consument aan, en verhoog je je omzet." />
              </p>
            </div>
            <div className="col-lg-4">
              <img
                className="w-100 h-auto"
                src={time}
                alt="webwinkel voor traiteur, tijdswinst is van essentieel belang"
                width="392"
                height="338"
              />
              <p className="ms-1">
                <TL text="" />
                Tijd is van essentieel belang
              </p>
            </div>
          </div>
        </div>

        <div className="container">
          <HomeInteraction />
        </div>

        <div className="container mt-5 boxtransp70 p-5 text-white">
          <div className="row">
            <h2>
              <TL text="Ubicopia® werd net voor deze nood aan organisatie ontwikkeld." />
            </h2>
            <p>
              <TL text="Eenmaal de feestdagen voorbij, wordt terug overgeschakeld op het klassieke aanbod, bvb. bbq gerechten in de zomer, gourmetschotels in de winter, en dient de website, webshop en alle bijbehorende weer snel aangepast te worden." />
            </p>
            <ul className="list-group bg-dark">
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Dankzij de zelf configuurbare inhoud van de webshop kan u de producten en rubrieken en subrubrieken zelf kiezen. Per product kan u bovendien opties bepalen, zo kan je definiëren dat dezelfde schotel voor 2 personen, 4 of 6 personen, een andere prijs krijgt en op een andere, correcte manier wordt verwerkt. Tegen het aanbreken van de feestdagen zet u enkele feestschotels in promotie, deze verschijnen vooraan op de pagina, u voegt enkele rubrieken en subrubrieken toe voor de feestschotels en plaatst deze vooraan in de webshop microShop). Eenmaal de feesten voorbij, past u de inhoud aan, en kan u terug naar het normale productassortiment." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Na de feestdagen, wenst u enkele dagen rust. In de afhaalmomenten kan u deze wekelijks of 2 of 3-wekelijks laten herhalen. Bij verlof geeft u de verlofdagen in en wordt de afhaalkalender voor uw verlofdagen geblokkeerd. Op deze manier kan de klant geen bestellingen boeken om af te halen wanneer u bent gesloten." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Online betalen kan worden ingeschakeld. Door de partnering met Worldline kan de klant online op voorhand betalen. U en uw klanten winnen tijd en efficiëntie bij het afhalen." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Wat echter indien er misbruik wordt gemaakt bij het bestellen? Iedereen die een webshop of ophaalsysteem heeft, heeft ofwel er al eens bij stilgestaan, of in het slechtste geval, het al meegemaakt. Wat als de klant een nepaccount aanmaakt, en een grote bestelling plaatst en deze dan niet komt afhalen? Ubicopia® heef het optionele systeem ingebouwd, dat bijvoorbeeld de eerste x aantal bestellingen online dienen betaald te worden, alvorens betaling bij afhalen kan worden ingeschakeld." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="De klant wordt via gepersonaliseerde emails op de hoogte gehouden van de voortgang van zijn bestelling. Deze kunnen elk afzonderlijk worden aan of afgeschakeld. Doet u bvb     geen verzending, dan hoeft er ook geen mail verstuurd te worden wanneer de bestelling klaar is." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Wat met het persoonlijke contact? Bij het verwerken zie je bij elke bestelling de naam en contactgegevens, door hierop te klikken bel je automatisch (Google Android), of start je een email op. Zo blijf je steeds in nauw contact met de klant, en kan je hem/haar snel contacteren wanneer er iets niet duidelijk is." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Door de makkelijke overzichten voor de boekhouding en de orderverwerking win je al snel veel tijd en kan je je focussen op je zaak en je leven, ipv administratie." />
                </p>
              </li>
              <li className="list-group-item bg-transparent text-white">
                <p>
                  <TL text="Facturen en orderbevestigingen worden automatisch opgemaakt en, naargelang uw ingestelde voorkeuren, al dan niet verstuurd naar de klant. Deze kan zijn documenten ook altijd achteraf downloaden via de microShop." />
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="container mb-3">
          <BackToCases />
        </div>
      </section>
    </div>
  );
};

export default CaseTraiteur;
