import React from "react";
import { useNavigate } from "react-router-dom";

import foodheader from "../Media/foodheader.webp";

const Logotitle = () => {
  var navigate = useNavigate();
  return (
    <button className="logotitle" onClick={() => navigate("/")}>
      <img
        className="hplogo"
        src={foodheader}
        alt="Ubicopia - e-commerce voor de kleinhandel"
        width="380"
        height="117"
      ></img>
    </button>
  );
};

export default Logotitle;
