import React, { Suspense, lazy } from "react";

//Routing
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

//Context
import { ControlProvider } from "./Store/control-context";

//Segments
import Header from "./Segments/Header";

// import Footer from "./Segments/Footer";

//Styling
import "./generic.css";

//Pages
import Home from "./Pages/Home";
import HomeCasePage from "./Pages/HomeCasePage";
import HomeProductPage from "./Pages/HomeProductPage";
import HomeTechPage from "./Pages/HomeTechPage";
import HomeContactPage from "./Pages/HomeContactPage";
import HomeAboutPage from "./Pages/HomeAboutPage";
import HomeVideoPage from "./Pages/HomeVideoPage";
import HomeSignupPage from "./Pages/HomeSignupPage";

import CaseFoodTruck from "./Pages/CaseFoodTruck";
import CaseBakker from "./Pages/CaseBakker";
import CaseSlager from "./Pages/CaseSlager";
import CaseFrituur from "./Pages/CaseFrituur";
import CaseKleinHandel from "./Pages/CaseKleinHandel";
import CaseTraiteur from "./Pages/CaseTraiteur";
import CaseWebShop from "./Pages/CaseWebShop";

// const CaseFoodTruck = lazy(() => import("./Pages/CaseFoodTruck"));
// const CaseBakker = lazy(() => import("./Pages/CaseBakker"));
// const CaseSlager = lazy(() => import("./Pages/CaseSlager"));
// const CaseFrituur = lazy(() => import("./Pages/CaseFrituur"));
// const CaseKleinHandel = lazy(() => import("./Pages/CaseKleinHandel"));
// const CaseTraiteur = lazy(() => import("./Pages/CaseTraiteur"));
// const CaseWebShop = lazy(() => import("./Pages/CaseWebShop"));

const Footer = lazy(() => import("./Segments/Footer"));

const App = () => (
  <Router>
    <ControlProvider>
      <Suspense fallback={<div>...loading</div>}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cases/" element={<HomeCasePage />} />
          <Route path="/cases/foodtruck/" element={<CaseFoodTruck />} />
          <Route path="/cases/bakker/" element={<CaseBakker />} />
          <Route path="/cases/slager/" element={<CaseSlager />} />
          <Route path="/cases/frituur/" element={<CaseFrituur />} />
          <Route path="/cases/kleinhandel/" element={<CaseKleinHandel />} />
          <Route path="/cases/traiteur/" element={<CaseTraiteur />} />
          <Route path="/cases/webshop/" element={<CaseWebShop />} />
          <Route path="/products/" element={<HomeProductPage />} />
          <Route path="/technology/" element={<HomeTechPage />} />
          <Route path="/contact/" element={<HomeContactPage />} />
          <Route path="/about/" element={<HomeAboutPage />} />
          <Route path="/videos/" element={<HomeVideoPage />} />
          <Route path="/signup/" element={<HomeSignupPage />} />
          <Route path="/home/" element={<Navigate to="/" />} />
          <Route path="/home/cases/" element={<Navigate to="/cases/" />} />
          <Route
            path="/home/products/"
            element={<Navigate to="/products/" />}
          />
          <Route
            path="/home/technology/"
            element={<Navigate to="/technology/" />}
          />
          <Route path="/home/contact/" element={<Navigate to="/contact/" />} />
          <Route path="/home/about/" element={<Navigate to="/about/" />} />
          <Route path="/home/login/" element={<Navigate to="/login/" />} />
          <Route path="/home/signup/" element={<Navigate to="/signup/" />} />
          <Route path="/home/videos/" element={<Navigate to="/videos/" />} />
        </Routes>
        <Footer />
      </Suspense>
    </ControlProvider>
  </Router>
);

export default App;
