import { createContext, useState } from "react";

const ControlContext = createContext({
  language: null,
  setlanguage: () => {},
});

export function ControlProvider(props) {
  const [language, setLangauge] = useState("NL");

  let context = {
    language: language,
    setlanguage: setLangauge,
  };

  return (
    <ControlContext.Provider value={context}>
      {props.children}
    </ControlContext.Provider>
  );
}

export default ControlContext;
