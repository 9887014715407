import React from "react";
import { Helmet } from "react-helmet";

import TL from "../Components/TL";
import "../cases.css";

import digitalpointer from "../Media/digitalpointer.webp";
import butcher from "../Media/butcher.webp";
import { keywordscase } from "../Data/keywordscase";
import { useNavigate } from "react-router-dom";

import onlinebackground from "../Media/online-background.webp";

const HomeCasePage = () => {
  const online = {
    backgroundImage: `url(${onlinebackground})`,
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
  };
  const navigate = useNavigate();

  return (
    <div className="casepage" id="start">
      <Helmet>
        <title>Ubicopia® - Cases</title>
        <meta
          name="description"
          content="Ubicopia® is geschikt als e-commerce tool voor verschillende cases, gaande van verse bereidingen, slagers, traiteurs, bakkers,frituur, foodtrucks enz."
        />
        <meta name="twitter:title" content="Ubicopia® - Cases" />
        <meta
          name="twitter:description"
          content="Ubicopia® is geschikt als e-commerce tool voor verschillende cases, gaande van verse bereidingen, slagers, traiteurs, bakkers,frituur, foodtrucks enz."
        />
        <meta name="keywords" content={keywordscase} />
        <link rel="canonical" href="https://ubicopia.be/cases/" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <section className="darksection py-5" style={online}>
        <div className="container bg-black">
          <div className="row">
            <div className="col-lg-7 coverbox boxhigh">
              <div className="boxolive p-4">
                <h1 className="display-5 fw-bold text-white">
                  <TL text="Ontdek hier de vele mogelijkheden van Ubicopia®" />
                </h1>
              </div>
              <div>
                <p className="fs-4 p-4">
                  <ul>
                    <li className="list-unstyled my-1">
                      <button
                        className="btn btn-primary w-75"
                        onClick={() => navigate("/cases/bakker/")}
                      >
                        <TL text="Webwinkel voor bakker" />
                      </button>
                    </li>
                    <li className="list-unstyled my-1">
                      <button
                        className="btn btn-primary w-75"
                        onClick={() => navigate("/cases/slager/")}
                      >
                        <TL text="Webwinkel voor slager" />
                      </button>
                    </li>
                    <li className="list-unstyled my-1">
                      <button
                        className="btn btn-primary w-75"
                        onClick={() => navigate("/cases/traiteur/")}
                      >
                        <TL text="Webwinkel voor traiteur" />
                      </button>
                    </li>
                    <li className="list-unstyled my-1">
                      <button
                        className="btn btn-primary w-75"
                        onClick={() => navigate("/cases/frituur/")}
                      >
                        <TL text="Webwinkel voor frituur" />
                      </button>
                    </li>
                    <li className="list-unstyled my-1">
                      <button
                        className="btn btn-primary w-75"
                        onClick={() => navigate("/cases/foodtruck/")}
                      >
                        <TL text="Webwinkel voor foodtruck" />
                      </button>
                    </li>
                    <li className="list-unstyled my-1">
                      <button
                        className="btn btn-primary w-75"
                        onClick={() => navigate("/cases/kleinhandel/")}
                      >
                        <TL text="Webwinkel voor kleinhandel" />
                      </button>
                    </li>
                    <li className="list-unstyled my-1">
                      <button
                        className="btn btn-primary w-75"
                        onClick={() => navigate("/cases/webshop/")}
                      >
                        <TL text="Ook als standaard webshop" />
                      </button>
                    </li>
                  </ul>
                </p>
                <p>
                  <TL text="Bekijk de cases." />{" "}
                  <TL text="Zo komt u te weten of het iets voor jouw zaak is." />
                </p>
              </div>
            </div>
            <div className="col-lg-5 coverbox d-flx align-items-center">
              <div className="row">
                <img
                  className="butcher"
                  src={butcher}
                  alt="slager digitaal"
                  width="400"
                  height="564"
                />
              </div>
              <div className="row">
                <img
                  className="digitalpointer"
                  src={digitalpointer}
                  alt="kleinhandel digitaal"
                  width="90"
                  height="90"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container boxtransp70 py-5 mt-5">
          <div className="row align-items-md-stretch">
            <div className="col-md-6 ">
              <div className="h-100 p-5 coverboxburly">
                <h2 className="text-black">
                  <TL text="De foodtruck. Voorbeeld voor verse bereidingen." />
                </h2>
                <p>
                  <TL text="We staan op verschillende plaatsen, op veranderende tijdstippen. Bijvoorbeeld op markten." />
                </p>
                <p>
                  <TL text="Plots komen er grote bestellingen binnen, we hebben slechts een beperkte capaciteit en er staan mensen aan de truck te wachten." />
                </p>
                <button
                  className="readmore text-black"
                  onClick={() => navigate("/cases/foodtruck/")}
                  aria-label="select case foodtruck"
                >
                  <TL text="naar case" />
                  <i className="bi bi-arrow-right ms-3"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="h-100 p-5 coverboxolive">
                <h2 className="text-white">
                  <TL text="De kleinhandel, u hebt nog geen eigen website" />
                </h2>
                <p>
                  <TL text="U merkt dat er minder binding is met de jongere generatie klanten." />
                </p>
                <p>
                  <TL text="Soms is het nodig de klanten te informeren bvb. door wegenwerken, of wegens ziekte of andere onderbrekingen." />
                </p>
                <p>
                  <TL text="U kijkt er echter tegenop nog een nieuwe avontuur op te starten, er is al zoveel werk en administratie." />
                </p>
                <button
                  className="readmore"
                  onClick={() => navigate("/cases/kleinhandel/")}
                  aria-label="select case kleinhandel"
                >
                  <TL text="naar case" />
                  <i className="bi bi-arrow-right ms-3"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="row align-items-md-stretch mt-5">
            <div className="col-md-6">
              <div className="h-100 p-5 coverboxolive">
                <h2 className="text-white">
                  <TL text="Ubicopia® als klassieke webshop" />
                </h2>
                <p>
                  <TL text="Voor de online handelaar, ook als Micro-Onderneming, biedt Ubicopia® de klassieke mogelijkheden van een webshop zoals we deze allemaal kennen." />
                </p>
                <p>
                  <TL text="Online betalen, verzending en tracking. Net zoals de klassieke webshop." />
                </p>
                <button
                  className="readmore"
                  aria-label="select case webshop"
                  onClick={() => navigate("/cases/webshop")}
                >
                  <TL text="naar case" />
                  <i className="bi bi-arrow-right ms-3"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="h-100 p-5 coverboxburly">
                <h2 className="text-black">
                  <TL text="De traiteurzaak. Verse bereidingen, piekmomenten, weinig tijd." />
                </h2>
                <p>
                  <TL text="Of u nu een kleinhandel hebt, met een seizoensgebonden traiteurdienst, of een traiteur bent in hoofdberoep. Ongetwijfeld kent u de bijbehorende stress wanneer de leveringen naderbij komen en de klanten komen afhalen." />
                </p>
                <button
                  className="readmore text-black"
                  aria-label="select case traiteur"
                  onClick={() => navigate("/cases/traiteur")}
                >
                  <TL text="naar case" />
                  <i className="bi bi-arrow-right ms-3"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="row align-items-md-stretch mt-5">
            <div className="col-md-6 ">
              <div className="h-100 p-5 coverboxburly">
                <h2 className="text-black">
                  <TL text="De frituur. Efficiëntie is van belang." />
                </h2>
                <p>
                  <TL text="Plots komen er grote bestellingen binnen, we hebben slechts een beperkte capaciteit en er staan mensen aan te schuiven." />
                </p>
                <p>
                  <TL text="Alles moet snel gaan. Laat het systeem voor jou werken." />
                </p>
                <p>
                  <TL text="Connecteer met je klanten, met het ingebouwde marketing platform." />
                </p>
                <button
                  className="readmore text-black"
                  onClick={() => navigate("/cases/frituur/")}
                  aria-label="select case frituur"
                >
                  <TL text="naar case" />
                  <i className="bi bi-arrow-right ms-3"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="h-100 p-5 coverboxolive">
                <h2 className="text-white">
                  <TL text="De slager" />
                </h2>
                <p>
                  <TL text="U merkt dat er minder binding is met de jongere generatie klanten." />
                </p>
                <p>
                  <TL text="Soms is het nodig de klanten te informeren bvb. door wegenwerken, of wegens ziekte of andere onderbrekingen." />
                </p>
                <p>
                  <TL text="U kijkt er echter tegenop nog een nieuwe avontuur op te starten, er is al zoveel werk en administratie." />
                </p>
                <button
                  className="readmore"
                  onClick={() => navigate("/cases/slager/")}
                  aria-label="select case slager"
                >
                  <TL text="naar case" />
                  <i className="bi bi-arrow-right ms-3"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="row align-items-md-stretch mt-5">
            <div className="col-md-6">
              <div className="h-100 p-5 coverboxolive">
                <h2 className="text-white">
                  <TL text="De bakker" />
                </h2>
                <p>
                  <TL text="U merkt dat er minder binding is met de jongere generatie klanten." />
                </p>
                <p>
                  <TL text="Soms is het nodig de klanten te informeren bvb. door wegenwerken, of wegens ziekte of andere onderbrekingen." />
                </p>
                <p>
                  <TL text="U kijkt er echter tegenop nog een nieuwe avontuur op te starten, er is al zoveel werk en administratie." />
                </p>
                <button
                  className="readmore"
                  onClick={() => navigate("/cases/bakker/")}
                  aria-label="select case bakker"
                >
                  <TL text="naar case" />
                  <i className="bi bi-arrow-right ms-3"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeCasePage;
