import React from "react";
import TL from "../TL";

const ConfigProduct = ({ title, id, content, prod, setProd }) => {
  return (
    <>
      {(prod === id || prod === "") && (
        <div className="productbox text-center mx-auto">
          <h4 className="prodpaktitle">{title}</h4>
          <div className="productmidfield">{content}</div>
          {prod === "" && (
            <button
              className="btn btn-success btn-sm border border-1"
              aria-label={title}
              onClick={() => setProd(id)}
            >
              <TL text="Selecteer" />
            </button>
          )}

          {prod !== "" && (
            <button
              className="btn btn-secondary btn-sm border border-1"
              aria-label="maak ongedaan"
              onClick={() => setProd("")}
            >
              <TL text="Maak ongedaan" />
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default ConfigProduct;
