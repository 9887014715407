import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import TL from "../Components/TL";

import ControlContext from "../Store/control-context";

const HeaderHomePage = () => {
  let controlObject = useContext(ControlContext);
  const [subject, setSubject] = useState(null);
  const [page, setPage] = useState("home");
  const [content, setContent] = useState("");
  var navigate = useNavigate();
  var loginURL = process.env.REACT_APP_FRONTEND_URL + "/login";

  useEffect(() => {
    var cont = "";
    switch (subject) {
      case "case":
        cont = (
          <>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-2 text-center fs-2">
                <i className="bi bi-caret-down"></i>
              </div>
              <div className="col-2"></div>
              <div className="col-2"></div>
              <div className="col-2"></div>
              <div className="col-2"></div>
            </div>
            <div
              className="d-flex row mt-2"
              onMouseLeave={() => setSubject(null)}
              onClick={() => {
                navigate("/cases/");
                setPage("case");
              }}
            >
              <div className="col-6 text-center">
                <p className="fs-1 text-center">
                  <i className="bi bi-shop-window"></i>
                </p>
                <p>
                  <TL text="Enkele uitgewerkte cases waarin u zich kan herkennen." />
                </p>
                <p>
                  <TL text="Zo komt u te weten of Ubicopia iets voor u is." />
                </p>
              </div>
              <div className="col-6 align-items-center">
                <ul>
                  <li>
                    <TL text="Case: U hebt een kleinhandel, maar nog geen website." />
                  </li>
                  <li>
                    <TL text="Case: U hebt een zaak met verschillende winkels." />
                  </li>
                  <li>
                    <TL text="Case : Traiteur" />
                  </li>
                  <li>
                    <TL text="Case : Bakker" />
                  </li>
                  <li>
                    <TL text="Case : Slager" />
                  </li>
                  <li>
                    <TL text="Case : Frituur" />
                  </li>
                  <li>
                    <TL text="Case : De foodtruck. Verse bereidingen." />
                  </li>
                  <li>
                    <TL text="Case : De klassieke webshop" />
                  </li>
                </ul>
              </div>
            </div>
          </>
        );
        setContent(cont);
        break;
      case "prod":
        cont = (
          <>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-2"></div>
              <div className="col-2 text-center fs-2">
                <i className="bi bi-caret-down"></i>
              </div>

              <div className="col-2"></div>
              <div className="col-2"></div>
              <div className="col-2"></div>
            </div>
            <div
              className="d-flex row mt-2"
              onMouseLeave={() => setSubject(null)}
              onClick={() => {
                navigate("/products/");
                setPage("prod");
              }}
            >
              <div className="col-6 align-center">
                <p className="fs-1 text-center">
                  <i className="bi bi-cart"></i>
                </p>
                <p className="text-center">
                  <TL text="Ontdek de unieke producten van Ubicopia®, oplossingen speciaal voor kleinhandel ontwikkeld." />
                </p>
                <ul>
                  <li>
                    <TL text="Geen zware investering" />
                  </li>
                  <li>
                    <TL text="Makkelijk te gebruiken" />
                  </li>
                  <li>
                    <TL text="Onmiddellijk beschikbaar" />
                  </li>
                </ul>
              </div>

              <div className="col-6 align-items-center">
                <ul>
                  <li>
                    <TL text="microShop - de supersnelle, gemakkelijk implementeerbare oplossing" />
                  </li>
                  <li>
                    <TL text="microSite - supersnel, je eigen website met webshop" />
                  </li>
                  <li>
                    <TL text="compoSite - volledig op maat gemaakt" />
                  </li>
                </ul>
              </div>
            </div>
          </>
        );
        setContent(cont);
        break;
      case "tech":
        cont = (
          <>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-2"></div>
              <div className="col-2"></div>
              <div className="col-2 text-center fs-2">
                <i className="bi bi-caret-down"></i>
              </div>

              <div className="col-2"></div>
              <div className="col-2"></div>
            </div>
            <div
              className="d-flex row mt-2"
              onMouseLeave={() => setSubject(null)}
              onClick={() => {
                navigate("/technology/");
                setPage("tech");
              }}
            >
              <div className="col-6 align-center">
                <p className="fs-1 text-center">
                  <i className="bi bi-cloud-sun"></i>
                </p>
                <p className="text-center">
                  <TL text="Kom meer te weten over de gebruikte technologie, en wat Ubicopia® zo uniek maakt voor u." />
                </p>
              </div>
              <div className="col-6 align-items-center">
                <ul>
                  <li>
                    <TL text="Lees meer over Saas" />
                  </li>
                  <li>Headless</li>
                  <li>Composable</li>
                </ul>
              </div>
            </div>
          </>
        );
        setContent(cont);
        break;
      case "contact":
        cont = (
          <>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-2"></div>
              <div className="col-2"></div>
              <div className="col-2"></div>
              <div className="col-2 text-center fs-2">
                <i className="bi bi-caret-down"></i>
              </div>
              <div className="col-2"></div>
            </div>
            <div
              className="d-flex row mt-2"
              onMouseLeave={() => setSubject(null)}
              onClick={() => {
                navigate("/contact/");
                setPage("contact");
              }}
            >
              <div className="col-6 align-center">
                <p className="fs-1 text-center">
                  <i className="bi bi-envelope-plus"></i>
                </p>
                <p>
                  <TL text="Contacteer ons op de manier die voor u het makkelijkst is" />
                </p>
              </div>
              <div className="col-6 align-items-center">
                <ul>
                  <li>E-mail : info@ubicopia.com</li>
                  <li>
                    <TL text="Telefoon" />: +32(0)16.903.806
                  </li>
                  <li>
                    <TL text="Contact Formulier" />
                  </li>
                  <li>
                    <TL text="Technische ondersteuning" />
                  </li>
                </ul>
              </div>
            </div>
          </>
        );
        setContent(cont);
        break;
      case "about":
        cont = (
          <>
            <div className="row">
              <div className="col-1"></div>
              <div className="col-2"></div>
              <div className="col-2"></div>
              <div className="col-2"></div>
              <div className="col-2"></div>
              <div className="col-2 text-center fs-2">
                <i className="bi bi-caret-down"></i>
              </div>
            </div>
            <div
              className="d-flex row mt-2"
              onMouseLeave={() => setSubject(null)}
              onClick={() => {
                navigate("/about/");
                setPage("about");
              }}
            >
              <div className="col-6 text-center">
                <p className="fs-1 text-center">
                  <i className="bi bi-building"></i>
                </p>
                <p>
                  <TL text="Kom meer te weten over het verhaal achter Ubicopia®" />
                </p>
              </div>
              <div className="col-6 align-items-center">
                <ul>
                  <li>
                    <TL text="Het verhaal" />
                  </li>
                  <li>
                    <TL text="Het bedrijf" />
                  </li>
                  <li>
                    <TL text="Het team" />
                  </li>
                </ul>
              </div>
            </div>
          </>
        );
        setContent(cont);
        break;
    }
  }, [subject]);

  return (
    <>
      <div className="dropdown btn-group headercondensed text-white">
        <button
          className="hhbutton"
          type="button"
          data-bs-toggle="dropdown"
          aria-label="open header"
          aria-expanded="false"
        >
          <i className="bi bi-list text-white"></i>
        </button>
        <ul className="dropdown-menu condensed-menu">
          <li>
            <button
              className="hhdropdownitem"
              onClick={() => {
                navigate("/");
                setPage("home");
              }}
            >
              {page === "home" ? (
                <strong>
                  <TL text="Home" />
                </strong>
              ) : (
                <>
                  <TL text="Home" />
                </>
              )}
            </button>
          </li>
          <li>
            <button
              className="hhdropdownitem"
              onClick={() => {
                navigate("/cases");
                setPage("case");
              }}
            >
              {page === "case" ? (
                <strong>
                  <TL text="Cases" />
                </strong>
              ) : (
                <>
                  <TL text="Cases" />
                </>
              )}
            </button>
          </li>
          <li>
            <button
              className="hhdropdownitem"
              onClick={() => {
                navigate("/products");
                setPage("prod");
              }}
            >
              {page === "prod" ? (
                <strong>
                  <TL text="Producten" />
                </strong>
              ) : (
                <>
                  <TL text="Producten" />
                </>
              )}
            </button>
          </li>
          <li>
            <button
              className="hhdropdownitem"
              onClick={() => {
                navigate("/technology");
                setPage("tech");
              }}
            >
              {page === "tech" ? (
                <strong>
                  <TL text="Tech" />
                </strong>
              ) : (
                <>
                  <TL text="Tech" />
                </>
              )}
            </button>
          </li>
          <li>
            <button
              className="hhdropdownitem"
              onClick={() => {
                navigate("/contact");
                setPage("contact");
              }}
            >
              {page === "contact" ? (
                <strong>
                  <TL text="Contact" />
                </strong>
              ) : (
                <>
                  <TL text="Contact" />
                </>
              )}
            </button>
          </li>
          <li>
            <button
              className="hhdropdownitem"
              onClick={() => {
                navigate("/about");
                setPage("about");
              }}
            >
              {page === "about" ? (
                <strong>
                  <TL text="Over ons" />
                </strong>
              ) : (
                <>
                  <TL text="Over ons" />
                </>
              )}
            </button>
          </li>
          <li className="dropdown2 d-flex-flex-row justify-content-center">
            <button
              className="hhdropdownitem"
              onClick={() => controlObject.setlanguage("NL")}
            >
              NL
            </button>

            <button
              className="hhdropdownitem"
              onClick={() => controlObject.setlanguage("FR")}
            >
              FR
            </button>

            <button
              className="hhdropdownitem"
              onClick={() => controlObject.setlanguage("EN")}
            >
              EN
            </button>
          </li>
          <hr className=""></hr>
          <li className="d-flex justify-content-center bg-success">
            <a
              className="text-decoration-none text-white text-center w-100"
              href={loginURL}
              aria-label="link to login page of ubicopia"
            >
              <TL text="Login" />
            </a>
          </li>
        </ul>
      </div>
      <div
        className="container-fluid headerhome border-none"
        onMouseLeave={() => setSubject(null)}
      >
        <div className="row p-2">
          <div className="col-1">
            <button
              className="hhbutton"
              onClick={() => {
                navigate("/");
                setPage("home");
              }}
            >
              {page === "home" ? (
                <span className="fs-6">
                  <i className="bi bi-house"></i>
                </span>
              ) : (
                <>
                  <i className="bi bi-house"></i>
                </>
              )}
            </button>
          </div>
          <div className="col-2">
            <button
              className="hhbutton"
              onMouseEnter={() => setSubject("case")}
              onClick={() => {
                navigate("/cases/");
                setPage("case");
              }}
            >
              {page === "case" ? (
                <>
                  <strong>
                    <TL text="Cases" />
                  </strong>
                </>
              ) : (
                <>
                  <TL text="Cases" />
                </>
              )}
            </button>
          </div>
          <div className="col-2">
            <button
              className="hhbutton"
              onMouseEnter={() => setSubject("prod")}
              onClick={() => {
                navigate("/products/");
                setPage("prod");
              }}
            >
              {page === "prod" ? (
                <strong>
                  <TL text="Producten" />
                </strong>
              ) : (
                <>
                  <TL text="Producten" />
                </>
              )}
            </button>
          </div>
          <div className="col-2">
            <button
              className="hhbutton"
              onMouseEnter={() => setSubject("tech")}
              onClick={() => {
                navigate("/technology/");
                setPage("tech");
              }}
            >
              {page === "tech" ? (
                <strong>
                  <TL text="Tech" />
                </strong>
              ) : (
                <>
                  <TL text="Tech" />
                </>
              )}
            </button>
          </div>
          <div className="col-2">
            <button
              className="hhbutton"
              onMouseEnter={() => setSubject("contact")}
              onClick={() => {
                navigate("/contact/");
                setPage("contact");
              }}
            >
              {page === "contact" ? (
                <strong>
                  <TL text="Contact" />
                </strong>
              ) : (
                <>
                  <TL text="Contact" />
                </>
              )}
            </button>
          </div>
          <div className="col-2">
            <button
              className="hhbutton"
              onMouseEnter={() => setSubject("about")}
              onClick={() => {
                navigate("/about/");
                setPage("about");
              }}
            >
              {page === "about" ? (
                <strong>
                  <TL text="Over ons" />
                </strong>
              ) : (
                <>
                  <TL text="Over ons" />
                </>
              )}
            </button>
          </div>
          <div className="col-1">
            <div className="dropdown">
              <a
                className="text-white text-decoration-none dropdown-toggle hhbutton"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {controlObject.language}
              </a>
              <ul className="dropdown-menu hhdropdown">
                <li className="ms-2">
                  <button
                    className="hhdropdownitem"
                    onClick={() => controlObject.setlanguage("NL")}
                  >
                    Nederlands
                  </button>
                </li>
                <li className="ms-2">
                  <button
                    className="hhdropdownitem"
                    onClick={() => controlObject.setlanguage("FR")}
                  >
                    Français
                  </button>
                </li>
                <li className="ms-2">
                  <button
                    className="hhdropdownitem"
                    onClick={() => controlObject.setlanguage("EN")}
                  >
                    English
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {subject === null ? <></> : <div className="hhdropdown">{content}</div>}
      </div>
    </>
  );
};

export default HeaderHomePage;
