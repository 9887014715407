import React from "react";
import TL from "../TL";

const WhyCard = (why) => {
  return (
    <div className="mb-5">
      <h3 className="fs-5 text-center whytitle">
        <TL text={why.title} />
      </h3>
      <div className="d-flex justify-content-center">
        <img src={why.image} alt={why.alt} width="160" height="160" />
      </div>
      <p className="fs-6 text-center">
        <TL text={why.subtitle1} />
      </p>
      <div className="fs-6">
        <TL text={why.text1} />
      </div>
      <p className="fs-5">
        <TL text={why.subtitle2} />
      </p>
      <div className="fs-6">
        <TL text={why.text2} />
      </div>
    </div>
  );
};
export default WhyCard;
