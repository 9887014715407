import React, { useState } from "react";

import { titles } from "../../Data/data";
import TL from "../TL";
import GTC from "../../Data/GTC";
import { isocountries } from "../../Data/data";

const ConfigSignup = ({ prod, pakk, setAccepted, setProd, setPakket }) => {
  const [accountCreated, setAccountCreated] = useState(false);
  const [showGTC, setShowGTC] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [formData, setFormData] = useState({
    email: "",
    title: "",
    firstname: "",
    name: "",
    phone: "",
    password: "",
    re_password: "",
    organisationname: "",
    organisationvat: "",
    organisationgoods: "",
    organisationbrand: "",
    street: "",
    hnr: "",
    hnrext: "",
    zip: "",
    city: "",
    country: "",
    product: prod,
    pakket: pakk,
  });

  const {
    email,
    phone,
    title,
    firstname,
    name,
    password,
    re_password,
    organisationname,
    organisationvat,
    organisationgoods,
    organisationbrand,
    street,
    hnr,
    hnrext,
    zip,
    city,
    country,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    if (password === re_password) {
      console.log("formdata:", formData);
      cfSignUp(formData);
      setAccountCreated(true);
      setAccepted(false);
      setProd("");
      setPakket("");
    }
  };

  const cfSignUp = async (data) => {
    var url = process.env.REACT_APP_API_URL + "capi/users/configregister/";
    await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((resp) => resp.json())
      .then((resp) => {
        console.log("response received");
      })
      .then((error) => (error ? console.log("error :", error) : null));
  };

  const pwToggler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="text-white mb-5">
      {accountCreated && (
        <div className="fs-5">
          <p>
            <TL text="Bedankt om te registreren, je ontvangt een email om je account te bevestigen." />
          </p>
          <p>
            <TL text="Nadat je account is gecontroleerd door het Ubicopia® team, kan je vervolgens inloggen met je paswoord. Dan kan je eventueel je profiel te vervolledigen met API's (Worldline,PostNL), en kan je al aan de slag om je shop te configureren. Deze maak je na inloggen aan in User/Your Details/Webshop details." />
          </p>
        </div>
      )}
      {!accountCreated && (
        <div className="signupbox">
          <p className="fs-4 mt-3">
            <strong>
              <TL text="Stap 4:" />
            </strong>{" "}
            <TL text="Jouw gegevens" />
          </p>
          <div className="fs-3">
            <TL text="Registreer" />
          </div>
          <p>
            <TL text="Ik teken in op een account" />
          </p>
          <form className="pb-2" onSubmit={(e) => onSubmit(e)}>
            <div className="fw-bold">
              <TL text="Persoonlijke gegevens" />
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-md-7">
                <label className="" htmlFor="email">
                  Email*
                </label>
                <div className="form-group">
                  <input
                    className="form-control"
                    id="email"
                    type="email"
                    placeholder="Email*"
                    name="email"
                    value={email}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-5">
                <label className="" htmlFor="phone">
                  <TL text="Telefoon (beginnende met +3..)" />*
                </label>
                <div className="form-group">
                  <input
                    className="form-control"
                    id="phone"
                    type="phone"
                    placeholder="Tel"
                    name="phone"
                    value={phone}
                    onInput={(e) => onChange(e)}
                  />
                </div>
              </div>
            </div>
            <div className="row d-flex align-items-center">
              <div className="col-md-2">
                <div className="form-group">
                  <label htmlFor="title">
                    <TL text="Titel" />*
                  </label>
                  <select
                    className="form-control"
                    placeholder="Title"
                    type="text"
                    required
                    id="title"
                    name="title"
                    value={title}
                    onInput={(e) => onChange(e)}
                  >
                    <option name="title" value="">
                      - <TL text="titel" /> -
                    </option>
                    {titles &&
                      titles.map((tit, index) => {
                        return (
                          <option name="title" value={tit.code} key={index}>
                            {tit.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group">
                  <label className="" htmlFor="firstname">
                    <TL text="Voornaam" /> *
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="First Name*"
                    id="firstname"
                    name="firstname"
                    value={firstname}
                    onChange={(e) => onChange(e)}
                    pattern="{1,255}$"
                    required
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group">
                  <label className="" htmlFor="name">
                    <TL text="Achternaam" /> *
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Name*"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => onChange(e)}
                    pattern="{1,255}$"
                    maxLength="255"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row mt-3">
                <label className="" htmlFor="password">
                  <TL text="Paswoord - min. 8 karakters, hoofd- kleine letter,cijfer en speciaal karakter. " />{" "}
                  (ex. -/_\)
                </label>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    <TL text="Paswoord" /> *
                  </label>
                  <div className="form-control p-0 d-flex justify-content-between">
                    <input
                      className="form-control"
                      type={passwordType}
                      placeholder="Paswoord*"
                      name="password"
                      id="password"
                      value={password}
                      onChange={(e) => onChange(e)}
                      pattern="(?=^.{8,255}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                      required
                    />
                    <button
                      type="button"
                      aria-label="show password"
                      className="bg-transparent border-0"
                      onClick={() => pwToggler()}
                    >
                      {passwordType === "password" ? (
                        <i className="bi bi-eye fs-5"></i>
                      ) : (
                        <i className="bi bi-eye-slash fs-5"></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="" htmlFor="repassword">
                    <TL text="Nogmaals je paswoord" /> *
                  </label>
                  <div className="form-control p-0 d-flex justify-content-between">
                    <input
                      className="form-control"
                      type={passwordType}
                      placeholder="Herhaal paswoord*"
                      name="re_password"
                      id="re-password"
                      value={re_password}
                      onChange={(e) => onChange(e)}
                      pattern={formData.password}
                      required
                    />
                    <button
                      type="button"
                      aria-label="show password"
                      className="bg-transparent border-0"
                      onClick={() => pwToggler()}
                    >
                      {passwordType === "password" ? (
                        <i className="bi bi-eye fs-5"></i>
                      ) : (
                        <i className="bi bi-eye-slash fs-5"></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="fw-bold">
              <TL text="Bedrijfsgegevens" />
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="">
                    <TL text="Officiële naam van je organisatie" /> *
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Naam van je organisatie*"
                    name="organisationname"
                    value={organisationname}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="">
                    <TL text="BTW nummer - BEXXXXXXXXXX (geen spaties)" /> *
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="VAT*"
                    name="organisationvat"
                    value={organisationvat}
                    onChange={(e) => onChange(e)}
                    pattern="[A-Z]{2}[0-9]{1,20}$"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="">
                    <TL text="Kies de handelsnaam voor op je webshop." /> *
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Handelsnaam*"
                    name="organisationbrand"
                    value={organisationbrand}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="">
                    <TL text="Beschrijf de goederen die je wil verhandelen" /> *
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="beschrijving goederen*"
                    name="organisationgoods"
                    value={organisationgoods}
                    onChange={(e) => onChange(e)}
                    required
                  />
                </div>
              </div>
            </div>

            <hr></hr>
            <div className="fw-bold">
              <TL text="Officiële adres bedrijf" /> *
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="">
                    <TL text="Straat" />
                  </label>
                  <input
                    className="form-control"
                    placeholder="straat"
                    type="text"
                    required
                    id="street"
                    name="street"
                    value={street}
                    onChange={(e) => onChange(e)}
                  />
                </div>
              </div>

              <div className="col-md-2">
                <label>
                  <TL text="Huisnummer" /> *
                </label>
                <input
                  className="form-control"
                  placeholder="huisnr"
                  type="text"
                  required
                  id="hnr"
                  name="hnr"
                  value={hnr}
                  onChange={(e) => onChange(e)}
                />
              </div>
              <div className="col-md-2">
                {" "}
                *<label>Ext</label>
                <input
                  className="form-control"
                  placeholder="ext"
                  type="text"
                  id="hnrext"
                  name="hnrext"
                  value={hnrext}
                  onChange={(e) => onChange(e)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    <TL text="Stad" /> *
                  </label>
                  <input
                    className="form-control"
                    placeholder="city"
                    type="text"
                    required
                    id="city"
                    name="city"
                    value={city}
                    onChange={onChange}
                  />
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group">
                  <label>
                    <TL text="Postcode" /> *
                  </label>
                  <input
                    className="form-control"
                    placeholder="ZIP code"
                    type="text"
                    required
                    id="zip"
                    name="zip"
                    value={zip}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <label>
                    <TL text="Land" /> *
                  </label>
                  <select
                    className="form-control"
                    placeholder="country"
                    type="text"
                    required
                    id="country"
                    name="country"
                    value={country}
                    onChange={onChange}
                  >
                    <option name="country" value="">
                      -- <TL text="kies een land" /> --
                    </option>
                    {isocountries &&
                      isocountries.map((ic, index) => {
                        return (
                          <option name="country" value={ic.code} key={index}>
                            {ic.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </div>

            <div className="row mt-5 mb-5">
              <div className="col-md-8">
                <TL text="Door te registreren accepteer je de" />{" "}
                <button
                  type="button"
                  aria-label="algemene voorwaarden"
                  className="readmore"
                  onClick={() => setShowGTC(true)}
                >
                  <TL text="Algemene voorwaarden" />
                </button>
                <p>
                  <TL text=", alsook de bovenvermelde aanbieding voor de geldigheid van 1 jaar." />
                </p>
                <p>
                  <TL test="Onderdeel van de algemene voorwaarden is het herroepingsrecht, art.20." />
                </p>
                <p>
                  <TL text="Je kan binnen een termijn van 14 dagen na activatie, kosteloos afzien van je aankoop, mits het een standaardproduct betreft zonder personalisatie." />
                </p>
                <p>
                  <TL text="Je ontvangt een bevestiging van je bestelling per email, op het opgegeven email adres." />
                </p>
              </div>
              <div className="col-md-4">
                <button
                  className="btn btn-primary"
                  type="submit"
                  aria-label="registreer"
                >
                  <TL text="Ik accepteer de algemene voorwaarden / Aankoop" />
                </button>
              </div>
            </div>

            <div>
              {showGTC && (
                <div>
                  <button
                    type="button"
                    className="btn-close text-white"
                    aria-label="Close"
                    onClick={() => setShowGTC(false)}
                  >
                    <TL text="Sluit" />
                  </button>
                  <GTC />
                </div>
              )}
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ConfigSignup;
