import React from "react";
import TL from "../TL";

const WhyList = () => {
  return (
    <div className="">
      {/* ------------ Makkelijk in gebruik ------------ */}
      <div className="py-3">
        <div className="row p-5 boxtransp70">
          <div className="col-lg d-flex justify-content-center align-items-center">
            <div className="text-white text-center p-3 display-5 fw-bold">
              <TL text="Ubicopia® is makkelijk in gebruik" />
            </div>
          </div>
          <div className="col-lg d-flex flex-column justify-content-center boxtransp70 text-white p-3">
            <p>
              <TL text="Via eenvoudige menu's configureer je volledig jouw webwinkel. Je activeert of deactiveert je producten. Kies je categoriën, je afhaalmomenten, het aantal leveringen per afhaalmoment, de levertijden, locaties, enz...." />
            </p>
            <p>
              <TL text="Een grondige opleiding om vertrouwd te raken met de mogelijkheden is steeds inbegrepen." />
            </p>
            <p>
              <TL text="Met de video-tutorials, kan je alles nog eens nagaan." />
            </p>
            <p>
              <TL text="Heb je nadien nog vragen, kan je steeds rekenen op een stevige support." />
            </p>
          </div>
        </div>

        {/* ------------ Je verhoogt je omzet ------------ */}
        <div className="row p-5 boxtransp70 mt-3 d-flex flex-row-reverse">
          <div className="col-lg d-flex justify-content-center align-items-center">
            <div className="text-white  text-center p-3 display-5 fw-bold">
              <TL text="Met Ubicopia® verhoog je je omzet." />
            </div>
          </div>
          <div className="col-lg d-flex flex-column justify-content-center boxtransp70 text-white p-3">
            <p>
              <TL text="Je winkel is altijd open, zelfs buiten de openingsuren." />
            </p>
            <p>
              <TL text="Klanten kunnen steeds online shoppen bij jou. Ook al is jouw winkel gesloten." />
            </p>
            <p>
              <TL text="Je hebt toegang tot klanten die je anders moeilijk of niet bereikt." />
            </p>
            <p>
              <TL text="Mensen met een drukke job hebben niet altijd de tijd om rustig naar de buurtwinkel te wandelen. Die gaan snel even naar de supermarkt, of doen hun boodschappen bij een grote online webwinkel." />
            </p>
            <p>
              <TL text="Ubicopia® geeft je toegang tot deze klanten." />
            </p>
          </div>
        </div>
      </div>
      {/* ------------ Je verhoogt je efficiëntie ------------ */}
      <div className="row mt-3 boxtransp70 mt-3 d-flex flex-row p-5">
        <div className="col-lg d-flex justify-content-center align-items-center">
          <div className="text-white text-center p-3 display-5 fw-bold">
            <TL text="Met Ubicopia® verhoog je je efficiëntie." />
          </div>
        </div>
        <div className="col-lg d-flex flex-column justify-content-center boxtransp70 text-white p-3">
          <p>
            <TL text="Laat het systeem voor jou werken." />
          </p>
          <p>
            <TL text="Als handelaar in de voeding heb je dikwijls te maken met piekmomenten, denk aan de verschillende feestdagen, of bij de eerste zonnestralen wanneer iedereen plots zijn BBQ bovenhaalt. Het verwerken van deze piekmomenten vraagt extra personeel, extra middelen, en veel stressbestendigheid." />
          </p>
          <p>
            <TL text="Met Ubicopia® win je veel tijd doordat het systeem veel taken van je overneemt." />
          </p>
          <ul>
            <li>
              <TL text="Het systeem doet de volledige online orderverwerking" />
            </li>
            <li>
              <TL text="Verzorgt indien gewenst de facturatie en betalingen." />
            </li>
            <li>
              <TL text="Houdt je klanten automatisch op de hoogte over de status van hun bestelling." />
            </li>
            <li>
              <TL text="Ubicopia® creëert een overzicht van je daginkomsten voor de wettelijk verplichte boekhouding." />
            </li>
            <li>
              <TL text="Ubicopia® geeft duidelijk weer wat je voor wie, waar en wanneer moet klaarmaken." />
            </li>
            <li>
              <TL text="Het systeem zorgt zelfs voor een overzicht per product zodat je deze efficiënter kan klaarmaken, als het ware een productieplanning." />
            </li>
          </ul>
          <p>
            <TL text="Zo kan je je gewonnen tijd gebruiken, en je aandacht toespitsen op andere aspecten van je zaak, zoals meer tijd spenderen aan het klaarmaken van je producten, of bedenken van nieuwe recepten, of wat tijd voor jezelf en je familie." />
          </p>
          <p>
            <TL text="Indien je efficiëntie stijgt, stijgt hiermee je omzet en je winst." />
          </p>
        </div>
      </div>

      {/* ------------ Je vindt aansluiting met de moderne consument ------------ */}
      <div className="row mt-3 boxtransp70 mt-3 d-flex flex-row-reverse p-5">
        <div className="col-lg d-flex justify-content-center align-items-center">
          <div className="text-white text-center p-3 display-5 fw-bold">
            <TL text="Vind aansluiting met de moderne consument" />
          </div>
        </div>
        <div className="col-lg d-flex flex-column justify-content-center boxtransp70 text-white p-3">
          <p>
            <TL text="Het is belangrijk mee te evolueren met je tijd." />
          </p>
          <p>
            <TL text="De traditionele buurtwinkel heeft zeker nog zijn waarde, en geen enkele dorps- of stadskern kan zich volwaardig noemen zonder zijn bakker, slager, frituur, ... Ook zorgt dit voor het behoud van het sociale contact." />
          </p>
          <p>
            <TL text="Echter is het zo dat consumenten steeds meer en meer online zoeken en kopen. Mede ten gevolge van de coronacrisis koopt tegenwoordig 2 op 3 consumenten zowel online als offline." />
            *
          </p>
          <p>
            <TL text="80% van de consumenten informeert zich eerst online alvorens de winkel fysiek op te zoeken." />
            *
          </p>
          <p>
            <TL text="Dit duidt erop dat je je als handelaar tegenwoordig niet meer kan permitteren e-commerce aan je te laten voorbijgaan." />
          </p>
          <p className="fst-italic">
            <small>* Unizo Retail Rapport 2022 </small>
          </p>
        </div>
      </div>

      {/* ------------ Ga voor je eigen webwinkel ------------ */}
      <div className="row mt-3 boxtransp70 mt-3 d-flex flex-row p-5">
        <div className="col-lg d-flex justify-content-center align-items-center">
          <div className="text-white text-center p-3 display-5 fw-bold">
            <TL text="Behoud je eigen identiteit" />
          </div>
        </div>
        <div className="col-lg d-flex flex-column justify-content-center boxtransp70 text-white p-3">
          <p>
            <TL text="Meer en meer handelaars gebruiken een eigen webshop (op maat)" />
            *
          </p>
          <p>
            <TL text="Belangrijk om weten is ook dat er een sterke stijging is in de verkoop via een eigen webshop op maat." />
          </p>
          <p>
            <TL text="De online verkoop via online marktplaatsen is aan het terugvallen, de trend is een eigen webshop al dan niet of maat, of kant-en-klare software, zoals Ubicopia® die aanbiedt." />
          </p>
          <p>
            <TL text="Net zoals je je met je zaak in het toonbeeld van de straat wil onderscheiden, is dit online ook het geval." />
          </p>
          <p>
            <TL text="Je hebt je eigen identiteit. Met een eigen gepersonaliseerde webwinkel, verstevig je je imago zowel online als offline." />
          </p>
          <p className="fst-italic">
            <small>* Unizo Retail Rapport 2022 </small>
          </p>
        </div>
      </div>

      {/* ------------ Verhoog je klantenbinding ------------ */}
      <div className="row my-3 boxtransp70 mt-3 d-flex flex-row-reverse p-5">
        <div className="col-lg d-flex justify-content-center align-items-center">
          <div className="text-white text-center p-3 display-5 fw-bold">
            <TL text="Verhoog je klantenbinding" />
          </div>
        </div>
        <div className="col-lg d-flex flex-column justify-content-center boxtransp70 text-white p-3">
          <p>
            <TL text="Je hebt een bijkomende mogelijkheid om met je klanten te communiceren." />
          </p>
          <p>
            <TL text="Uiteraard volledig in lijn met de GDPR wetgeving, verzorgt Ubicopia® je klantenbestand. Ingebouwd in Ubicopia kan je mailings naar je klanten versturen, hen op de hoogte te houden van bvb. nieuwe producten, speciale acties, onverwachte gebeurtenissen zoals een onverwachte sluiting wegens ziekte of overmacht." />
          </p>
          <p>
            <TL text="Bovendien zet je met Ubicopia een extra verkoopkanaal op waardoor je voor je klanten beter bereikbaar wordt. In tegenstelling tot eerder passieve communicatie zoals facebook, zorgt het Ubicopia mail systeem voor actieve en rechtstreekse communicatie met je klanten." />
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyList;
