import React from "react";
import { Helmet } from "react-helmet";

import hoestartenthumb from "../Media/hoestartenthumb.png";
import watisubicopiathumb from "../Media/watisubicopiathumb.png";

const HomeVideoPage = () => {
  return (
    <div>
      <Helmet>
        <title>Ubicopia® - Videos</title>
        <meta name="description" content="Ubicopia® - How to en User videos" />
        <meta name="robots" content="index,follow" />
        <meta
          name="twitter:description"
          content="Ubicopia video tutorials. Hulp om online van start te gaan."
        />
      </Helmet>
      <section>
        <div className="container mt-5 bg-secondary p-3 text-white">
          <h1 className="text-white mb-3">Videos Ubicopia®</h1>
          <h2 className="text-white">Wat is Ubicopia®</h2>
          <div className="my-3 d-flex justify-content-center">
            <video
              width="90%"
              height="auto"
              poster={watisubicopiathumb}
              controls
            >
              <source
                src="https://backup.ubicopia.be/video/watisubicopia_v2.mp4"
                type="video/mp4"
              />
            </video>
          </div>
          <h2 className="text-white mt-3">Hoe starten met Ubicopia®</h2>
          <div className="my-3 d-flex justify-content-center">
            <video width="90%" height="auto" poster={hoestartenthumb} controls>
              <source
                src="https://backup.ubicopia.be/video/ubicopiahoestarten.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeVideoPage;
