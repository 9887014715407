import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import TL from "../Components/TL";
import BackToCases from "../Components/BackToCases";
import HomeInteraction from "../Components/Home/HomeInteraction";

import frietjes from "../Media/frituur.webp";
import fries from "../Media/fries.webp";
import time from "../Media/time.webp";

const CaseFrituur = () => {
  useEffect(() => {
    document.getElementById("start").scrollIntoView({ behavior: "smooth" });
  }, []);
  const friesstyle = {
    backgroundImage: `url(${fries})`,

    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
  };

  const timestyle = {
    backgroundImage: `url(${time})`,
    backgroundSize: "cover",
    objectFit: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
  };
  return (
    <div className="casepage" id="start">
      <Helmet>
        <title>Webwinkel voor frituur</title>
        <meta
          name="description"
          content="Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta name="twitter:title" content="Webwinkel voor foodtrucks" />
        <meta
          name="twitter:description"
          content="Ubicopia® is webwinkel en webshop software  voor foodtrucks, slagers, bakkers, traiteurs, frituur enz."
        />
        <meta
          name="keywords"
          content="webwinkel voor frituur, webshop voor frituur, webwinkel software voor frituur, webshop software voor frituur, webwinkel systeem voor frituur, webshop systeem voor frituur, frituur online"
        />
        <link rel="canonical" href="https://ubicopia.be/cases/frituur/" />
        <meta name="robots" content="index,follow" />
      </Helmet>

      <section className="py-5" style={friesstyle}>
        <div className="container">
          <BackToCases />
        </div>
        <div className="container boxdark" id="title">
          <div className="row d-flex p-2">
            <div className="boxcornsilk col-lg-6 d-flex justify-content-center align-items-center p-3">
              <div className="text-black p-3">
                <h1>
                  <TL text="Webwinkel voor frituur" />
                </h1>
                <div className="fs-1">
                  <TL text="Efficiëntie" />
                </div>
                <p>
                  <TL text="Een groot aantal bestellingen op korte tijd vraag een goede organisatie." />
                </p>

                <p>
                  <TL text="Ubicopia® neemt een aantal taken van je over." />
                </p>
                <p>
                  <TL text="Ubicopia® verhoogt niet enkel je online aanwezigheid, maar verhoogt ook je efficientie door de beschikbare tijd nuttiger te besteden." />
                </p>
                <div className="fs-1">
                  <TL text="Klantenbinding" />
                </div>
                <p>
                  <TL text="Je verhoogt tegelijk de service naar je klanten door wachttijden te vermijden, en tegelijk steeds een verse bereiding aan te bieden. Hierdoor verhoog je je klantenbinding." />
                </p>
                <div className="fs-1">
                  <TL text="Omzetstijging" />
                </div>
                <p>
                  <TL text="Je zet in op een extra verkoopkanaal." />
                </p>
              </div>
            </div>
            <div className="col-lg-6 p-3 d-flex align-items-center">
              <img
                className="w-100 h-auto"
                src={frietjes}
                alt="webwinkel voor de frituur"
                width="530"
                height="353"
              />
            </div>
          </div>
        </div>

        <div className="container boxtransp70 text-white p-5 mt-5">
          <div className="row">
            <div className="col-lg-8">
              <h2 className="text-start">
                <TL text="Verhoging efficiëntie" />
              </h2>
              <p className="ms-4">
                <TL text="Tijd is de essentie." />
              </p>
              <h3 className="fs-5">
                <TL text="Ubicopia® neemt een aantal taken van je over." />
              </h3>
              <p className="ms-4">
                <TL text="Je klanten bestellen online, je wordt minder gestoord tijdens je werk." />
              </p>
              <p className="ms-4">
                <TL text="Met de getimede preparatie worden je klanten geïnformeerd en werk je gestructureerd." />
              </p>
              <p className="ms-4">
                <TL text="Met een duidelijk overzicht weet je perfect wat er moet gebeuren." />
              </p>
              <p className="ms-4">
                <TL text="Je kan je werk wat verdelen door de tijdslots in te stellen en het maximum aantal bestellingen per slot op te geven." />
              </p>
              <h3 className="fs-5">
                <TL text="Boekhoudkundige hulp" />
              </h3>
              <p className="ms-4">
                <TL text="Ubicopia® bereidt je overzicht van je daginkomsten voor op een overzichtelijke en wettelijk correct manier." />
              </p>
              <h2 className="text-start">
                <TL text="Klantenbinding stijgt" />
              </h2>
              <h3 className="fs-5">
                <TL text="Vermijden van wachttijden" />
              </h3>
              <p className="ms-4">
                <TL text="De keuken heeft slechts een beperkte capaciteit, komt er dan onverwacht een grote bestelling, dan starten de wachttijden." />
              </p>
              <p className="ms-4">
                <TL text="Je kan zelf aangeven hoeveel werk je per online tijdslot kan aannemen." />
              </p>
              <p className="ms-4">
                <TL text="Je kan instellen hoeveel tijd op voorhand je nog bestellingen accepteert, dit kan gaan tot enkele dagen, uren of zelfs minuten." />
              </p>
              <p className="ms-4">
                <TL text="Doordat de klant op voorhand betaalt, wordt er snel opgehaald." />
              </p>
              <p className="ms-4">
                <TL text="De klant weet ongeveer wanneer hij zijn bestelling kan verwachten en zal op tijd worden geïnformeerd wanneer exact deze kan worden opgehaald. Dit gebeurt via sms of email." />
              </p>
              <h3 className="fs-5">
                <TL text="Altijd vers" />
              </h3>
              <p className="ms-4">
                <TL text="Je klant wordt op tijd geïnformeerd met een instelbare timer wanneer zijn bestelling vers kan worden afgehaald. Dit kan per email of sms." />
              </p>
              <h3 className="fs-5">
                <TL text="Online aanwezig" />
              </h3>
              <p className="ms-4">
                <TL text="Met de ingebouwde marketing-mailer informeer je je klanten pro-actief over nieuwe etenswaren, je dag- of weekschotel, uitzonderlijke situaties, enz." />
              </p>
              <p className="ms-4">
                <TL text="Ubicopia® kan worden geïnstalleerd met of zonder website naar je eigen wens." />
              </p>
              <p className="ms-4">
                <TL text="De Ubicopia® Microshop is al een website op zich en kan zo worden gebruikt met een eigen email, hosting en webdomeinnaam - dit is dan je eigen website met wat basisinformatie waarop je klanten hun bestellingen kunnen plaatsen." />
              </p>
              <p className="ms-4">
                <TL text="Heb je reeds een website, dan leveren we aan jou of je webmaster een stukje code, onder de vorm van een javascript snippet of iframe, in een mum van tijd start je met je online verkoop." />
              </p>
              <h2 className="text-start">
                <TL text="Omzet stijgt" />
              </h2>
              <p className="ms-4">
                <TL text="Je connecteert met een nieuwe type klant en opent een extra verkoopskanaal." />
              </p>
            </div>
            <div className="col-lg-4">
              <img
                className="w-100 h-auto"
                src={time}
                alt="webwinkel wint tijd"
                width="332"
                height="286"
              />
            </div>
          </div>
        </div>

        <div className="container">
          <HomeInteraction />
        </div>

        <div className="container boxtransp70 mt-5 p-3 text-white">
          <div className="row mt-3">
            <h2>
              <TL text="Ubicopia® biedt hiervoor een kant-en-klare oplossing." />
            </h2>
            <ul className="list-group bg-dark">
              <li className="list-group-item bg-transparent text-white">
                <TL text="Vrij te kiezen tijdslots voor de afhalingen." />
              </li>
              <li className="list-group-item bg-transparent text-white">
                <TL text="Maximum aantal bestellingen per timeslot." />
              </li>
              <li className="list-group-item bg-transparent text-white">
                <TL text="Je kan bepalen hoeveel uren, of zelfs minuten er op voorhand moet besteld worden." />
              </li>
              <li className="list-group-item bg-transparent text-white">
                <TL text="De klant wordt bij de start van de bereiding automatisch verwittigd door het systeem. Zo weet de klant perfect wanneer hij moet komen afhalen, om een verse bereiding mee te nemen." />
              </li>
              <li className="list-group-item bg-transparent text-white">
                <TL text="Boekhoudkundig wordt alles in de achtergrond bijgehouden, en kan er per dag een BTW-overzicht worden gemaakt van de daginkomsten." />
              </li>
              <li className="list-group-item bg-transparent text-white">
                <TL text="Met de ingebouwde HTML-maildienst, worden de klanten persoonlijk geïnformeerd, wanneer er nieuwe producten in promotie, zijn, of wanneer er bvb een omleiding is." />
                is.
              </li>
            </ul>

            <div className="col-lg-4"></div>
          </div>
        </div>
        <div className="container">
          <BackToCases />
        </div>
      </section>
    </div>
  );
};

export default CaseFrituur;
